import { Injectable } from '@angular/core';
import { CommonConstants } from 'src/app/utility/common-constants';
import { HttpClient } from '@angular/common/http';

@Injectable({
    providedIn: 'root'
  })

export class ConsultantStatusService {
    
    baseUrl : string = CommonConstants.baseUrl+'/dashboard/';
    
    constructor(public http: HttpClient) {}

    consultantStatusCount(obj){
        return this.http.post(this.baseUrl+"ConsultantStatusCount", obj);
    }

    getSmartSoftEmployees(obj){
        return this.http.post(this.baseUrl+"getSmartSoftEmployees", obj);
    }

    getSmartSoftSubContractors(obj){
        return this.http.post(this.baseUrl+"getSmartSoftSubContractors", obj);
    }

    getNonBillableEmployees(obj){
        return this.http.post(this.baseUrl+"getNonBillableEmployees", obj);
    }

}