import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { CommonConstants } from 'src/app/utility/common-constants';

@Injectable({
  providedIn: 'root'
})
export class UploadImageService{
  baseUrl: string = CommonConstants.baseUrl+'/'+CommonConstants.recruitment;
  constructor(public http:HttpClient) { }

  uploadImage(formData:FormData,obj:any){
    //Note this routing is written in server.js file 
    this.http.post(this.baseUrl+'/'+'uploadimage/'+'@'+obj.pathDirectory+'@'+obj.grpentityid+
      '@'+obj.subgrpentityid+'@'+obj.CONSULTANTID, formData)
    .map((files: Response) => files.json())
    .subscribe(files => console.log('files', files))

  }

  downloadImage(obj:any):any{
   return this.http.get(this.baseUrl+'/'+'downloadimage/'+'@'+obj.pathDirectory+'@'+obj.grpentityid+
   '@'+obj.subgrpentityid+'@'+obj.CONSULTANTID);
    
  }

  removeFileFromServer(obj:any){
    // debugger
    // return this.http.get(this.baseUrl+'/'+'removeFileFromServer/'+'@'+obj.pathDirectory+'@'+obj.grpentityid+
    // '@'+obj.subgrpentityid+'@'+obj.CONSULTANTID);
     return this.http.post(this.baseUrl+'/'+'removeFileFromServer',obj);
  }

  checkFileByConsultantId(obj:any){
    return this.http.get(this.baseUrl+'/'+'resumeUploadedClients/'+'@'+obj.pathDirectory+'@'+obj.grpentityid+
    '@'+obj.subgrpentityid);
  }

  // fileUploadedOrNot(consultantid:any){
  //   console.log(consultantid);
  //   return this.http.post(this.baseUrl+'/fileUploadedOrNot/'+consultantid,this.http)
  // }
  
}