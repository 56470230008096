import {Component, Injectable,Input,Output,EventEmitter} from '@angular/core';


@Injectable()
export class SharedService {
   
@Output() fire: EventEmitter<any> = new EventEmitter();

  constructor() {
    console.log('shared service started');
  }

  hide() {
   console.log('show started'); 
    this.fire.emit(false);
  }

  show() {
   console.log('hide started'); 
   debugger;
    this.fire.emit(true);
  }

  getEmittedValue() {
    return this.fire;
  }

            




}
