export class CommonError {
    

    public static error_001:string="is required"; //If the field is mandatory
    public static error_002:string="Cannot contain numbers"; //If the field data type is Alphabet
    public static error_003:string="Cannot contain Special Characters except -, &, (,)"; //If the field data type is Alphabet or Alphanumeric  "
    public static error_004:string="Invalid Date - Either wrong date OR the Date format should be mm/dd/yyyy"; //If the filed data type is DATE
    public static error_005:string="Maximum length exceeded"; //If the field data type is either Alphanumeric / Alphabet/Digits
    public static error_006:string="Can contain only (.@_-) as special characters"; //If the field is Email OR Webmail
    public static error_007:string="Should not be Zero"; //If the field data type is Numeric
    public static error_008:string="Atleast one value to be selected"; //If the field is to be selected from dropdown OR combobox OR List
    public static error_009:string="Cannot contain Alphabets";  //If the field type is Numeric
    public static error_010:string="Cannot have negative values";//If the filed data type is Amount
    public static error_011:string="Special Character . ONLY allowed"; //If the field data type is Amount and if no negative values are allowed
    public static error_012:string="Special Character - and . ONLY allowed";//If the field data type is Amount and if negative values are allowed
    public static error_013:string="No record found"; //When pulling record from database, if record not found
    public static error_014:string="Record already exists";//When duplicate record founds with the specific field value
    public static error_015:string="Can upload only .jpeg or pdf or .xlsx files only";//While file uploading, if User tries to upload a file other than the given format, then this error to be displayed
    public static error_016:string="File with same name already exists";//While uploading a file, if the same file name repeats, then this error to be displayed
    public static error_017:string="Please check, Total file size exceeds specified limit";//When file size going beyond limit
    public static error_018:string="All data fields would be reset to Blank";//while User selects either CANCEL button or REFRESH icon on top
    public static error_019:string="All data will be lost, do you wish to continue?"; //While User enters data and tries to click on REFRESH button on top
    
    public static success_001:string="The Record(s) has been Inserted Successfully"; //When record saved properly
    public static success_002:string="The Record(s) has been updated Successfully";  //When record updated properly
    public static success_003:string="The Record(s) has been Deleted Successfully";  //When record updated properly
  

}