import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { SubGroupEntity } from 'src/app/model/menu/masters/sub-group-entity.model';
import { SubGroupEntityService } from 'src/app/service/menu/masters/sub-group-entity.service';
import { DialogService, DialogCloseResult } from '@progress/kendo-angular-dialog';
import { GroupEntity } from 'src/app/model/menu/masters/group-entity.model';
import { Dialog } from 'src/app/utility/dialog';
import { MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-sub-group-entity',
  templateUrl: './sub-group-entity.component.html',
  styleUrls: ['./sub-group-entity.component.css']
})
export class SubGroupEntityComponent implements OnInit {

  displayedColumns:string[]=["subgrpentityid","subgrpentityname","shortname","actions"];

  public company:GroupEntity[];
  viewData:MatTableDataSource<any>;
  public dataArray:any;
  public editDataItem:SubGroupEntity;
  public isNew:boolean;
  mainGroupList:SubGroupEntity[];
 
  private subGroupEntityModel:SubGroupEntity=new SubGroupEntity();

  dialogwidth:number=400;
  @ViewChild(MatSort) sort:MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  searchkey:string;
  public editForm: FormGroup = new FormGroup({
    'searchField':new FormControl(),
    'subgrpentityid':new FormControl(),
  });

  constructor(private subGroupEntityService:SubGroupEntityService,
              private dialogService: DialogService) { }

  ngOnInit() {
    debugger
      this.subGroupEntityService.getSubGroupEntityDetails(this.subGroupEntityModel).subscribe((data:any[])=>{
        this.dataArray=[];
        this.dataArray=data;
        this.viewData=new MatTableDataSource(this.dataArray);
        this.viewData.sort=this.sort;
        this.viewData.paginator=this.paginator;
      });

      this.subGroupEntityService.getMainGroupWithId(this.subGroupEntityModel).subscribe((data:any[])=>{
        this.mainGroupList=data;
        });
  }
  onSearchClear(){
    this.searchkey='';
    this.applyFilter();
  }
 applyFilter() {
    this.viewData.filter = this.searchkey.trim().toLowerCase();
 }

  public addHandler() {
    this.editDataItem = new SubGroupEntity();
    this.dialogwidth=400;
    this.isNew = true;
  }

  public editHandler(dataItem) {
        debugger;
        this.editDataItem = dataItem;
        this.dialogwidth=1100;
        this.isNew = false;
 
  }

public cancelHandler() {
    this.editDataItem = undefined;
}
public saveHandler() {
    setTimeout(() => { this.subGroupEntityService.getSubGroupEntityDetails(this.subGroupEntityModel).subscribe((data:any[])=>{
      this.dataArray=[];
      this.viewData=null;
      this.dataArray=data;
      this.viewData=new MatTableDataSource(this.dataArray);
      this.viewData.sort=this.sort;
      this.viewData.paginator=this.paginator;
    });},100); 
     this.editDataItem = undefined;
}

public removeHandler(dataItem) {
 
  var dialog = Dialog.deleteDialog(this.dialogService);
  
  dialog.result.subscribe((result) => {
          if (result instanceof DialogCloseResult) {
          } 
          else {
                if(!result.primary){
                       this.subGroupEntityService.deleteSubGroupEntity(dataItem).subscribe((response:any)=>{
                        setTimeout(() => { this.subGroupEntityService.getSubGroupEntityDetails(dataItem).subscribe((data:any[])=>{
                          this.dataArray=[];
                          this.viewData=null;
                          this.dataArray=data;
                          this.viewData=new MatTableDataSource(this.dataArray);
                          this.viewData.sort=this.sort;
                          this.viewData.paginator=this.paginator;
                         });},10);
                       });
                     
                }
          }
        
  });

}
}
