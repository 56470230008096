import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient,HttpResponse } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { Holidays } from 'src/app/model/menu/masters/holidays.model';
import { CommonConstants } from 'src/app/utility/common-constants';

import 'rxjs/Rx';

const httpOptions = {
  headers: new HttpHeaders({ 
    'Access-Control-Allow-Origin':'*',
    'Authorization':'authkey',
    'Access-Control-Allow-Methods': 'GET, PUT, POST, DELETE, HEAD',
  })
};

@Injectable({
  providedIn: 'root'
}) 

export class HolidaysService extends BehaviorSubject<any[]>{
   baseUrl: string = CommonConstants.baseUrl+'/'+CommonConstants.holidays;
  values :any;

   currentYear:number;
   currentMonth:number;
   yearArray:Array<number>=[];
   private holiday=[];
  
    constructor(private http: HttpClient) { 
    super([]);
  }
  
  getHolidaysDetails(holidays:Holidays){
      return this.http.post(this.baseUrl,holidays);
  }

  createHolidays(holidays:Holidays){
      return this.http.post(this.baseUrl+'/'+CommonConstants.add, holidays).subscribe();
  }

  updateHolidays(holidays:Holidays){
      return this.http.post(this.baseUrl +'/'+CommonConstants.edit+'/', holidays,httpOptions).subscribe(response => console.log(response));
  }

  deleteHolidays(holidays:Holidays) {
      return this.http.post(this.baseUrl +'/'+CommonConstants.delete,holidays,httpOptions).subscribe(response => console.log(response));
  }
  // arrValue:[];
  // async CheckDuplicateHolidays(holidays:Holidays){
  // let data =await this.http.post<Holidays>(this.baseUrl + '/checkDuplicate',holidays,httpOptions).toPromise();
  // }

  loadYearComboBox():Array<number>{
            this.yearArray=[];
            this.currentYear=(new Date()).getFullYear();
            this.yearArray.push( this.currentYear);
            for(let i=1;i<=5;i++){
              this.yearArray.push( (this.currentYear+i));
            }
            return this.yearArray;
  }

  getAllYearOfHolidays(holidays){
        return this.http.post(this.baseUrl +'/getAllYears',holidays,httpOptions);
  }
 
  loadGridAccordingYear(holidays){
        return this.http.post(this.baseUrl +"/loadGridAccordingYear" ,holidays,httpOptions);
  }
}
