import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { HttpClient,HttpHeaders } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { tap } from 'rxjs/operators/tap';
import { map } from 'rxjs/operators/map';
import { GridDataResult } from '@progress/kendo-angular-grid';
import { CommonConstants } from 'src/app/utility/common-constants';
import { GroupEntityAddress } from 'src/app/model/menu/masters/group-entity-address.model';


const CREATE_ACTION = 'create';
const UPDATE_ACTION = 'update';
const REMOVE_ACTION = 'destroy';

const httpOptions = {
    headers: new HttpHeaders({ 
      'Access-Control-Allow-Origin':'*',
      'Authorization':'authkey',
      //'userid':'1',
      'Access-Control-Allow-Methods': 'GET, PUT, POST, DELETE, HEAD',
    })
  };

  @Injectable({
    providedIn: 'root'
  }) 
export class GroupEntityAddressService extends BehaviorSubject<any[]> {
    constructor(private http: HttpClient) {
        super([]);
    }

   

    customersObservable : Observable<String[]>;
  
   
  
   

 

      public getGroupEntityAddress(groupEntity:any):Observable<any>  {
          debugger
        return this.http.get(CommonConstants.baseUrl+'/'+CommonConstants.groupEntity+'/'+"groupEntityAddress"+'/'+groupEntity.grpentityid,httpOptions);
            }


            createGroupEntityAddress(groupEntityAddress: GroupEntityAddress) {
                return this.http.post(CommonConstants.baseUrl+'/'+CommonConstants.groupEntity+'/'+"groupEntityAddress"+'/'+CommonConstants.add, groupEntityAddress).subscribe();
              }
            
              updateGroupEntityAddress(groupEntityAddress: GroupEntityAddress) {
                return this.http.post(CommonConstants.baseUrl+'/'+CommonConstants.groupEntity+'/'+"groupEntityAddress"+'/'+CommonConstants.edit+'/' + groupEntityAddress.grpentityid, groupEntityAddress,httpOptions).subscribe(response => console.log(response));
              }

              deleteGroupEntityAddress(groupEntityAddress: GroupEntityAddress) {
                return this.http.post(CommonConstants.baseUrl+'/'+CommonConstants.groupEntity+'/'+"groupEntityAddress"+'/'+CommonConstants.delete+'/'+ groupEntityAddress.grpentityid, groupEntityAddress,httpOptions);
              }
                         
                                    public getMaxGroupEntityAddressSlno(grpEntityId:number):Observable<any>  {
                                        debugger;
                                        return this.http.get(CommonConstants.baseUrl+'/'+CommonConstants.groupEntity+'/'+"getMaxGroupEntityAddress"+'/'+grpEntityId,httpOptions);
                                            }

    //                                         getCountryById(id: String) {
    //                                             debugger;
    //                                             return this.http.get<GroupEntityAddress>(CommonConstants.baseUrl+CommonConstants.country+ CommonConstants.slash + id);
    //                                           }

   
}
