import { Component, OnInit } from '@angular/core';
import { BirthdayWishesService } from 'src/app/service/menu/dashboard/birthday-wishes.service';
import { BirtdayWishes } from 'src/app/model/menu/dashboard/birthday-wishes.model';

@Component({
  selector: 'app-birthday-wishes',
  templateUrl: './birthday-wishes.component.html',
  styleUrls: ['./birthday-wishes.component.css']
})
export class BirthdayWishesComponent implements OnInit {
birtdayWishesModel:BirtdayWishes=new BirtdayWishes();
dataItem:any[]=[];
image:string="assets/email.jpg"

constructor(private birthdaywishesService:BirthdayWishesService) { }

  ngOnInit() {
    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

    this.birthdaywishesService.birthdayWishes(this.birtdayWishesModel).subscribe((data:any[])=>{
      if(data.length>0){
        for(let i=0;i<data.length;i++){
          data[i].NAME=data[i].NAME+" - "+monthNames[new Date(data[i].birthdate).getMonth()]+" "+new Date(data[i].birthdate).getDate();
        }
      }
      this.dataItem=data;
    });
    }

}
