import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Report } from '../model/report.model';
import { Observable } from 'rxjs';

/*const httpOptions = {
  headers: new HttpHeaders({
    'Access-Control-Allow-Origin': '*',
    'Authorization': 'authkey',
    'userid': '1'
  })
}; */

@Injectable({
  providedIn: 'root'
})
export class ReportService {

  constructor(private http: HttpClient) { }
  baseUrl: string = 'http://localhost:3000/report';

  apiURL: "http://localhost:3000";

  customersObservable: Observable<String[]>;

  getActiveEmployees() {
    console.log(this.http.get<Report[]>(this.baseUrl));
    return this.http.get<Report[]>(this.baseUrl);
  }

}
