export class LicenseDetails {
    LICENSENO:string;
    LICENSEEXPIRYDT:Date;
    CITY:string;
    STATEID:string;
    COUNTRYID:string;
    CONSULTANTID:string;
    COMPANYCODE:number;
    grpentityid:number;
    SERIALNO:number;
    subgrpentityid:number;
    //subgrpentityname:string;
    CREATEDUSERID=sessionStorage.getItem("logInuser"); 
    MODIFIEDUSERID=sessionStorage.getItem("logInuser");
    CREATEDDT:any;
    MODIFIEDDT:any;
    
  
    COUNTRYNAME:string;
    COUNTRYCODE:string;
}
