import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CommonConstants } from 'src/app/utility/common-constants';
import { EmployeeVacationDetails } from 'src/app/model/menu/marketing/employee-vacation-details.model';


@Injectable({
  providedIn: 'root'
})
export class EmployeeVacationDetailsService {
 
  strUrl:string=CommonConstants.baseUrl+'/'+CommonConstants.empVacationDetails;
  constructor(private http:HttpClient) { }
  LoadDetailsByEmployeeId(employeeVacationDetails:EmployeeVacationDetails){
    return this.http.post(this.strUrl,employeeVacationDetails);
  }
  convertConsultantId:any;
  createEmployeeVacationDetails(employeeVacationDetails:EmployeeVacationDetails){
     debugger;
     if(employeeVacationDetails.isoutofcountry==true){
      employeeVacationDetails.isoutofcountry='Y';
    }else if(employeeVacationDetails.isoutofcountry==false || employeeVacationDetails.isoutofcountry==null){
      employeeVacationDetails.isoutofcountry='N';
    }
     this.convertConsultantId=employeeVacationDetails.CONSULTANTID;
     //Reassign CONSULTANTID To avoid Key Value pair
     employeeVacationDetails.CONSULTANTID=this.convertConsultantId.CONSULTANTID;
    return this.http.post(this.strUrl+'/'+CommonConstants.add,employeeVacationDetails);
  }
  editEmployeeVacationDetails(employeeVacationDetails:EmployeeVacationDetails){
    this.convertConsultantId=employeeVacationDetails.CONSULTANTID;
    //Reassign CONSULTANTID To avoid Key Value pair
    employeeVacationDetails.CONSULTANTID=this.convertConsultantId.CONSULTANTID;
    return this.http.post(this.strUrl+'/'+CommonConstants.edit,employeeVacationDetails);
  }
}
