import { Component, OnInit } from "@angular/core";
import { ConsultantStatus } from "src/app/model/menu/dashboard/consultant-status.model";
import { ConsultantStatusService } from "src/app/service/menu/dashboard/consultant-status.service";

@Component({
  selector: "app-consultant-status",
  templateUrl: "./consultant-status.component.html",
  styleUrls: ["./consultant-status.component.css"],
})
export class ConsultantStatusComponent implements OnInit {
  consultantstatusModel: ConsultantStatus = new ConsultantStatus();
  title: String = "";

  barChartLabels: String[] = ["Employees", "SubContractor", "NonBillable"];
  barChartData = new Array<any>(3);

  constructor(private consultantStatusService: ConsultantStatusService) {
    this.consultantStatusService
      .consultantStatusCount(this.consultantstatusModel)
      .subscribe((data: any[]) => {
        if (data.length > 0) {
          const objKeys = Object.keys(data[0]);
          const objVal = Object.values(data[0]);

          for (let i = 0; i < objKeys.length; i++) {
            this.barChartData[i] = objVal[i];
          } //closing of for
        } //closing of if
      });
  }

  ngOnInit() {}

  public barChartColors: Array<any> = [
    {
      backgroundColor: ["#7FFA9B", "#EBD2B4", "#c397fc"],
      borderColor: ["white", "white", "white"],
    },
  ];

  public barChartType: string = "bar";

  public barChartOptions: any = {
    layout: {
      padding: {
        left: 5,
        right: 5,
        top: 20,
        bottom: 0,
      },
    },
    legend: {
      display: false,
      position: "right",
      labels: {
        fontSize: 10,
        boxWidth: 10,
      },
    },
    scales: {
      xAxes: [
        {
          ticks: {
            fontSize: 10,
            fontStyle: "bold",
          },
        },
      ],
      yAxes: [
        {
          ticks: {
            steps: 20,
            stepValue: 20,
            min: 0,
          },
        },
      ],
    },
    scaleShowVerticalLines: false,
    responsive: true,
    scaleShowValues: true,
    scaleValuePaddingX: 10,
    scaleValuePaddingY: 10,
    animation: {
      onComplete: function () {
        var chartInstance = this.chart,
          ctx = chartInstance.ctx;
        ctx.textAlign = "center";
        ctx.textBaseline = "bottom";
        this.data.datasets.forEach(function (dataset, i) {
          var meta = chartInstance.controller.getDatasetMeta(i);
          meta.data.forEach(function (bar, index) {
            var data = dataset.data[index];
            ctx.fillText(data, bar._model.x, bar._model.y - 5);
          });
        });
      },
    },
  };

  consultantstatus: ConsultantStatus;
  employeeList: any[] = [];
  public chartClicked(e: any): void {
    if (e.active.length > 0) {
      const chart = e.active[0]._chart;
      const activePoints = chart.getElementAtEvent(e.event);
      if (activePoints.length > 0) {
        // get the internal index of slice in pie chart
        const clickedElementIndex = activePoints[0]._index;
        const label = chart.data.labels[clickedElementIndex];
        // get value by index
        const value = chart.data.datasets[0].data[clickedElementIndex];
        console.log(clickedElementIndex, label, value);

        if (label == this.barChartLabels[0]) {
          this.title =
            "List Of Employees - " + this.barChartLabels[0] + " - " + value;
          this.consultantStatusService
            .getSmartSoftEmployees(this.consultantstatusModel)
            .subscribe((data: any[]) => {
              this.employeeList = data;
            });
        }
        if (label == this.barChartLabels[1]) {
          this.title =
            "List Of Employees - " + this.barChartLabels[1] + " - " + value;
          this.consultantStatusService
            .getSmartSoftSubContractors(this.consultantstatusModel)
            .subscribe((data: any[]) => {
              this.employeeList = data;
            });
        }
        if (label == this.barChartLabels[2]) {
          this.title =
            "List Of Employees - " + this.barChartLabels[2] + " - " + value;
          this.consultantStatusService
            .getNonBillableEmployees(this.consultantstatusModel)
            .subscribe((data: any[]) => {
              this.employeeList = data;
            });
        }

        this.consultantstatus = new ConsultantStatus();
      }
    }
  }
}
