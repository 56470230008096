import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { CommonConstants } from 'src/app/utility/common-constants';
import { ContactDetails } from 'src/app/model/menu/hr/hr-employee/contact-details.model';

const httpOptions = {
  headers: new HttpHeaders({ 
    'Access-Control-Allow-Origin':'*',
    'Authorization':'authkey',
    'Access-Control-Allow-Methods': 'GET, PUT, POST, DELETE, HEAD',
  })
};

@Injectable({
  providedIn: 'root'
})
export class ContactDetailsService {
  strUrl:string=CommonConstants.baseUrl+'/'+CommonConstants.contactDetails;
  constructor(private http:HttpClient) { }
  getEmpContactDetails(contactDetails:ContactDetails){
    return this.http.post(this.strUrl,contactDetails);
  }
  updateEmpContactDetails(contactDetails:ContactDetails){
    debugger;
    console.log(contactDetails.CONSULTANTID);
    return this.http.post(this.strUrl+'/'+CommonConstants.edit,contactDetails,httpOptions);
  }
  saveEmpContactDetails(contactDetails:ContactDetails){
    debugger;
    console.log(contactDetails.CONSULTANTID);
    return this.http.post(this.strUrl+'emp'+'/'+CommonConstants.add,contactDetails,httpOptions);
  }
}
