import { Injectable } from "@angular/core";
import {
    HttpInterceptor,
    HttpRequest,
    HttpResponse,
    HttpErrorResponse,
    HttpHandler,
    HttpEvent,
    HttpParams
} from '@angular/common/http';

import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/do';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/throw';
import { Http, Response, RequestOptions, Headers } from '@angular/http';
import { Router } from '@angular/router'





@Injectable()
export class AppHttpInterceptor implements HttpInterceptor {
    constructor(private router: Router){

    }
    headers = new Headers({
        'Content-Type': 'application/json',
        'Token': localStorage.getItem("Token"),
        'Access-Control-Allow-Origin':'*',
        'Authorization':'authkey',
        'userid':'1',
 'Cache-Control': 'no-cache',
        'Pragma': 'no-cache',
    
        
    'Access-Control-Allow-Methods':' GET, POST, PATCH, PUT, DELETE, OPTIONS',
    'Access-Control-Allow-Headers': 'Origin, Content-Type, X-Auth-Token',
    
    
    });

    authReq:any;
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        console.log("intercepted request ... ");
       // debugger;


        this.authReq=req;  // Clone the request to add the new header.
        let params= new HttpParams();
        let value={'grpentityid':'1','subgrpentityid':'1'};
      //  params.append('entity',JSON.stringify(value));
    //  if(req.method=="POST")
      //  req.body('entity',JSON.stringify(value));

        if(localStorage.getItem("Token") !=null)
        {
         this.authReq = req.clone({ headers: req.headers.set("Token", localStorage.getItem("Token"))
    
    
        
    });
        }


        if (req.method.toLowerCase() === 'post') {
            debugger;
            if (req.body instanceof FormData) {
              req =  req.clone({
                body: req.body.append('grpentityid', '1')
              });
            } else {
             
              req =  req.clone({
                body: {...req.body,grpentityid:'1',subgrpentityid:'1'}
              })
            }            
          } 
          if (req.method.toLowerCase() === 'get') {
            req = req.clone({
              params: req.params.set('grpentityid', '1')
            });
          } 
        








        
        console.log("Sending request with new header now ...");

        //send the newly created request
        return next.handle(this.authReq)
            .catch(err => {
                // onError
                console.log(err);
                if (err instanceof HttpErrorResponse) {
                    console.log(err.status);
                    console.log(err.statusText);
                    if (err.status === 401) {
                        window.location.href = "/login";
                    }
                }
                return Observable.throw(err);
            }) as any;
    }
}