import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { CommonConstants } from 'src/app/utility/common-constants';
import { Observable } from 'rxjs';

const httpOptions = {
  headers: new HttpHeaders({ 
    'Access-Control-Allow-Origin':'*',
    'Authorization':'authkey',
    'Access-Control-Allow-Methods': 'GET, PUT, POST, DELETE, HEAD',
  })
};
@Injectable()

export class GroupEntityService {
  
  baseUrl=CommonConstants.baseUrl+'/'+CommonConstants.groupEntity;

  constructor(private http:HttpClient) { 
  }

  getGroupEntityDetails(groupEntity){
    return this.http.post(this.baseUrl,groupEntity,httpOptions);
  }

  createGroupEntity(groupEntity){
   
    return this.http.post(this.baseUrl +'/'+CommonConstants.add,groupEntity,httpOptions).subscribe((res)=>console.log(res));
  }

  updateGroupEntity(groupEntity){
    return this.http.post(this.baseUrl +'/'+CommonConstants.edit,groupEntity,httpOptions).subscribe((res)=>console.log(res));
  }

  deleteGroupEntity(groupEntity){
   
    console.log("Delete->"+CommonConstants.delete);
    return this.http.post(this.baseUrl +'/'+CommonConstants.delete,groupEntity,httpOptions);
  }

  // getCountryWithId(){
  //   return this.http.get(this.baseUrl+'/getCountry',httpOptions);
  // }

  // loadStateList(countryName){
  //   return this.http.get(this.baseUrl + '/' +countryName,httpOptions)
  // }

  



         

        
                
}
