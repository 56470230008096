import { DialogService } from '@progress/kendo-angular-dialog';

export class Dialog {
    public static deleteDialog(dialogService: DialogService):any{

        var dialog = dialogService.open({
            title: "Please confirm",
            content: "Do you want to delete the record.",
            actions: [
                { text: "No" , primary: true},
                { text: "Yes" }
            ],
            //width: 270,
            //height: 150,
            minWidth: 150,
            
});
return dialog;
    }


public static customdeleteDialog(msg:string,dialogService: DialogService):any{

        var dialog = dialogService.open({
            title: "Please confirm",
            content: msg,
            actions: [
                { text: "No" , primary: true},
                { text: "Yes" }
            ],
            //width: 300,
            //height: 200,
            minWidth: 200,
});
return dialog;
    }



public static alertbox(title:string,msg:string,dialogService: DialogService):any{

    var dialog = dialogService.open({
        title: title,
        content: msg,
        actions: [
            { text: "Ok" }
        ],
        //width: 300,
        //height: 200,
        minWidth: 200,
});
return dialog;
}


}
