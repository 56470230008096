import { Injectable } from '@angular/core';
import { CommonConstants } from 'src/app/utility/common-constants';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class VacationOutOfCountryService {

  baseUrl: string = CommonConstants.baseUrl+'/dashboard/vacationOutOfCountry';
    
    constructor(public http:HttpClient) { }
   
    vacationOutOfCountry(obj){
      return this.http.post(this.baseUrl,obj);
    }
}
