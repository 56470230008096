import { Component, OnInit, Input } from '@angular/core';
import { ActiveProjects } from 'src/app/model/menu/reports/projects/active-projects.model';
import { printDiv } from '../print-div';

@Component({
  selector: 'app-attrition-rate-popup',
  templateUrl: './attrition-rate-popup.component.html',
  styleUrls: ['./attrition-rate-popup.component.css']
})
export class AttritionRatePopupComponent implements OnInit {
  public active:boolean=false;
  constructor() { }

  //@Input() inactiveEmployee:any[]=[];
  //@Input() hiredEmployee:any[]=[];
  @Input() employeeList:any[]=[];
  @Input() title:any;
  
  ngOnInit() {
  }

  @Input() public set model(obj:ActiveProjects){
     if(obj !== undefined){
       this.active=true;
      }
  }

  closeForm(){
    this.active=false;
  }

  printData(id)
  {
debugger;
    printDiv(id,this.title);
  }
}
