export class MarketingSkillSetDetails {
    CONSULTANTID:string;
    SKILLDTLID:number;
    SKILLNATUREID:number;
    SKILLNATURETYPEID:number;
    SKILLDESCID:number;
    grpentityid:number=Number(sessionStorage.getItem("entityId"));
    subgrpentityid:number;

    //this is assign on ngInit(); this is a mandatory field in database
    CREATEDUSERID=sessionStorage.getItem("logInuser"); 
    MODIFIEDUSERID=sessionStorage.getItem("logInuser");
    CREATEDDT:any;
    MODIFIEDDT:any;
}
