import { Component, OnInit } from '@angular/core';
import { AdminService } from 'src/app/admin.service';
import { CommonService } from 'src/app/service/common/common.service';
import { FormAccess } from 'src/app/model/formAccess.model';


@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {

  flag:boolean=true;
  holidayFlag:boolean=false;
  activeProjectsFlag:boolean=false;
  birthdayThisMonthFlag:boolean=false;
  employementAnniversaryFlag:boolean=false;
  attritionRateFlag:boolean=false;
  vacationOutOfCountryFlag:boolean=false;
  consultantStatusFlag:boolean=false;
  employeeFlag:boolean=false;
  candidateFlag:boolean=false;
  placementRolloffHistoryFlag:boolean=false;
  placementThisMonthFlag:boolean=false;

  

  menuDesc:String="";
  subMenus:any[];
  formAccess:FormAccess=new FormAccess();

  constructor(private adminservice:AdminService,
    private commonService:CommonService) { }
  ngOnInit() {

              this.menuDesc="Home";
              this.adminservice.getMenuIdByName(this.menuDesc).subscribe((data:any)=>{
                    if(data[0].MENUID!=null){
                            this.formAccess.MENUID=data[0].MENUID;
                            this.commonService.getSubmenuAccordingToFormAccess(this.formAccess).subscribe((subMenuList:any[])=>{
                            //this.adminservice.getsubMenuList(data[0].MENUID).subscribe((subMenuList)=>{
                              console.log("Submenu list");
                              console.log(subMenuList);
                            this.subMenus=subMenuList; 
                            for(let i=0; i<subMenuList.length;i++){

                              if(subMenuList[i].SUBGROUPID==14){
                                this.holidayFlag=true;
                              }else if(subMenuList[i].SUBGROUPID==6){
                                this.activeProjectsFlag=true;
                              }else if(subMenuList[i].SUBGROUPID==11){
                                this.birthdayThisMonthFlag=true;
                              }else if(subMenuList[i].SUBGROUPID==15){
                                this.employementAnniversaryFlag=true;
                              }else if(subMenuList[i].SUBGROUPID==5){
                                this.attritionRateFlag=true;
                              }else if(subMenuList[i].SUBGROUPID==8){
                                this.vacationOutOfCountryFlag=true
                              }else if(subMenuList[i].SUBGROUPID==3){
                                this.consultantStatusFlag=true
                              }else if(subMenuList[i].SUBGROUPID==1){
                                this.employeeFlag=true;
                              }else if(subMenuList[i].SUBGROUPID==7){
                                this.candidateFlag=true;
                              }else if(subMenuList[i].SUBGROUPID==16){
                                this.placementRolloffHistoryFlag=true;
                              }else if(subMenuList[i].SUBGROUPID==4){
                                this.placementThisMonthFlag=true;
                              }

  


                            }//closing of for

                            });
                    }
              });
              
}
//   public pieChartLabels:string[] = ["Pending", "InProgress", "OnHold", "Complete", "Cancelled"];
//   public pieChartData:number[] = [21, 39, 10, 14, 16];
//   public pieChartType:string = 'pie';
//   public pieChartTypes:string = 'bar';
//   public pieChartTypess:string = 'line';
//   public pieChartOptions:any = {'backgroundColor': [

//     '#6610f2',
//             ]}

//             public lineChartData: ChartDataSets[] = [
//               { data: [65, 59, 80, 81, 56, 55, 40], label: 'Series A' },
//               { data: [28, 48, 40, 19, 86, 27, 90], label: 'Series B' },
//               { data: [180, 480, 770, 90, 1000, 270, 400], label: 'Series C', yAxisID: 'y-axis-1' }
//             ];
//             public lineChartLabels: Label[] = ['January', 'February', 'March', 'April', 'May', 'June', 'July'];
//             public lineChartOptions: (ChartOptions & { annotation: any }) = {
//               responsive: true,
//               scales: {
//                 // We use this empty structure as a placeholder for dynamic theming.
//                 xAxes: [{}],
//                 yAxes: [
//                   {
//                     id: 'y-axis-0',
//                     position: 'left',
//                   },
//                   {
//                     id: 'y-axis-1',
//                     position: 'right',
//                     gridLines: {
//                       color: 'rgba(255,0,0,0.3)',
//                     },
//                     ticks: {
//                       fontColor: 'red',
//                     }
//                   }
//                 ]
//               },
//               annotation: {
//                 annotations: [
//                   {
//                     type: 'line',
//                     mode: 'vertical',
//                     scaleID: 'x-axis-0',
//                     value: 'March',
//                     borderColor: 'orange',
//                     borderWidth: 2,
//                     label: {
//                       enabled: true,
//                       fontColor: 'orange',
//                       content: 'LineAnno'
//                     }
//                   },
//                 ],
//               },
//             };
//             public lineChartColors: Color[] = [
//               { // grey
//                 backgroundColor: 'rgba(148,159,177,0.2)',
//                 borderColor: 'purple',
//                 pointBackgroundColor: 'rgba(148,159,177,1)',
//                 pointBorderColor: '#fff',
//                 pointHoverBackgroundColor: '#fff',
//                 pointHoverBorderColor: 'rgba(148,159,177,0.8)'
//               },
//               { // dark grey
//                 backgroundColor: 'rgba(77,83,96,0.2)',
//                 borderColor: 'rgba(77,83,96,1)',
//                 pointBackgroundColor: 'rgba(77,83,96,1)',
//                 pointBorderColor: '#fff',
//                 pointHoverBackgroundColor: '#fff',
//                 pointHoverBorderColor: 'rgba(77,83,96,1)'
//               },
//               { // red
//                 backgroundColor: '#00FFFF',
//                 borderColor: '#1280c0',
//                 pointBackgroundColor: 'rgba(161, 200, 202)',
//                 pointBorderColor: '#fff',
//                 pointHoverBackgroundColor: '#fff',
//                 pointHoverBorderColor: 'rgba(148,159,177,0.8)'
//               }
//             ];


//             public lineChartColors1: Color[] = [
//               { // grey
//                 backgroundColor: 'rgba(148,139,177,0.2)',
//                 borderColor: 'purple',
//                 pointBackgroundColor: 'rgba(128,159,147,1)',
//                 pointBorderColor: '#fff',
//                 pointHoverBackgroundColor: '#fff',
//                 pointHoverBorderColor: 'rgba(148,179,147,0.8)'
//               },
//               { // dark grey
//                 backgroundColor: 'rgba(177,83,196,0.2)',
//                 borderColor: 'rgba(177,183,196,1)',
//                 pointBackgroundColor: 'rgba(17,43,196,111)',
//                 pointBorderColor: '#fff',
//                 pointHoverBackgroundColor: '#fff',
//                 pointHoverBorderColor: 'rgba(137,43,26,1)'
//               },
//               { // red
//                 backgroundColor: '#00FFFF',
//                 borderColor: '#1280c0',
//                 pointBackgroundColor: 'rgba(161, 200, 202)',
//                 pointBorderColor: '#fff',
//                 pointHoverBackgroundColor: '#fff',
//                 pointHoverBorderColor: 'rgba(148,159,177,0.8)'
//               }
//             ];





//             public lineChartColor: Color[] = [
//               { // grey
//                 backgroundColor: '#1280c0',
//                 borderColor: 'purple',
//                 pointBackgroundColor: 'rgba(148,159,177,1)',
//                 pointBorderColor: '#fff',
//                 pointHoverBackgroundColor: '#fff',
//                 pointHoverBorderColor: 'rgba(148,159,177,0.8)'
//               },
//               { // dark grey
//                 backgroundColor: 'rgba(77,83,96,0.2)',
//                 borderColor: 'rgba(77,83,96,1)',
//                 pointBackgroundColor: 'rgba(77,83,96,1)',
//                 pointBorderColor: '#fff',
//                 pointHoverBackgroundColor: '#fff',
//                 pointHoverBorderColor: 'rgba(77,83,96,1)'
//               },
//               { // red
//                 backgroundColor: '#00FFFF',
//                 borderColor: 'blue',
//                 pointBackgroundColor: 'rgba(161, 200, 202)',
//                 pointBorderColor: '#fff',
//                 pointHoverBackgroundColor: '#fff',
//                 pointHoverBorderColor: 'rgba(148,159,177,0.8)'
//               }
//             ];




//             public lineChartLegend = true;
//             public lineChartType = 'line';
//           //  public lineChartPlugins = [pluginAnnotations];
          

//           chartOptions = {
//             responsive: true
//           };
        
//           chartData = [
//             { data: [330, 600, 260, 700], label: 'Account A' },
//             { data: [120, 455, 100, 340], label: 'Account B' },
//             { data: [45, 67, 800, 500], label: 'Account C' }
//           ];
        
//           chartLabels = ['January', 'February', 'Mars', 'April'];
        





//   // events on slice click
//   public chartClicked(e:any):void {
//     console.log(e);
//   }
 
//  // event on pie chart slice hover
//   public chartHovered(e:any):void {
//     console.log(e);
//   }

}
