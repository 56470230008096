// loader-interceptor.service.ts
import { Injectable } from '@angular/core';
import {
  HttpResponse,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { LoaderService } from '../loader.service';
import { NotificationService } from '@progress/kendo-angular-notification';



@Injectable()
export class LoaderInterceptor implements HttpInterceptor {
  private requests: HttpRequest<any>[] = [];

  constructor(private loaderService: LoaderService,
    private notificationService: NotificationService

  ) { }

  removeRequest(req: HttpRequest<any>) {
    const i = this.requests.indexOf(req);
    if (i >= 0) {
      this.requests.splice(i, 1);
    }
    this.loaderService.isLoading.next(this.requests.length > 0);
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    this.requests.push(req);

    console.log("No of requests--->" + this.requests.length);

    this.loaderService.isLoading.next(true);
    return Observable.create(observer => {
      const subscription = next.handle(req)
        .subscribe(
          event => {
            if (event instanceof HttpResponse) {
              this.removeRequest(req);
              observer.next(event);
            }
          },
          err => {
            let errorMessage:any = Object.values(err.error)[0];
            
            /**
             * If incoming error is user customized error, 
             * then display that error message.
             * Else display default error message
             */
            errorMessage = typeof(errorMessage) !== 'string' ? 'Something went wrong!' : errorMessage;

            this.notificationService.show({
              content: errorMessage,
              animation: { type: 'slide', duration: 400 },
              position: { horizontal: 'center', vertical: 'bottom' },
              type: {style: 'error', icon: true },
              closable: true,
              height: 50,
              width: 500,
              cssClass: 'error-notification',
            });
            
            this.removeRequest(req);
            observer.error(err);
          },
          () => {
            this.removeRequest(req);
            observer.complete();
          });
      // remove request from queue when cancelled
      return () => {
        this.removeRequest(req);
        subscription.unsubscribe();
      };
    });
  }
}