
export class GroupEntityAddress{
  
 // GROUPENTITYID:number;
 grpentityid:number;
  NATURENAME:string;
  SLNO:number;
  ADDRESS1:string;
  ADDRESS2:string;
  CITY:string;
  STATEID:number;
  COUNTRYI:string;
  WEBSITE:string;
  CONTACTNATUREID:number;
  CONTACTPERSON:string;
  EMAIL:string;
  PHONE1:number;
  CREATEDUSERID=sessionStorage.getItem("logInuser"); 
  MODIFIEDUSERID=sessionStorage.getItem("logInuser");
  CREATEDDT:any;
  MODIFIEDDT:any;
  ZIPCODE:string;

  //subgrpentityid:number;

  COUNTRYCODE:string;
 COUNTRYNAME: string;

  set _CLIENTID(grpentityid: number) {
        this.grpentityid = grpentityid;
    }

    set _SLNO(SLNO: number) {
      this.SLNO = SLNO;
  }

 
  }