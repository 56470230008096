import { Injectable } from '@angular/core';
import { CommonConstants } from 'src/app/utility/common-constants';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class PlacementRolloffHistoryService {

  baseUrl: string = CommonConstants.baseUrl+'/dashboard';
    
    constructor(public http:HttpClient) { }
    loadEmployee(obj){
      return this.http.post(this.baseUrl+'/placementRolloffHistory/loadEmployee',obj);
    }
    loadmonthlabel(obj){
      return this.http.post(this.baseUrl+'/placementRolloffHistory/loadmonthlabel',obj);
    }
    loadplacement(obj){
      return this.http.post(this.baseUrl+'/placementRolloffHistory/loadplacement',obj);
    }
    loadrolloff(obj){
      return this.http.post(this.baseUrl+'/placementRolloffHistory/loadrolloff',obj);
    }
}