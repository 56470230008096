import { Component, OnInit, Input, Output, EventEmitter, Inject, HostListener } from '@angular/core';
import { FormGroup, FormControl, Validators, ValidatorFn, AbstractControl } from '@angular/forms';
import { ReferralDetails } from 'src/app/model/menu/masters/referral-details.model';
import { ReferralDetailsService } from 'src/app/service/menu/masters/referral-details.service';
import { CommonService } from 'src/app/service/common/common.service';
import { CommonLabels } from 'src/app/utility/common-labels';
import { CommonError } from 'src/app/utility/common-error';
import { CommonNotifications } from 'src/app/utility/common-notifications';
import { NotificationService } from '@progress/kendo-angular-notification';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-referral-details-edit',
  templateUrl: './referral-details-edit.component.html',
  styleUrls: ['./referral-details-edit.component.css']
})
export class ReferralDetailsEditComponent implements OnInit {
 
  //Lables Declaration
  strSubGroupCompanyName:string=CommonLabels.subGroupEntity_name;
  strCompany:string=CommonLabels.referral_company;
  strEmployee:string=CommonLabels.referral_employee;
  strFirstName:string=CommonLabels.referral_firstName;
  strLastName:string=CommonLabels.referral_lastName;
  strPhone:string=CommonLabels.referral_phone;
  strAlternatePhone:string=CommonLabels.referral_alternatePhone;
  strEmail:string=CommonLabels.referral_emial;
  strAlternateEmail:string=CommonLabels.referral_alternateEmail;
  strIyear:string=CommonLabels.referral_Iyear;
  strIIyear:string=CommonLabels.referral_IIyear;
  //Error Name
  error_001=CommonError.error_001;
 
  // public editForm: FormGroup;
  public active = false;
  refDetailsService:ReferralDetailsService
  public listItems:ReferralDetails[]
  public selectedValue: number ;
  comanyListData:Array<String>=[];
  empListData:Array<String>=[];
  public empNameList:ReferralDetails[]
  cpName:any;
  firstName:any;
  lastName:any
  empValue:string;
  public subgroupNameList:ReferralDetails[];
  referralDetails:ReferralDetails= new ReferralDetails();
 
  subgroupselectionflag:boolean=true;
  referralSearchList:any=[];

  
  referralError={isError:false,errorMessage:''};
 

@Input() public isNew = false;
@Input() listItem:any[];
@Input() options:boolean;
@Input()isEdit:boolean;
@Input() consultantFlag:any;
@Input() fName:any="";
@Input() lName:any="";
@Input() public  subgrpentityname:string;

  companyname: any;

  @Input() public set model(refDetails:ReferralDetails) {
    this.active = refDetails !== undefined;
    this.editForm.reset(refDetails);
    if(refDetails!=undefined){
        if((""+refDetails.CONSULTANTID).length==0){
          this.setSelectableSettings('false');
        }else{
          this.editForm.controls['CONSULTANTID'].setValue({CONSULTANTID:refDetails.CONSULTANTID,employeeName:refDetails.FIRSTNAME+" "+refDetails.LASTNAME})
        }
    }
   
      var loggedInSubgroupId=this.commonService.setLoggedInSubgroupEntityId(this.subgroupNameList);
      if(loggedInSubgroupId.length> 0){
        this.referralDetails.subgrpentityid=loggedInSubgroupId[0].subgrpentityid;
        this.referralDetails.subgrpentityname=loggedInSubgroupId[0].subgrpentityname;
        this.editForm.controls.subgrpentityid.setValue(loggedInSubgroupId[0].subgrpentityid);
        this.loadEmployee(this.referralDetails);
    }
  }

  @Output() cancel: EventEmitter<any> = new EventEmitter();
  @Output() save: EventEmitter<ReferralDetails> = new EventEmitter();

    constructor(@Inject(ReferralDetailsService) editServiceFactory: any,private commonService:CommonService,
                private notificationService:NotificationService,
                private referralDetailsService:ReferralDetailsService) {
        this.refDetailsService = editServiceFactory;
        

    }

 

  ngOnInit() {
     debugger;
       //Loading Sub Group
      this.commonService.getSubGroupEntityDetails(this.referralDetails).subscribe((data:ReferralDetails[])=>{
        this.subgroupNameList=data;
      });
      this.options=true;
      this.consultantFlag="Y";
  }
  setSelectableSettings(value:any){
      const subGroupControl=this.editForm.get('subgrpentityid');
      const employeeControl=this.editForm.get('CONSULTANTID');
      const firstNameControl=this.editForm.get('FIRSTNAME');
        if(value=="true"){
           this.options=true;
           subGroupControl.setValidators(Validators.required);
           employeeControl.setValidators(Validators.required);
           firstNameControl.clearValidators();
        }else if(value=="false"){
         this.options=false; 
         firstNameControl.setValidators(Validators.required);
         subGroupControl.clearValidators();
         employeeControl.clearValidators();
         this.referralError={isError:false,errorMessage:''};
       }
       firstNameControl.updateValueAndValidity();
       subGroupControl.updateValueAndValidity();
       employeeControl.updateValueAndValidity();

      // this.reset();
   }
   



// reset(){
  
//   this.editForm.controls['CONSULTANTID'].setValue(undefined);
//   this.editForm.controls['FIRSTNAME'].setValue(undefined);
//   this.editForm.controls['LASTNAME'].setValue(undefined);
//   this.editForm.controls['PHONE1'].setValue(undefined);
//   this.editForm.controls['PHONE2'].setValue(undefined);
//   this.editForm.controls['EMAILID1'].setValue(undefined);
//   this.editForm.controls['EMAILID2'].setValue(undefined);
//   this.editForm.controls['IYEARPERCENTAGE'].setValue(undefined);
//   this.editForm.controls['IIYEARPERCENTAGE'].setValue(undefined);
// }

  loadEmployee(refDtls:any){
    this.companyname = refDtls.subgrpentityname;
     if(this.editForm.value.subgrpentityid==undefined)
        this.subgroupselectionflag=true;
     else
        this.subgroupselectionflag=false;
    // this.cpName=refDtls.COMPANYNAME;
    // this.listItem=[];
     this.empValue=undefined;
  //  this.refDetailsService.loadEmployeeName(refDtls).subscribe((data:ReferralDetails[])=>{
  //     this.listItem=data;
  //  });

  this.commonService.getEmployeeList(this.editForm.value).subscribe((data:any[])=>{
    this.listItem=data;
    this.referralSearchList= this.listItem.slice();
    });
  }
 
  editForm= new FormGroup({
            'COMPANYNAME': new FormControl(),
             'COMPANYCODE':new FormControl(),
            'FIRSTNAME'  :new FormControl(),
            'LASTNAME': new FormControl(),
            'PHONE1': new FormControl(),
            'PHONE2': new FormControl(),
            'EMAILID1': new FormControl(''),
            'EMAILID2': new FormControl(),
             'IYEARPERCENTAGE': new FormControl(),
             'IIYEARPERCENTAGE': new FormControl(),
             'STATUS'        :new FormControl(),
             'grpentityid'   : new FormControl(),
             'subgrpentityid' : new FormControl('',Validators.required),
             'CONSULTANTID'   :new FormControl('',Validators.required),
             'ISOURCONSULTANTFLAG' :new FormControl(),
              'REFERRALID' :new FormControl(),
              'CREATEDUSERID':new FormControl(),
              'CREATEDDT':new FormControl(),
             'MODIFIEDUSERID':new FormControl(),
             'MODIFIEDDT':new FormControl(),

         });
public onSave(e): void {
    e.preventDefault();
      var referralid=0;
      this.refDetailsService.getreferralid(this.editForm.value).subscribe((data:any[])=>{
        referralid=(data[0].REFERRALID+1);
      })
       
      if(this.isNew)
         {

          console.log(this.editForm.value);
            if(this.editForm.value.ISOURCONSULTANTFLAG=='Y'){
              this.editForm.value.COMPANYCODE=this.editForm.value.subgrpentityid;
              this.editForm.value.COMPANYNAME=this.companyname;
              this.editForm.value.CONSULTANTID=this.editForm.value.CONSULTANTID.CONSULTANTID;

            }else{
              this.editForm.value.COMPANYCODE=undefined;
              this.editForm.value.CONSULTANTID="";
            }

            this.refDetailsService.createReferral(this.editForm.value).subscribe((data:any)=>{
              this.editForm.value.REFERRALID=referralid
              this.save.emit(this.editForm.value);
              debugger;  
              CommonNotifications.success(this.notificationService,CommonError.success_001);
           },err=>{
                 debugger;
                 CommonNotifications.error(this.notificationService);
           });

        }
        else
        {
           this.refDetailsService.updateReferral(this.editForm.value).subscribe((data:any)=>{
            this.save.emit(this.editForm.value);
            debugger;  
            CommonNotifications.success(this.notificationService,CommonError.success_002);
         },err=>{
               debugger;
               CommonNotifications.error(this.notificationService);
         });

         }
        this.active = true;
        this.options=true;//by default checked radio button after click save
        //this.subgrpentityname=undefined;//after saving not clearing formcontrol value 
        this.referralError={isError:false,errorMessage:''};
}

  public onCancel(e): void {
    this.referralError={isError:false,errorMessage:''};
      e.preventDefault();
      this.closeForm();
  }

  private closeForm(): void {
    this.referralError={isError:false,errorMessage:''};
    this.options=true;//by default checked radio button
    this.active = false;
      this.cancel.emit();
  }
  loadEmployeeNameById(refDtls:ReferralDetails){

    this.refDetailsService.getEmpNameFromMaster(refDtls).subscribe((data:ReferralDetails[])=>{
      this.empNameList=data;
     for(let i=0;i<this.empNameList.length;i++){
         this. firstName=this.empNameList[i].FIRSTNAME;
        this.lastName=this.empNameList[i].LASTNAME;
      }
    
   });

    this.referralDetailsService.getReferralDetailsList(this.editForm.value).subscribe((data:any[])=>{
      var flag=0;
      for(let i=0;i<data.length;i++){
        if((data[i].FIRSTNAME+" "+data[i].LASTNAME)==(refDtls.FIRSTNAME+" "+refDtls.LASTNAME)){
          flag=1
        }
        if(i==data.length-1){
          if(flag==0){
            this.referralError={isError:false,errorMessage:''};
          }else{
            this.referralError={isError:true,errorMessage:'Referral already exist.'};
          }
          
        }
      }
      

    });

   
    //   this.refDetailsService.getEmpNameFromMaster(refDtls).subscribe((data:ReferralDetails[])=>{
    //    this.empNameList=data;
    //   for(let i=0;i<this.empNameList.length;i++){
    //       this.firstName=this.empNameList[i].FIRSTNAME;
    //      this.lastName=this.empNameList[i].LASTNAME;
    //    }
     
    // });


  }
  // getSubGroupName(obj){
  //   this.subgrpentityname=obj.subgrpentityname;
  // }


  filterHandler(value:any){
    this.referralSearchList = this.listItem.filter((s) => s.employeeName.toLowerCase().indexOf(value.toLowerCase()) !== -1);
  }
  
}
