import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CommonConstants } from 'src/app/utility/common-constants';

@Injectable({
  providedIn: 'root'
})
export class EmpAddressDetailsService {
  baseurl=CommonConstants.baseUrl+'/'+CommonConstants.EmployeeAddressDetails;
  constructor(public http:HttpClient) { }

  getAddressType(obj){
    return this.http.post(CommonConstants.baseUrl+'/'+CommonConstants.EmployeeAddressDetails +'/addressType',obj)
  }

  getData(obj){
    return this.http.post(this.baseurl,obj);
  }
 
  save(obj){
       return this.http.post(this.baseurl+'/'+CommonConstants.add,obj);
  }
  
  edit(obj){
      return this.http.post(this.baseurl+'/'+CommonConstants.edit,obj);
  }
}
