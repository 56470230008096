import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {Router} from '@angular/router';
import { CommonConstants } from 'src/app/utility/common-constants';

@Injectable()
export class AuthenticationService {
  constructor(private http: HttpClient,private myRoute: Router) {
  }

  /*login(username: string, password: string) {
    return this.http.post<any>('/api/authenticate', {username: username, password: password})
      .pipe(map(user => {
        // login successful if there's a jwt token in the response
        if (user && user.token) {
          // store user details and jwt token in local storage to keep user logged in between page refreshes
          localStorage.setItem('currentUser', JSON.stringify(user));
        }

        return user;
      }));
  }
*/

  
  sendToken(token: string) {
    localStorage.setItem("LoggedInUser", token)
  }
  getToken() {
    return localStorage.getItem("LoggedInUser")
  }
  isLoggedIn() {
    return this.getToken() !== null;
  }
  logout() {
    localStorage.removeItem("LoggedInUser");
    // this.myRoute.navigate(["login"]);
    // window.location.replace('/login');
    window.location.href=CommonConstants.loginUrl;
  }


}
