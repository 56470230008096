import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CommonConstants } from 'src/app/utility/common-constants';

@Injectable({
  providedIn: 'root'
})
export class MarketingSkillSetDetailsService {

  // baseUrl:string=CommonConstants.baseUrl+'/'+CommonConstants.EmployeeSkillSetDetails;
  baseUrl:string=CommonConstants.baseUrl+'/'+CommonConstants.marketingSkillSetDetails;
  constructor(private http:HttpClient) { }

  loadSkillSet(obj){
       return this.http.post(this.baseUrl,obj)
  }

  loadConsultingTypeDropDown(obj:any){
    return this.http.post(this.baseUrl+'/consultingType',obj);
  }
  loadConsultingTitleOrRoleDropDown(obj:any){
    return this.http.post(this.baseUrl+'/consultingTitleOrRole',obj);
  }
  loadLineOfBusinessDropDown(obj:any){
    return this.http.post(this.baseUrl+'/lineOfBusiness',obj);
  }
  loadExpertiseIndOrDomainDropDown(obj:any){
    return this.http.post(this.baseUrl+'/expertiseIndDomain',obj);
  }
  loadExpertiseTechnologyDropDown(obj:any){
    return this.http.post(this.baseUrl+'/expertiseTechnology',obj);
  }
  loadSAPSkillsDropDown(obj:any){
    return this.http.post(this.baseUrl+'/sapSkills',obj);
  }
  loadProgrammingSkillsDropDown(obj:any){
    return this.http.post(this.baseUrl+'/programmingSkills',obj);
  }
  loadOtherSkillsDropDown(obj:any){
    return this.http.post(this.baseUrl+'/otherSkills',obj);
  }
  loadSkillsetDropdown(obj:any){
    return this.http.post(this.baseUrl,obj)
  }
  loadMaxIdByConsultantId(obj:any){
    return this.http.post(this.baseUrl+'/maxId',obj);
  }
  loadSavedSkillDtlByConsultantId(obj){
    return this.http.post(this.baseUrl+'/'+'savedSkillDetails',obj)
  }
  save(obj){
    return this.http.post(this.baseUrl+'/'+CommonConstants.add,obj);
  }
  delete(obj){
    return this.http.post(this.baseUrl+'/'+CommonConstants.delete,obj);
  }
}
