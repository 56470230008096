export const CommonLabels= {  
      //Common Lables Name
       subGroupEntity_name:'Subgroup Company Name',
       common_subGroupEntityName:'Subgroup Company Name',
       common_groupCompanyName:'Group Company Name',
       common_consultantId:'Consultant Id',
       common_employeeName:'Employee Name',
       
       common_firstName:'First Name',
       common_lastName:'Last Name', 
       common_shortName:'Short Name',
       common_Address:'Address',
       common_city:'City',
       common_zipcode:'Zipcode',
       common_country:'Country',
       common_state:'State',
       common_mobileNumber:'Mobile Number',
       common_phoneNumbers:'Phone Numbers',
       common_website:'Website',
       common_email:'Email',
       common_taxNumber:'Tax Number',
 
       common_industryType:'Industry Type',
       common_paymentTerms:'Payment Terms',
       common_invoiceType:'Invoice Type',
       common_status:'Status',
       common_contactNature:'Contact Nature',
       common_contactName:'Contact Name',
       common_from:'From',
       common_to:'To',
       common_cc:'CC',
       common_subject:'Subject',
       common_body:'Body',
       common_query:'Query',
       common_week:'Week',
       common_fromDate:'From Date',
       common_toDate:'To Date',
       
       

      //For Masters Menu 
      clientIndustryType_name:'Client Industry Type',
      expenses_Type:'Expenses Type',
      expenses_desc:'Expenses Description',
      immigrationClassification_name:'Visa Classification',
      immigrationStatus_name:'Visa Status',
      immigrationTask_name:'Visa Task',
      immigrationType_name:'Visa Type',
      contactPerson_name:'Contact Person',
      qualification_name:'Qualification Name',
      qualification_type:'Qualification Type',
      referral_company:'Company',
      referral_employee:'Employee',
      referral_firstName:'First Name',
      referral_lastName:'Last Name',
      referral_phone:'Phone',
      referral_alternatePhone:'Alternate Phone',
      referral_emial:'Email',
      referral_alternateEmail:'Alternate Email',
      referral_Iyear:'I Year%',
      referral_IIyear:'II Year%',
      vendorType_desc:'Vendor Type Description',
      vendor_name:'Vendor Name',
      vendor_type:'Vendor Type',
      vendorPayment_terms:'Payment Terms',
      vendors_status:'Status',
      vendor_notes:'Notes',
      sub_company_name:'Sub Company Name',
      //Vendor Address
      vendor_contactNature:'Contact Person Nature',
      vendor_contactName:'Contact Name',
      vendor_phone:'Phone Numbers',
      vendor_email:'Email',
      vendor_Address1:'Address1',
      vendor_Address2:'Address2',
      vendor_city:'City',
      vendor_zipCode:'Zip Code',
      vendor_country:'Country',
      vendor_state:'State',
      internal_status:'Internal Status',
      group_status:'Employee Group Name',
     
      //For Admin Menu
      usergroup_name:'User Group Name',
      mailAlert_name:'Alert Name',


      //ClientType labels
      clientType_description:'Client Type Description',

      //EmploymentStatus labels
      employmentStatus_employmentStatus:'Employment Status',

      //FieldMapping labels
      fieldMapping_fieldDescription:'Field Description',

      //GCCommunication Labels
      gCCommunication_gCActionType:'GC Action Type',

      //Holidays Labels
      holidays_year:'Year',
      holidays_holidayDate:'Holiday Date',

      holidays_description:'Description',

      //Payment Terms Labels
      paymentTerms_paymentTerms:'Payment Terms',

      //Project Status Labels
      projectStatus_projectStatus:'Project Status',

      //Project Type Labels
      projectType_projectType:'Project Type',

      //Skill Set labels
      skillSet_skillSet:'Skill Set',

      //Dependent Relation
      dependentRelation_description:'Description',

      //Job Title
      jobTitle_jobTitle:"Job Title",
     
       //Visa Product
      visaProduct_visaProductDescription:"Visa Product   Description",
	 //Wages History LCA
       wageshistoryLCA_lcaDescription:"LCA Description",
       wageshistoryLCA_code:"LCA Code",

       //Exempt Nonexempt 
       exemptNonexempt_description:"Exempt Non-Exempt Description",
       exemptNonexempt_exemptNonexemptCode:"Exempt Non-Exempt Code",

	 //Billing Cycle 
       billingCycle_description:'Billing Cycle Description',
       //Project Hours
       projectHours_description:'Project Hours Description',
       //project Mode
       projectMode_description:'Project Mode Description',

       //project Mode
       projectPlacedby_description:'Placed By Description',

        //currency
        currency_Description:"Currency Name",
        currency__code:"Currency Code",

      //ProjectPerDiem labels
      projectPerDiem_description:'PerDiem Description',

      //ProjectRatePer
      projectRatePer_description:'Rate Per Description',

       //project Contact Type
       projectContactType_description:'Project Contact Type',



   //GC Category
       gcCategory_gcCategory:"GC Category Description",

       //open Request Type
       openRequestType_openRequestType:"Open Request Type",
     

      //Schedule Job
      scheduleJob_alertName:"Alert Name",
      scheduleJob_day:"Day :",
      scheduleJob_days:"Days :",
      scheduleJob_time:"Time",
      scheduleJob_active:"Active",

      //Standard Expense
      standardExpense_expensesDesc:"Expenses Desc",
      standardExpense_amount:"Amount",
      standardExpense_recurring:"Recurring Flag",

       //For Project Percentage
       projectPercentage_fromDate:"From Date",
       projectPercentage_toDate:"To Date",
       projectPercentage_percentage:"Percentage",
       projectPercentage_notes:"Notes",

     
       //Manual Expenses
       manualExpense_employee:"Employee",
       manualExpense_receivedDate:"Received Date",
       manualExpense_description:"Description",
       manualExpense_amount:"Amount",
       manualExpense_chequeNo:"Cheque No",
       manualExpense_notes:"Notes",

   

    //For HR Menu
      //For Contact Details
      Employee_mobileNumber:'Mobile No',
      Employee_housePhoneNumber:'House Phone No',
      Employee_workPhoneNo:'Work Phone No',
      Employee_phoneExt:'Ex',
      Employee_indiaContactNo:'India Contact No',
      Employee_faxNo:'Fax No',
      Employee_emailId:'Email ID',
      Employee_altEmialId:'Alt Email ID',
      Employee_slNo:'Sl.No',
      Employee_name:'Name',
      Employee_relationShip:'Relationship',
      Employee_phoneNumber:'Phone Number',
      Employee_address:'Address',
      //Passport Details label
      Employee_PassportNumber:'Passport Number',
      Employee_issuedDate:'Issued Date',
      Employee_expiryDate:'Expiry Date',
      Employee_issuedBy:'Issued By',
      Employee_dependent:'Dependent',
      Employee_self:'Self',
      Employee_relation:'Consultant Relation',


     
      //Hr->Employee->Dependent Details
       dependentDetails_dependentDetails:"Dependent Details",
       dependentDetails_relation:"Relation",
       dependentDetails_birthDate:"Birth Date",
       dependentDetails_dependent:"Dependent",
 
       //Hr ->Employee->License Details
       licenseDetails_licenseDetails:"License Details",
       licenseDetails_licenseNo:"License No",
       licenseDetails_expiryDate:"Expiry Date",
       licenseDetails_licenseHistory:"License History",

       //Hr->Employee->GcWages
       gcWages_gCWagesDetails:"GC Wages Details",
       gcWages_gCWages:"GC Wages",
       gcWages_category:"Category",
       gcWages_filedDate:"Filed Date",
       gcWages_jobTitle:"Job Title",
       gcWages_priorityDate:"Priority Date",
       gcWages_approvedDate:"Approved Date",


      //Vacation Details label
        vacationDetails_startDt:'Start Date',
        vacationDetails_estEndDt:'Estimated End Date',
        vacationDetails_return:'Return',
        vacationDetails_returnDt:'Return Date',
        vacationDetails_reportedDt:'Reported Date',
        vacationDetails_remarks:'Remarks',
 vacationDetails_vacationContNo:'Vacation Contact No',

    //Hr->Employee -> Previous Employement
       previousEmployement_previousEmploymentDetails:"Previous Employment Details",
       previousEmployement_details:"Details",
       previousEmployement_companyName:"Company Name",
       previousEmployement_designation:"Designation",
       previousEmployement_notes:"Notes",

       //Hr->Emoloyee-> Confidential Details
       confidentialDetails_confidentialDetails:"ConfidentialDetails",
     	confidentialDetails_sSNo:"SSN No.",
       confidentialDetails_panNo:"PAN No.",
       confidentialDetails_receivedDate:"Received Date",
       confidentialDetails_datOfBirth:"Date of Birth",
 //Hr->Emoloyee-> Reporting Manager
        reportingManager_managerAssign:"Manager Assign",
        reportingManager_details:"Details",
        reportingManager_managerName:"Manager Name",
        reportingManager_effectiveDate:"Effective Date",

         //Hr->Emoloyee-> Certification
         certification_certificationDetails:"Certification Details",
         certification_details:"Details",
         certification_certificationName:"Certification Name",
         certification_certificationIn:"Certification In",
         certification_certifiedYear:"Certified Year",
         certification_validUpto:"Valid Upto",

//Hr->Employee->Qualification Details
         empQualificationDetails_qualificationDetails:"Qualification Details",
         empQualificationDetails_details:"Details",
         empQualificationDetails_qualificationType:"Qualification Type",
         empQualificationDetails_qualification:"Qualification",

   //Hr->Employee->Address Details
         empAddressDetails_addressDetails:"Address Details",
         empAddressDetails_address:"Address",
         empAddressDetails_withEffect:"Effective",
         empAddressDetails_zipCode:"Zip Code",
         empAddressDetails_gC:"GC",
         empAddressDetails_insurance:"Insurance",
         empAddressDetails_permanent:"Permanent",
         empAddressDetails_payroll:"Payroll",
         empAddressDetails_addressHistory:"Address History",

 //Hr Employee->Wages History
         empWagesHistory_wagesDetails:"Wages Details",
         empWagesHistory_h1BProjectName:"H1B Project Name",
         empWagesHistory_projectName:"Project Name",
         empWagesHistory_jobTitle:"Job Title",
         empWagesHistory_lCA:"LCA",
         empWagesHistory_lCANumber:"LCA Number",
         empWagesHistory_exemptNonExempt:"Exempt/Non-Exempt",
         empWagesHistory_noOfSlots:"No Of Slots",
         empWagesHistory_fromDate:"From Date",
         empWagesHistory_toDate:"To Date",
         empWagesHistory_offeredPromisedWage:"Offered/Promised Wage",
         empWagesHistory_prevailingWage:"Prevailing Wage",
         empWagesHistory_wagesRangesFrom:"Wages Ranges From",
         empWagesHistory_wagesRangesTo:"Wages Ranges To",
         empWagesHistory_address1:"Address 1",
         empWagesHistory_address2:"Address 2",
         empWagesHistory_city:"City",
         empWagesHistory_state:"State",
         empWagesHistory_zipCode:"Zip Code",
         empWagesHistory_country:"Country",
         empWagesHistory_remarks:"Remarks",
         empWagesHistory_locked:"Locked",
         empWagesHistory_wagesLCADetails:"Wages LCA Details",
         empWagesHistory_totalNoOfSlot:"Total No Of Slot",
         empWagesHistory_usageFromDate:"Usage From Date",
         empWagesHistory_usageToDate:"Usage To Date",


   //Hr->Employee->Immigration Details label
       immigrationDetails_processClassification:'Process Classification',
       immigrationDetails_applicationtyp:'Application Type',
       immigrationDetails_applicationStatus:'Application Status',
       immigrationDetails_appliedforClient:'Applied For Client',
       immigrationDetails_product:'Product',
       immigrationDetails_actionDt:'Action Date',
       immigrationDetails_recieptNo:'Receipt No',
       immigrationDetails_a:'A#',
       immigrationDetails_appliedDt:'Applied Date',
       immigrationDetails_rcvDt:'Received Date',
       immigrationDetails_frmDt:'From Date',
       immigrationDetails_ExpDt:'Expiration Date',
       immigrationDetails_startDt:'Start Date',
       immigrationDetails_active:'Active',
       immigrationDetails_stamped:'Stamped',
       immigrationDetails_effDt:'Effective Date',
       immigrationDetails_processtask:'Process Task',
       immigrationDetails_ExpryDt:'Expiry Date',
       immigrationDetails_I94No:'I94 No',
       immigrationDetails_I94IssueCity:'I94 Issue City',
       immigrationDetails_I94IssueDate:'I94 Issue Date',
       immigrationDetails_I94ExpDate:'I94 Expiry Date',
       immigrationDetails_remarks:'Remarks',

         //Hr->Employee->Project immigration label
        projectImmigration_Name:'Project Name',
        projectImmigration_StartDate:'Proj Start Date',
        projectImmigration_EndDate:'End Date',
        projectImmigration_Status:'Project Status',
        projectImmigration_PoNumber:'PO Number',
        projectImmigration_ClientName:'Client Name',
        projectImmigration_ClientType:'Client Type',
       

         //Hr->Employee->Status label
         status:'status',
         status_Date:'Status Date',
         status_Reason:'Reason',
         status_AppliedFor:'Applied For',
         status_ApplicationExpDt:'Application Expiry Date',
         status_I94Number:'I94 Number',
         status_I94ExpDt:'I94 Expiry Date',
         projectStatus:'Project Status',
         internalStatus:'Internal Status',
         status_Notes:'Notes',



	 //ERM-> Search
       search_assignedToEmployee:"Assigned To Employee",
       search_open:"Open",
       search_underProcess:"Under Process",
      search_completed:"Completed",
      search_cancelled:"Cancelled",
      search_expandall:"Expand all",
      search_collapseall:"Collapse all",
      search_add:"Add",
      search_employee:"Employee",
      search_assignedTo:"Assigned To",
      search_type:"Type",
      search_request:"Request",
      search_priority:"Priority",
      search_high:"High",
      search_Normal:"Normal",
      search_low:"Low",
      search_estCompleteDt:"Est. Complete Dt",
      search_ticketId:"Ticket Id",
      search_response:"Response",


   //ERM-> Open Request
       openRequest_inbox:"Inbox",
       openRequest_createdByYou:"Created By You",
       openRequest_employeeName:"Employee Name",
       openRequest_assignTo:"Assign To",
       openRequest_priority:"Priority",
       openRequest_by:"By",
       openRequest_type:"Type",
       openRequest_request:"Request",

//Marketing->Projects(Projecct location,Project Client,contact Details and billing details)
                  //projects
projects_projects:"Projects",
projects_subgroupName:"Subgroup Name",
projects_employeeName:"Employee Name",
projects_details:"Details",
projects_projectName:"Project Name",
projects_remote:"Remote",
projects_expensesPaid:"Expenses Paid",
projects_billingCycle:"Billing Cycle",
projects_capiAmount:"Expenses cap",
projects_actual:"Actual",
projects_hours:"Hours",
projects_placedBy:"Placed By",
projects_placedDate:"Placed Date",
projects_projectStartDate:"Project Start Date",
projects_estEndDate:"Est. End Date",
projects_projectStatus:"Project Status",
projects_actualEndDate:"Actual End Date",
projects_projectType:"Project Type",
projects_projectMode:"Project Mode",
projects_reason:"Notes",

//Billintg Details
projectBillingDetails_billingDetails:"Billing Details",
projectBillingDetails_consultantName:"Consultant Name :",
projectBillingDetails_projectName:"Project Name :",
projectBillingDetails_billing:"Billing... ",
projectBillingDetails_fromDate:"From Date",
projectBillingDetails_toDate:"To Date",
projectBillingDetails_currency:"Currency",
projectBillingDetails_ratePer:"Rate Per",
projectBillingDetails_pONumber:"PO Number",
projectBillingDetails_perDiem:"PerDiem... ",
projectBillingDetails_perDiemRate:"PerDiem Rate",
projectBillingDetails_perDimPer:"PerDim Per",
projectBillingDetails_onsite:"Onsite...",
projectBillingDetails_billingRate:"Billing Rate",
projectBillingDetails_consultantRate:"Consultant Rate",
projectBillingDetails_oTBillingRate:"OT. Billing Rate",
projectBillingDetails_oTConsRate:"OT. Cons. Rate",
projectBillingDetails_offsite:"Offsite...",

//project client
projectclient_clientDetails:"Client Details",
projectclient_client:"Client...",
projectclient_clientName:"Client Name",
projectclient_clientType:"Client Type",
projectclient_sequenceNo:"Sequence No.",
projectclient_invoiceTo:"Invoice To",
//Project Location Details
projectLocation_projectLocationDetails:"Project Location Details",
projectLocation_contactType:"Contact Type",
projectLocation_contactName:"Name",
projectLocation_phoneNumbers:"Phone",
projectLocation_email:"Email",
projectLocation_address1:"Address 1",
projectLocation_address2:"Address 2",
projectLocation_city:"City",
projectLocation_country:"Country",
projectLocation_state:"State",
//Project Contact Details
projectContact_employeeContactDetails:"Employee Contact Details",
projectContact_contactDetails:"Contact Details",
//another projectContact label is already declare



//Marketing Menu
//Marketing Certification Details
  marketing_CertificationName:'Certification Name',
  marketing_CertificationIn:'Certification In',
  marketing_CertifiedYear:'Certified Year',
  marketing_ValidUpto:'Valid Upto',


//HR Home Menu
 //Importnat Links
  importantLinks_LinkDesc:'Link Description',
  importantLinks_Link:'Link',
  importantLinks_FromDt:'From Date',
  importantLinks_ToDate:'To Date',

  //Importnat Links
  importantMsgs_Title:'Title',
  importantMsgs_Msg:'Message',
  importantMsgs_FromDt:'From Date',
  importantMsgs_ToDate:'To Date',

//MyProfile Menu
          myprofile_personalDetails:'Personal Details',
          myprofile_name:'Name :',
          myprofile_designation:'Designation :',
          myprofile_hiredDate:'Hired Date :',
          myprofile_immigrationStatus :'Immigration Status :',
          myprofile_reportingManager:'Reporting Manager :',


  };


