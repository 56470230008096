import { Component, OnInit, Input } from '@angular/core';
import { ActiveProjects } from 'src/app/model/menu/reports/projects/active-projects.model';
import { EmployeeService } from 'src/app/service/employee.service';
import { printDiv } from '../print-div';
@Component({
  selector: 'app-employee-popup',
  templateUrl: './employee-popup.component.html',
  styleUrls: ['./employee-popup.component.css']
})
export class EmployeePopupComponent implements OnInit {

  public active:boolean=false;
  constructor(private employeeService:EmployeeService) { }

  //@Input() inactiveEmployee:any[]=[];
  //@Input() hiredEmployee:any[]=[];
  text:string='';
  @Input() employeeList:any[]=[];
  @Input() title:any;
  @Input() concatLabel:any;
  ngOnInit() {
  }

  @Input() public set model(obj:ActiveProjects){

    this.text="- "+this.concatLabel+" On:"
     if(obj !== undefined){
       this.active=true;
      }
  }

  closeForm(){
    this.active=false;
  }
  printData(id)
  {
debugger;
    printDiv(id,this.title);
  }
  
  

/*
  this.employeeService.employeelist(this.employeeModel).subscribe((data:any[])=>{
    this.employeeList=data;
    if(data.length>0){
      this.attritionRate=new AttritionRate();
    }
  })
*/

}
