import { Injectable } from "@angular/core";
import { PreloadingStrategy, Route } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable()
//Service is used for preloading that is implemented in app-routing.
export class CustomPreloadingStrategy implements PreloadingStrategy{
    preload(route:Route,load:()=>Observable<any>):Observable<any>{
        if(route.data && route.data['preload']){
            console.log('preload path is : '+route.path)
            return load();
        }else{
            return Observable.of(null);
        }
    }
}