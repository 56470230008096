import { Injectable } from "@angular/core";
import { CommonConstants } from "src/app/utility/common-constants";
import { HttpClient } from "@angular/common/http";

@Injectable({
  providedIn: "root",
})
export class ActiveProjectService {
  baseUrl: string = CommonConstants.baseUrl + "/dashboard/activeProjects";

  constructor(public http: HttpClient) {}

  dashboardActiveProjects(obj) {
    return this.http.post(this.baseUrl, obj);
  }

  loadFullTime(obj) {
    return this.http.post(this.baseUrl + "/loadFullTime", obj);
  }

  loadPartTime(obj) {
    return this.http.post(this.baseUrl + "/loadPartTime", obj);
  }

  loadOnCallBasis(obj) {
    return this.http.post(this.baseUrl + "/loadOnCallBasis", obj);
  }

  loadOffShoreBasis(obj) {
    return this.http.post(this.baseUrl + "/offShore", obj);
  }

  loadTekPlay(obj) {
    return this.http.post(this.baseUrl + "/tekplay", obj);
  }
}
