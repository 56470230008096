import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';
import {ReportService} from '../service/report.service';
import {Report} from '../model/report.model';
import { ExcelService } from 'src/app/service/excelservice.service';


@Component({
  selector: 'app-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.css']
})
export class ReportComponent implements OnInit {

  report: Report[];

  constructor(private router: Router, private reportService: ReportService,private excelService:ExcelService) { }

  ngOnInit() {
    this.reportService.getActiveEmployees()
      .subscribe( data => {
        this.report = data;
      });
  }

  exportAsXLSX():void {
    this.excelService.exportAsExcelFile(this.report, 'sample');
  }

}
