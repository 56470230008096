import { Component, OnInit, Inject, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { CommonLabels } from 'src/app/utility/common-labels';
import { CommonError } from 'src/app/utility/common-error';
import { SubGroupEntity } from 'src/app/model/menu/masters/sub-group-entity.model';
import { SubGroupEntityService } from 'src/app/service/menu/masters/sub-group-entity.service';
import { SubGroupEntityAddressService } from 'src/app/service/menu/masters/sub-group-entity-address.service';
import { DialogService, DialogCloseResult } from '@progress/kendo-angular-dialog';
import { SubGroupEntityAddress } from 'src/app/model/menu/masters/sub-group-entity-address.model';
import { Dialog } from 'src/app/utility/dialog';
import { CommonService } from 'src/app/service/common/common.service';
import { NotificationService } from '@progress/kendo-angular-notification';
import { CommonNotifications } from 'src/app/utility/common-notifications';

@Component({
  selector: 'app-sub-group-entity-edit',
  templateUrl: './sub-group-entity-edit.component.html',
  styleUrls: ['./sub-group-entity-edit.component.css']
})
export class SubGroupEntityEditComponent implements OnInit {

   //GroupEntityEdit labels
   strSubGroupCompanyName=CommonLabels.common_subGroupEntityName;
   strGroupCompanyName=CommonLabels.common_groupCompanyName;
   strshortName=CommonLabels.common_shortName;
   strIndustryType=CommonLabels.common_industryType;
   strPaymentTerms=CommonLabels.common_paymentTerms;
   strInvoiceType=CommonLabels.common_invoiceType;
   strStatus=CommonLabels.common_status;
   strWebsite=CommonLabels.common_website;
   strTaxNumbe=CommonLabels.common_taxNumber;
 //End

 //Coomon Errors
 error_001=CommonError.error_001;
 //End

 public industryTypeItems: Array<SubGroupEntity> =[];
 public invoiceType: Array<any> =[];
 public paymentTerms: Array<any> =[];
 mainGroupList:SubGroupEntity[];
 mainGroup:SubGroupEntity=new SubGroupEntity();
 
 public editAddressDataItem: any;
 public status:Array<{text:String,value:String}>=
 [
   { text: "Active", value: 'A' },
   { text: "InActive", value: 'I' },
 
 ];  

 //public columns: any[] = [{field: "NATURENAME",title:"Cont. Nature"}, {field: "CONTACTPERSON",title:"Name"},{field:"ADDRESS1",title: "Address"}];
 displayedColumns:string[]=["NATURENAME","CONTACTPERSON","ADDRESS1","actions"];
 
 subGroupEntityService:SubGroupEntityService;
 SubGroupEntityAddressService:SubGroupEntityAddressService;
 
 constructor(@Inject(SubGroupEntityService) editServiceFactory: any,
     private subGroupEntityAddressService:SubGroupEntityAddressService,
     private dialogService: DialogService,
     private commonService:CommonService,
     private notificationService:NotificationService
     ) {
     this.subGroupEntityService = editServiceFactory;
  
 }
 public subGroupEntityAddress: any=[];
 ngOnInit() {
  
      this.subGroupEntityService.getMainGroupWithId(this.mainGroup).subscribe((data:SubGroupEntity[])=>{
        this.mainGroupList=data;
        });

        this.commonService.getIndustryTypeList().subscribe((industryType) =>{
        this.industryTypeItems=industryType;
        });

       this.commonService.getPaymentTermsList().subscribe((paymentTerms) =>{
       this.paymentTerms=paymentTerms;
       });

       this.commonService.getInvoiceTypeList().subscribe((invoiceType)=>{
       this.invoiceType=invoiceType;
       });


 }

 public active = false;
 public subGroupEntityObject:any;
 public editForm: FormGroup = new FormGroup({

         'grpentityid': new FormControl(''),
         'subgrpentityid':new FormControl(''),
         'subgrpentityname': new FormControl('',[Validators.required]),
         'shortname': new FormControl('', [Validators.required]),
         'INDUSTRYTYPEID': new FormControl('',[Validators.required]),
         'PAYMENTTERMSCODE': new FormControl('',[Validators.required]),
         'InvoiceTypecode': new FormControl('',[Validators.required]),
         'ACTIVEINACTIVEFLAG': new FormControl('',[Validators.required]),

        // 'website': new FormControl('',[Validators.required]),
        // 'taxnumber':new FormControl('',[Validators.required]),

         'website': new FormControl(''),
         'taxnumber':new FormControl(''),

         'STATUS':new FormControl(''),
         'COUNTRYCODE':new FormControl(''),
         'createuserid':new FormControl(),
         'createddt':new FormControl(),
         'modifieduserid':new FormControl(),
         'modifieddt':new FormControl(),
   
 });

 slno:any;



@Input() public isNew = false;
@Input() public isAddressNew :boolean= false;
@Input() dialogwidth;
@Input() public set model(subGroupEntity:SubGroupEntity) {
 if(subGroupEntity !== undefined)
 {
   this.subGroupEntityObject=subGroupEntity;
   this.subGroupEntityAddressService.getSubGroupEntityAddress(subGroupEntity).subscribe((data:SubGroupEntityAddress[])=>this.subGroupEntityAddress=data);
 }  
 this.editForm.reset(subGroupEntity);
 this.active = subGroupEntity !== undefined;
}
@Input() public state:any[];


@Output() cancel: EventEmitter<any> = new EventEmitter();
@Output() save: EventEmitter<SubGroupEntity> = new EventEmitter();
@Input() dataArray:any;
duplicateValue:boolean=false; 
grpEntityId:number=Number(sessionStorage.getItem("entityId"));


public addAddressHandler(clientObject) {
 var subGroupEntityAddress=new SubGroupEntityAddress();
 subGroupEntityAddress.subgrpentityid=clientObject.value.subgrpentityid;
 subGroupEntityAddress.grpentityid=clientObject.value.grpentityid;

 this.subGroupEntityAddressService.getMaxSubGroupEntityAddressSlno(subGroupEntityAddress.subgrpentityid).subscribe(data=>{this.slno=(data[0].SLNO+1)
  });

 this.editAddressDataItem = subGroupEntityAddress;
 this.isAddressNew = true;
  
}

public editAddressHandler(dataItem) {
 this.state=[]
 this.slno=dataItem.SLNO
 this.commonService.getStateByCountryCode(dataItem).subscribe((data:any[])=>{
   this.state=data;
 });

 this.editAddressDataItem = dataItem;
 this.isAddressNew = false;

}

public cancelAddressHandler() {
 this.editAddressDataItem = undefined;
}

public saveAddressHandler(subgrpEntityAddress: any) {
 setTimeout(() => { this.subGroupEntityAddressService.getSubGroupEntityAddress(this.subGroupEntityObject).subscribe((data:SubGroupEntityAddress[])=>{
   this.subGroupEntityAddress=data;
   });},100); 
 this.editAddressDataItem = undefined;
}

public removeAddressHandler(dataItem) {
   var dialog = Dialog.deleteDialog(this.dialogService);
   dialog.result.subscribe((result) => {
         if (result instanceof DialogCloseResult) {
         } 
         else {
               if(!result.primary){
                      this.subGroupEntityAddressService.deleteSubGroupEntityAddress(dataItem).subscribe((response:any)=>{
                        setTimeout(() => { this.subGroupEntityAddressService.getSubGroupEntityAddress(dataItem).subscribe((data:SubGroupEntityAddress[])=>{
                          this.subGroupEntityAddress=[];   
                          this.subGroupEntityAddress=data;
                          });},10);
                      });
                     
               }
         }
       
 });
}



public onSave(e): void {
 e.preventDefault();
 debugger;
 
 this.save.emit(this.editForm.value);
 //Duplicate Entry check
  if(this.dataArray.length>0){
      var data=this.dataArray.map(t=>t.subgrpentityname);
      var groupEntityIdList=this.dataArray.map(t=>t.grpentityid);
      var sortNameList=this.dataArray.map(t=>t.shortname);
      for(let i=0;i<data.length;i++){
          if((this.editForm.get('subgrpentityname').value).toUpperCase()==data[i].toUpperCase() && (this.editForm.get('shortname').value).toUpperCase()==sortNameList[i].toUpperCase()
                && this.grpEntityId==groupEntityIdList[i]){
                this.duplicateValue=true;
          }else{
            this.duplicateValue=false;
          }
      }
  }
 if(this.isNew){

          if(this.duplicateValue==true){
            CommonNotifications.warning(this.notificationService,CommonError.error_014);
          }else{
          this.subGroupEntityService.createSubGroupEntity(this.editForm.value).subscribe((data:any)=>{
                debugger;  
                CommonNotifications.success(this.notificationService,CommonError.success_001);
            },err=>{
                  debugger;
                  CommonNotifications.error(this.notificationService);
            });
          }
  }else{
              this.subGroupEntityService.updateSubGroupEntity(this.editForm.value).subscribe((data:any)=>{
              debugger;  
              CommonNotifications.success(this.notificationService,CommonError.success_002);
              },err=>{
                    debugger;
                    CommonNotifications.error(this.notificationService);
              });
  }
  this.active = true;
}


public onCancel(e): void {
 e.preventDefault();
 this.closeForm();
}

private closeForm(): void {
 this.active = false;
 this.cancel.emit();
}
}
