import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { CommonConstants } from 'src/app/utility/common-constants';


const httpOptions = {
  headers: new HttpHeaders({ 
    'Access-Control-Allow-Origin':'*',
    'Authorization':'authkey',
    'Access-Control-Allow-Methods': 'GET, PUT, POST, DELETE, HEAD',
  })
};
@Injectable({
  providedIn: 'root'
})
export class ConfidentialDetailsService {

  baseUrl:string=CommonConstants.baseUrl+'/'+CommonConstants.EmployeeConfidentialDetails;
  constructor(private http:HttpClient) { }

  getEmployeeDOB(confidentialDetailsModel){
    return this.http.post(this.baseUrl+'/'+'getDOB',confidentialDetailsModel);
  }
  save(cfDetailsModel){
    debugger;
    //assign encryptedSSNumber 
    if(cfDetailsModel.EncryptSSNUMBER !=null && cfDetailsModel.EncryptSSNUMBER !=undefined){
      cfDetailsModel.SSNUMBER= cfDetailsModel.EncryptSSNUMBER;
    }
    return this.http.post(this.baseUrl+'/'+CommonConstants.add,cfDetailsModel);
  }
  edit(cfDetailsModel){
    //assign encryptedSSNumber 
    if(cfDetailsModel.EncryptSSNUMBER !=null && cfDetailsModel.EncryptSSNUMBER !=undefined){
      cfDetailsModel.SSNUMBER= cfDetailsModel.EncryptSSNUMBER;
    }
    return this.http.post(this.baseUrl+'/'+CommonConstants.edit,cfDetailsModel);
  }

  loadData(cfDetailsModel){
    return this.http.post(this.baseUrl,cfDetailsModel);
  }

  updateDateOfBirth(obj){
    return this.http.post(this.baseUrl+'/updateDateOfBirth',obj);
  }
}
