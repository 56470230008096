import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { CommonConstants } from 'src/app/utility/common-constants';
import { PassportDetails } from 'src/app/model/menu/hr/hr-employee/passport-details.model';


const httpOptions = {
  headers: new HttpHeaders({ 
    'Access-Control-Allow-Origin':'*',
    'Authorization':'authkey',
    'Access-Control-Allow-Methods': 'GET, PUT, POST, DELETE, HEAD',
  })
};
@Injectable({
  providedIn: 'root'
})
export class PassportDetailsService {

  strUrl:string=CommonConstants.baseUrl+'/'+CommonConstants.passportDetails;
  constructor(private http:HttpClient) { }

  loadSelfPassportDetails(passportDetails:PassportDetails){
    return this.http.post(this.strUrl,passportDetails);
  }
  // loadConsultantRelation(passportDetails:PassportDetails){
  //   return this.http.post(this.strUrl+'/consultantrel',passportDetails);
  // }
  loadGridDataForDependent(passportDetails:PassportDetails){
    // passportDetails.CONSULTANTID="SSIU1069";
    // passportDetails.DEPENDENTNO=passportDetails.dependentrelation;
    debugger;
    console.log( passportDetails.DEPENDENTNO);
    return this.http.post(this.strUrl+'/dependent',passportDetails);
  }
  getSerailNo(passportDetails:PassportDetails){
    debugger
  return this.http.post(this.strUrl+'/serialno',passportDetails);
  }
  updatePassportDetails(passportDetails:PassportDetails){
    debugger;
    console.log(passportDetails);
    return this.http.post(this.strUrl+'/'+CommonConstants.edit,passportDetails);
  }
  savePassportDetails(passportDetails:PassportDetails){
    debugger;
    console.log(passportDetails);
    return this.http.post(this.strUrl+'/'+CommonConstants.add,passportDetails);
  }
}
