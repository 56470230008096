import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { tap } from 'rxjs/operators/tap';
import { map } from 'rxjs/operators/map';
import { Employee } from 'src/app/model/Employee.model';
//import { clientType } from '../model/clientType';
import { CommonConstants } from 'src/app/utility/common-constants';
import { GridDataResult } from '@progress/kendo-angular-grid';
import { EmployeeMain } from '../model/menu/hr/hr-employee/employee-main.model';
import { EmployeeGeneralDetails } from '../model/menu/hr/hr-employee/employee-general-details.model';


const CREATE_ACTION = 'create';
const UPDATE_ACTION = 'update';
const REMOVE_ACTION = 'destroy';

const httpOptions = {
    headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        'Authorization': 'authkey',
        //'userid':'1',
        'Access-Control-Allow-Methods': 'GET, PUT, POST, DELETE, HEAD',
    })
};

@Injectable()
export class EmployeeService extends BehaviorSubject<any[]> {
    constructor(private http: HttpClient) {
        super([]);
    }

    baseUrl: string = CommonConstants.baseUrl;
    public employeeObject: any;


    apiURL: "http://localhost:3000";

    customersObservable: Observable<String[]>;


    public getNewEmployeeList(): Observable<any> {
        debugger;
        return this.http.get(CommonConstants.baseUrl + CommonConstants.newEmployee, httpOptions);
    }


    public getEmployeeSearchList(obj: any): Observable<any> {
        return this.http.post(CommonConstants.baseUrl + "/employeeList", obj);
    }

    public getEmployeeSubMenu(): Observable<any> {
        debugger;
        return this.http.get(CommonConstants.baseUrl + "/employeeSubMenu", httpOptions);
    }

    public getEmployeeObject(employee: any): Observable<any> {
        debugger;
        return this.http.post(CommonConstants.baseUrl + "/employee/" + employee.consultantid, httpOptions);
    }




    createNewEmployee(employee: Employee) {
        debugger
        return this.http.post(CommonConstants.baseUrl + CommonConstants.newEmployee, employee).subscribe();
    }

    updateNewEmployee(employee: Employee) {
        debugger;
        return this.http.post(CommonConstants.baseUrl + CommonConstants.newEmployee + '/' + employee.CONSULTANTID, employee, httpOptions).subscribe(response => console.log(response));
    }







    public uploadEmployeeImage(employee: any, fd: FormData) {
        debugger;
        return this.http.post(CommonConstants.baseUrl + CommonConstants.employeeImage + '/' + employee.consultantid, fd, httpOptions);
    }

    public getEmployeeImage(employee: any) {
        debugger;
        return this.http.get(CommonConstants.baseUrl + CommonConstants.employeeImage + '/' + employee.consultantid, httpOptions);
    }





    //there is no rout available of upper createNewEmployee so i create new 
    createEmployee(obj: EmployeeMain) {
        debugger
        return this.http.post(CommonConstants.baseUrl + "/createEmployee", obj);
    }

    insertEmployeeGeneralDetails(obj: EmployeeGeneralDetails) {
        debugger
        return this.http.post(CommonConstants.baseUrl + "/insertEmployeeGeneralDetails", obj);
    }


    editEmployee(obj: EmployeeMain) {
        debugger
        return this.http.post(CommonConstants.baseUrl + "/editEmployee", obj);
    }

    editEmployeeGeneralDetails(obj: EmployeeGeneralDetails) {
        debugger
        return this.http.post(CommonConstants.baseUrl + "/editEmployeeGeneralDetails", obj);
    }










    /*
 
     
       createClient(client: Client) {
         return this.http.post(CommonConstants.baseUrl+CommonConstants.client, client).subscribe();
       }
     
       updateClient(client: Client) {
        debugger;
         return this.http.post(CommonConstants.baseUrl+CommonConstants.client + '/' + client.CLIENTID, client,httpOptions).subscribe(response => console.log(response));
       }
     
       deleteClient(client: Client) {
         return this.http.post(CommonConstants.baseUrl+CommonConstants.clientDel + '/' + client.CLIENTID, client,httpOptions).subscribe(response => console.log(response));
       }
 
 
       public getClientTypeList():Observable<any>  {
         debugger;
         return this.http.get(this.baseUrl+CommonConstants.clientApi+CommonConstants.clientTypeList,httpOptions);
             }
 
             public getClientList():Observable<any>  {
                 debugger;
                 return this.http.get(CommonConstants.baseUrl+CommonConstants.client,httpOptions);
                     }
 
 
 
             public getClientIndustryTypeList():Observable<any>  {
                 debugger;
                 return this.http.get(this.baseUrl+CommonConstants.clientApi+CommonConstants.clientIndustryTypeList,httpOptions);
                     }    
     
                     public getPaymentTermsList():Observable<any>  {
                         debugger;
                         return this.http.get(this.baseUrl+CommonConstants.clientApi+CommonConstants.paymentTermsList,httpOptions);
                             }    
             
 
                             public getInvoiceTypeList():Observable<any>  {
                                 debugger;
                                 return this.http.get(this.baseUrl+CommonConstants.clientApi+CommonConstants.invoiceTypeList,httpOptions);
                                     }    
                     
 
 
 
 
 
 
 
 
 
 
     private data: any[] = [];
 
     public read() {
         if (this.data.length) {
             return super.next(this.data);
         }
 
         this.fetch()
             .pipe(
                 tap(data => {
                     this.data = data;
                 })
             )
             .subscribe(data => {
                 super.next(data);
             });
     }
 
     public save(data: any, isNew?: boolean) {
         const action = isNew ? CREATE_ACTION : UPDATE_ACTION;
 
         this.reset();
 
         this.fetch(action, data)
             .subscribe(() => this.read(), () => this.read());
     }
 
     public remove(data: any) {
         this.reset();
 
         this.fetch(REMOVE_ACTION, data)
             .subscribe(() => this.read(), () => this.read());
     }
 
     public resetItem(dataItem: any) {
         if (!dataItem) { return; }
 
         // find orignal data item
         const originalDataItem = this.data.find(item => item.ProductID === dataItem.ProductID);
 
         // revert changes
         Object.assign(originalDataItem, dataItem);
 
         super.next(this.data);
     }
 
     private reset() {
         this.data = [];
     }
 
     private fetch(action: string = '', data?: any): Observable<any[]> {
             
             return this.http.get<Client[]>(CommonConstants.baseUrl+CommonConstants.client,httpOptions).pipe(map(res => <any[]>res));
 
 
 
     }
 
     private serializeModels(data?: any): string {
         return data ? `&models=${JSON.stringify([data])}` : '';
     }*/
}
