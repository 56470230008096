import { Component, OnInit } from '@angular/core';
import { CommonService } from 'src/app/service/common/common.service';
import { EmployeeVacationDetails } from 'src/app/model/menu/marketing/employee-vacation-details.model';
import { FormGroup, FormControl, Validators, AbstractControl } from '@angular/forms';
import { EmployeeVacationDetailsService } from 'src/app/service/menu/marketing/employee-vacation-details.service';
import { CommonLabels } from 'src/app/utility/common-labels';
import { CommonNotifications } from 'src/app/utility/common-notifications';
import { NotificationService } from '@progress/kendo-angular-notification';
import { CommonError } from 'src/app/utility/common-error';
import { DatePipe, ɵNullViewportScroller } from '@angular/common';

@Component({
  selector: 'app-employee-vacation-details',
  templateUrl: './employee-vacation-details.component.html',
  styleUrls: ['./employee-vacation-details.component.css']
})
export class EmployeeVacationDetailsComponent implements OnInit {
       
        strsubGroupCompanyName:string=CommonLabels.common_subGroupEntityName;
        strEmpName:string= CommonLabels.common_employeeName
        

        subGroupList:any=[];
        employeeVacationDetails:EmployeeVacationDetails= new EmployeeVacationDetails();
        hideAndShowFlag:boolean=false;
        empList:any=[];
        employeeNameList:any=[];
        viewData:any=[];
        displayedColumns: string[] = ['vacationstartdt','estimatedenddt','REMARKS','APPROVEDFLG','isoutofcountry','RETURNTAG',
                                      'returndt','reporteddt',"REPORTINGMANAGERCOMMENTS"]
        
        convertConsultantId:any;
        constructor(private employeeVacationDetailsService:EmployeeVacationDetailsService, private commonService:CommonService) { }

        ngOnInit() {
          this.editForm.reset(this.employeeVacationDetails);
          this.commonService.getSubGroupEntityDetails(this.employeeVacationDetails).subscribe((data:any)=>{
              this.subGroupList=data;
              var loggedInSubgroupId=this.commonService.setLoggedInSubgroupEntityId(this.subGroupList);
              if(loggedInSubgroupId.length> 0){
                 this.employeeVacationDetails.subgrpentityid=loggedInSubgroupId[0].subgrpentityid
                  this.editForm.controls['subgrpentityid'].setValue(loggedInSubgroupId[0].subgrpentityid);
                  this.commonService.getEmployeeList( this.employeeVacationDetails).subscribe((data:any)=>{
                    this.empList=data;
                    this.employeeNameList=this.empList.slice()
                 });
              }
          });
        }
        public editForm: FormGroup = new FormGroup({  
          'CONSULTANTID':new FormControl(),
          'grpentityid':new FormControl(),
          'subgrpentityid':new FormControl(),
        });
        
        loadEmployeeOnSelectionChange(value:any){
          debugger;
          this.employeeVacationDetails.subgrpentityid=value.subgrpentityid;
          //Load Employee Name 
          this.commonService.getEmployeeList( this.employeeVacationDetails).subscribe((data:any)=>{
             this.empList=data;
             this.employeeNameList=this.empList.slice()
          })
          this.hideAndShowFlag=false;
          this.editForm.controls['CONSULTANTID'].reset();
          console.log(value);
        }
        getEmployeeName(value:any){
          this.employeeNameList = this.empList.filter((s) => s.employeeName.toLowerCase().indexOf(value.toLowerCase()) !== -1);
        }
        loadDataOnSelectionChange(value:any){
          debugger;
          if(value!=null && value!="" && value!=undefined){
                this.hideAndShowFlag=true;
                this.employeeNameList= this.empList; //If User wants to change drop down value it will load all List
                // this.editForm.controls['CONSULTANTID'].reset(undefined);
                // this.editForm.controls['CONSULTANTID'].setValue({CONSULTANTID:value.CONSULTANTID});
                this.employeeVacationDetails.CONSULTANTID=value.CONSULTANTID;
                //if the value is Object Format
                this.convertConsultantId=value.CONSULTANTID;
                if(this.convertConsultantId.CONSULTANTID!=undefined){
                    this.employeeVacationDetails.CONSULTANTID=this.convertConsultantId.CONSULTANTID;
                }
                this.employeeVacationDetailsService.LoadDetailsByEmployeeId( this.employeeVacationDetails).subscribe((data:any)=>{
                  this.viewData=data;
                });
           }else{
            this.hideAndShowFlag=false;
            this.editForm.controls['CONSULTANTID'].reset();
           }
        }
          
}
