import { Injectable } from '@angular/core';
import { CommonConstants } from 'src/app/utility/common-constants';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class CandidateService {

  baseUrl: string = CommonConstants.baseUrl+'/dashboard';
    
    constructor(public http:HttpClient) { }
   
    stamped(obj){
      return this.http.post(this.baseUrl+'/candidate',obj);
    }
    loadEmployee(obj){
        return this.http.post(this.baseUrl+'/candidate/loadEmployee',obj);
      }
    
}
