import { Injectable } from '@angular/core';
import { CommonConstants } from 'src/app/utility/common-constants';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class AttritionRateService {

  baseUrl: string = CommonConstants.baseUrl+'/dashboard';
    
    constructor(public http:HttpClient) { }
   
    attritionRateHired(obj){
      return this.http.post(this.baseUrl+'/attritionRateHired',obj);
    }

    attritionRateInactive(obj){
      return this.http.post(this.baseUrl+'/attritionRateInactive',obj);
    }
}
