import { NgModule } from "@angular/core";
import { CommonModule, DatePipe } from "@angular/common";
import { HomeComponent } from "../../home/home.component";
import { LoginComponent } from "../../login/login.component";
import { NavMenuComponent } from "../Dashboard/nav-menu/nav-menu.component";
import { DashboardComponent } from "../Dashboard/dashboard/dashboard.component";
import { BlankcomponentComponent } from "../Dashboard/blankcomponent/blankcomponent.component";
import { ReportComponent } from "../../report/report.component";
import { UserFormComponent } from "../../user-form/user-form.component";
import { jqxGridComponent } from "jqwidgets-scripts/jqwidgets-ts/angular_jqxgrid";
import { LoginEntityComponent } from "../../loginentity/loginentity.component";
import { AppComponent } from "../../app.component";
import { AngularFontAwesomeModule } from "angular-font-awesome";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { SocialLoginModule, AuthServiceConfig } from "angular-6-social-login";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { ChartsModule } from "ng2-charts";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { Ng2CompleterModule } from "ng2-completer";
import { InputsModule } from "@progress/kendo-angular-inputs";
import {
  GridModule,
  ExcelService,
  EditService,
  ExcelModule,
  PDFModule,
} from "@progress/kendo-angular-grid";
import { DialogModule } from "@progress/kendo-angular-dialog";
import {
  DropDownsModule,
  ComboBoxModule,
} from "@progress/kendo-angular-dropdowns";
import {
  DatePickerModule,
  CalendarModule,
} from "@progress/kendo-angular-dateinputs";
import { UploadModule } from "@progress/kendo-angular-upload";
import { getAuthServiceConfigs } from "../../app.module";
import { AppHttpInterceptor } from "../../utility/AppHttpInterceptor";
import { EncryptdecryptService } from "../../service/encryptdecrypt.service";
import { AuthenticationService } from "../../service/menu/dashboard/auth.service";
import { AuthGuard } from "../../auth.guard";
import { GroupEntity } from "../../model/menu/masters/group-entity.model";
import { GroupEntityService } from "../../service/menu/masters/group-entity.service";
import { EmployeeService } from "../../service/employee.service";
import { SharedService } from "../../shared.service";
import { SubGroupEntityService } from "../../service/menu/masters/sub-group-entity.service";
import { RouterModule } from "@angular/router";

import { TabStripModule, PanelBarModule } from "@progress/kendo-angular-layout";
import { TreeViewModule } from "@progress/kendo-angular-treeview";
import { MenusModule } from "@progress/kendo-angular-menu";
import { DragDropModule } from "@angular/cdk/drag-drop";
import { NgMultiSelectDropDownModule } from "ng-multiselect-dropdown-angular7";

import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MyLoaderComponent } from "src/app/progressbar/components/my-loader/my-loader.component";
import { LoaderService } from "src/app/progressbar/loader.service";
import { LoaderInterceptor } from "src/app/progressbar/interceptors/loader-interceptor.service";
import { MatTableModule } from "@angular/material/table";
import {
  MatFormFieldModule,
  MatInputModule,
  MatSortModule,
} from "@angular/material";

import { MatIconModule } from "@angular/material/icon";

import { MatButtonModule } from "@angular/material/button";
import { MatPaginatorModule } from "@angular/material/paginator";

import { HolidayDashboardComponent } from "../Dashboard/holiday-dashboard/holiday-dashboard.component";
import { ActiveProjectDashboardComponent } from "../Dashboard/active-project-dashboard/active-project-dashboard.component";
import { ActiveProjectPopupComponent } from "../Dashboard/active-project-popup/active-project-popup.component";

import { EmployeeVacationDetailsComponent } from "../marketing/employee-vacation-details/employee-vacation-details.component";
import { SubGroupEntityComponent } from "../masters/sub-group-entity/sub-group-entity.component";
import { SubGroupEntityEditComponent } from "../masters/sub-group-entity-edit/sub-group-entity-edit.component";
import { SubGroupEntityAddressComponent } from "../masters/sub-group-entity-address/sub-group-entity-address.component";
import { GroupEntityComponent } from "../masters/group-entity/group-entity.component";
import { GroupEntityEditComponent } from "../masters/group-entity-edit/group-entity-edit.component";
import { GroupEntityAddressComponent } from "../masters/group-entity-address/group-entity-address.component";
import { SkillsetDetailsEditComponent } from "../marketing/skillset-details-edit/skillset-details-edit.component";

import { BirthdayWishesComponent } from "../Dashboard/birthday-wishes/birthday-wishes.component";
import { AnniversaryWishesComponent } from "../Dashboard/anniversary-wishes/anniversary-wishes.component";
import { ScrollViewModule } from "@progress/kendo-angular-scrollview";
import { ReferralDetailsEditComponent } from "../masters/referral-details-edit/referral-details-edit.component";
import { AttritionRateComponent } from "../Dashboard/attrition-rate/attrition-rate.component";
import { VacationOutOfCountryComponent } from "../Dashboard/vacation-out-of-country/vacation-out-of-country.component";
import { AttritionRatePopupComponent } from "../Dashboard/attrition-rate-popup/attrition-rate-popup.component";
import { EmployeePopupComponent } from "../Dashboard/employee-popup/employee-popup.component";
import { ConsultantStatusComponent } from "../Dashboard/consultant-status/consultant-status.component";
import { ConsultantStatusPopupComponent } from "../Dashboard/consultant-status-popup/consultant-status-popup.component";
import { EmployeeComponent } from "../Dashboard/employee/employee.component";
import { UploadComponentComponent } from "../recruitment/upload-component/upload-component.component";
import { EmployeeViewDetailsComponent } from "../hr-employee/employee-view-details/employee-view-details.component";
import { CandidateComponent } from "../Dashboard/candidate/candidate.component";
import { CandidatePopupComponent } from "../Dashboard/candidate-popup/candidate-popup.component";
import { PlacementThisMonthComponent } from "../Dashboard/placement-this-month/placement-this-month.component";
import { PlacementThisMonthPopupComponent } from "../Dashboard/placement-this-month-popup/placement-this-month-popup.component";
import { PlacementRolloffHistoryComponent } from "../Dashboard/placement-rolloff-history/placement-rolloff-history.component";
import { PlacementRolloffHistoryPopupComponent } from "../Dashboard/placement-rolloff-history-popup/placement-rolloff-history-popup.component";
import { RelogindialogComponent } from "../Dashboard/relogindialog/relogindialog.component";

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    LoginComponent,
    NavMenuComponent,
    DashboardComponent,
    BlankcomponentComponent,
    LoginEntityComponent,
    RelogindialogComponent,

    MyLoaderComponent,

    ReportComponent,
    UserFormComponent,
    jqxGridComponent,

    //dashbord component
    HolidayDashboardComponent,
    ActiveProjectDashboardComponent,
    ActiveProjectPopupComponent,
    BirthdayWishesComponent,
    AnniversaryWishesComponent,
    AttritionRateComponent,
    VacationOutOfCountryComponent,
    AttritionRatePopupComponent,
    EmployeeComponent,
    EmployeePopupComponent,
    ConsultantStatusPopupComponent,
    ConsultantStatusComponent,
    CandidateComponent,
    CandidatePopupComponent,
    PlacementThisMonthComponent,
    PlacementThisMonthPopupComponent,
    PlacementRolloffHistoryComponent,
    PlacementRolloffHistoryPopupComponent,

    //share component
    EmployeeVacationDetailsComponent,
    SubGroupEntityComponent,
    SubGroupEntityEditComponent,
    SubGroupEntityAddressComponent,
    GroupEntityComponent,
    GroupEntityEditComponent,
    GroupEntityAddressComponent,

    SkillsetDetailsEditComponent,
    ReferralDetailsEditComponent,
    UploadComponentComponent,
    EmployeeViewDetailsComponent,
  ],

  imports: [
    CommonModule,
    RouterModule,
    NgMultiSelectDropDownModule.forRoot(),
    NgbModule.forRoot(),

    FormsModule,
    MenusModule,
    ChartsModule,
    ReactiveFormsModule,

    MatProgressSpinnerModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    MatButtonModule,

    DialogModule,
    TabStripModule,
    GridModule,
    DropDownsModule,

    Ng2CompleterModule,
    InputsModule,
    DatePickerModule,
    ComboBoxModule,
    UploadModule,
    TreeViewModule,
    PanelBarModule,
    ExcelModule,
    DragDropModule,
    PDFModule,
    MenusModule,
    CalendarModule,
    ScrollViewModule,
  ],
  exports: [
    CommonModule,
    AngularFontAwesomeModule,
    FormsModule,
    SocialLoginModule,
    HttpClientModule,
    ChartsModule,
    ReactiveFormsModule,
    NgMultiSelectDropDownModule,

    Ng2CompleterModule,
    InputsModule,
    GridModule,
    DialogModule,
    DropDownsModule,
    DatePickerModule,
    ComboBoxModule,
    UploadModule,

    TabStripModule,
    TreeViewModule,
    PanelBarModule,
    ExcelModule,
    DragDropModule,
    PDFModule,
    MenusModule,
    CalendarModule,
    ScrollViewModule,

    MatProgressSpinnerModule,
    MatTableModule,
    MatIconModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatSortModule,
    MatPaginatorModule,

    //share component
    EmployeeVacationDetailsComponent,
    SubGroupEntityComponent,
    SubGroupEntityEditComponent,
    SubGroupEntityAddressComponent,
    GroupEntityComponent,
    GroupEntityEditComponent,
    GroupEntityAddressComponent,
    SkillsetDetailsEditComponent,
    ReferralDetailsEditComponent,
    AttritionRatePopupComponent,
    EmployeePopupComponent,
    ConsultantStatusPopupComponent,
    UploadComponentComponent,
    EmployeeViewDetailsComponent,
  ],
  providers: [
    //NotificationService,
    {
      provide: AuthServiceConfig,
      useFactory: getAuthServiceConfigs,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AppHttpInterceptor,
      multi: true,
    },

    { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true },

    AuthenticationService,
    AuthGuard,
    DatePipe,

    ExcelService,
    EncryptdecryptService,
    GroupEntity,
    GroupEntityService,

    EmployeeService,
    SharedService,
    {
      provide: AuthServiceConfig,
      useFactory: getAuthServiceConfigs,
    },
    ExcelService,
    EncryptdecryptService,
    AuthenticationService,
    AuthGuard,
    //SubGroupEntityService,
  ],
})
export class SetupModule {
  constructor() {
    console.log("Setup module loaded");
  }
}
