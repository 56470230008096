import { Component , HostListener} from '@angular/core';
import { AdminService } from 'src/app/admin.service';
import {AuthenticationService} from 'src/app/service/menu/dashboard/auth.service';
import { OnInit } from '@angular/core/src/metadata/lifecycle_hooks';
import { LocationStrategy } from '@angular/common';
import { Router } from '@angular/router';
import { CommonConstants } from './utility/common-constants';




@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
  
})
export class AppComponent implements OnInit {
  title = 'AngularPortal';
  isLogged=false;
  grpEntityValue:number=0;
  BrowserCloseFalg:boolean=false
  constructor(private adminservice:AdminService,private auth:AuthenticationService, private router: Router,private locationStrategy: LocationStrategy) { 

   // this.preventBackButton();
  

  }



  preventBackButton() {
  
   if(location.href!=CommonConstants.homeUrl)
    location.href=CommonConstants.homeUrl;
    history.pushState(null, null, location.href);
    this.locationStrategy.onPopState(() => {
      history.pushState(null, null, location.href);
    })
  }


  ngOnInit() {
   
    this.grpEntityValue=Number(sessionStorage.getItem("entityId"));
    console.log(this.BrowserCloseFalg);
    
    if(this.auth.isLoggedIn() != false)
    {
      //this.isLogged=true;
      if(this.grpEntityValue==0  || this.grpEntityValue==undefined){
        this.isLogged=false
        this.router.navigate(['login']);
      }else{
        this.isLogged=true;
        this.router.navigate(['dashboard']);
      }
    
    }
    else
    {
      this.isLogged=false;

    }

  }
  ////This Function will call during closing browser tab and closing browser 
  @HostListener("window:beforeunload", ["$event"]) unloadHandler(event: Event) {

   
     window.location.href=CommonConstants.loginUrl;
//window.location.href="http://localhost:4200";
    //window.location.reload();
  
  }
  

  

  






}
