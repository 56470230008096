export class ReferralDetails {
    REFERRALID:number;
    // COMPANYCODE:number;
    // COMPANYNAME:string;
    FIRSTNAME:string;
    LASTNAME:string;
    PHONE1:number;
    PHONE2:number;
    EMAILID1:string;
    EMAILID2:string;
    IYEARPERCENTAGE:number;
    IIYEARPERCENTAGE:number;
    STATUS:string='Y';
    grpentityid:number=Number(sessionStorage.getItem("entityId"));
    subgrpentityid :number;
    CONSULTANTID:string;
    ISOURCONSULTANTFLAG:string;
    CREATEDUSERID=sessionStorage.getItem("logInuser"); 
    MODIFIEDUSERID=sessionStorage.getItem("logInuser");
    CREATEDDT:any;
    MODIFIEDDT:any;  
    subgrpentityname:string;
}

