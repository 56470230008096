import { Component, OnInit, Output, EventEmitter, Inject, Input } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { GroupEntityAddress } from 'src/app/model/menu/masters/group-entity-address.model';
import { GroupEntityAddressService } from 'src/app/service/menu/masters/group-entity-address.service';
import { CommonLabels } from 'src/app/utility/common-labels';
import { CommonError } from 'src/app/utility/common-error';
import { SelectionRange } from '@progress/kendo-angular-dateinputs';
import { CommonService } from 'src/app/service/common/common.service';

@Component({
  selector: 'app-group-entity-address',
  templateUrl: './group-entity-address.component.html',
  styleUrls: ['./group-entity-address.component.css']
})
export class GroupEntityAddressComponent implements OnInit {

  //GroupEntityAddress LABELS
  strContactNature=CommonLabels.common_contactNature;
  strContactName=CommonLabels.common_contactName;
  strPhoneNumbers=CommonLabels.common_phoneNumbers;
  strEmail=CommonLabels.common_email;
  strAddress=CommonLabels.common_Address;
  strCity=CommonLabels.common_city;
  strZipCode=CommonLabels.common_zipcode;
  strCountry=CommonLabels.common_country;
  strState=CommonLabels.common_state;

  //End

  //Common Errors
  error_001=CommonError.error_001;

  //End


  groupEntityAddressService:GroupEntityAddressService;
  
  constructor(@Inject(GroupEntityAddressService) groupEntityAddressService: any,private commonService:CommonService) {
  this.groupEntityAddressService=groupEntityAddressService;
  }

  public addressActive = false;
 
  public grpentityid:any;
  
  public slnoId:number;
  public contactNature: Array<any> =[];
  public country: Array<GroupEntityAddress> =[];

  public editAddressForm: FormGroup = new FormGroup({
      'grpentityid': new FormControl(),
      'SLNO':new FormControl(),
      'CONTACTNATUREID': new FormControl('', [Validators.required]),
      'CONTACTPERSON': new FormControl('', [Validators.required]),
      'PHONE1': new FormControl('', Validators.required),
      'EMAIL': new FormControl('', Validators.required),
      'ADDRESS1': new FormControl('', [Validators.required]),
      'ADDRESS2': new FormControl(''),
      'CITY': new FormControl('', Validators.required),
      'ZIPCODE': new FormControl('', Validators.required),
      'COUNTRYID': new FormControl('', Validators.required),
      'STATEID': new FormControl('',Validators.required),
      'STATUS': new FormControl('Y'),
      'CREATEDUSERID':new FormControl(),
      'CREATEDDT':new FormControl(),
      'MODIFIEDUSERID':new FormControl(),
      'MODIFIEDDT':new FormControl(),
    });
  
   
  @Input() public state: Array<any> =[];
  @Input() public isAddressNew :boolean;
  @Input() public slno:number;
  @Input() public set model(groupEntityAddress: GroupEntityAddress) {
        if(groupEntityAddress !== undefined)
              {this.grpentityid=groupEntityAddress.grpentityid;
              
            
              }
              this.editAddressForm.reset(groupEntityAddress);
              this.addressActive = groupEntityAddress !== undefined;
        }

  @Output() cancel: EventEmitter<any> = new EventEmitter();
  @Output() save: EventEmitter<GroupEntityAddress> = new EventEmitter();


  ngOnInit() {
   
              this.commonService.getContactNature().subscribe((contactNature) =>{
              this.contactNature=contactNature;
              });
              this.commonService.getCountry().subscribe((countryList)=>{
                this.country=countryList;
              });
              this.commonService.getStateList().subscribe((state) =>{
              this.state=state;
              });

  }

  public selectionChange(country: any): void {
      this.state=[];
      
      this.editAddressForm.controls['STATEID'].setValue(undefined)     
      this.commonService.getStateByCountryName(country).subscribe((state) =>{
      this.state=state;
      });

      
      
  }

  public onAddressSave(e): void {
      e.preventDefault();
      this.save.emit(this.editAddressForm.value);
      if(this.isAddressNew)
      {
         this.groupEntityAddressService.createGroupEntityAddress(this.editAddressForm.value);
      }
      else
      {
       this.groupEntityAddressService.updateGroupEntityAddress(this.editAddressForm.value);

      }
      this.addressActive = true;
  }

  public onAddressCancel(e): void {
      e.preventDefault();
      this.closeForm();
  }

  private closeForm(): void {
      this.addressActive = false;
      this.cancel.emit();
  }

}
