import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CommonConstants } from 'src/app/utility/common-constants';

@Injectable({
  providedIn: 'root'
})
export class EmployeeDetailsService {
  strUrl:string=CommonConstants.baseUrl+'/'+CommonConstants.EmployeeDetails;
  constructor(private http:HttpClient) { }
  loadAllReferralList(obj:any){
    return this.http.post(this.strUrl+"/loadReferral",obj);

  }
  loadData(obj:any){
    return this.http.post(this.strUrl+"/loadData",obj);
  }
  loadDesignationHistory(obj:any){
    return this.http.post(this.strUrl+"/loadDesignationHistory",obj);

  }
  loadHiredHistory(obj:any){
    return this.http.post(this.strUrl+"/loadHiredHistory",obj);
  }
  updateEmployeeDetails(obj:any){
    return this.http.post(this.strUrl+"/updateEmployeeDetails",obj);
  }
  updateEmployeeGeneralDetails(obj:any){
    return this.http.post(this.strUrl+"/updateEmployeeGeneralDetails",obj);
  }
  // insertEmployeeDetails(obj:any){
  //   return this.http.post(this.strUrl+"/insertEmployeeDetails",obj);
  // }




  loadEmployeeImmegration(obj:any){
    return this.http.post(this.strUrl+"/loadEmployeeImmegration",obj);
  }

  loadProjectDetails(obj:any){
    return this.http.post(this.strUrl+"/loadProjectDetails",obj);
  }



  loadEmployeeDetails(obj:any){
    return this.http.post(this.strUrl+"/loadEmployeeViewDetails",obj);
  }

  loadImmigrationClassification(obj:any){
    return this.http.post(this.strUrl+"/loadImmigrationClassification",obj);
  }

  isRefferalAvailable(obj:any){
    return this.http.post(this.strUrl+"/isRefferalAvailable",obj);
  }

  updateRefferal(obj:any){
    return this.http.post(this.strUrl+"/updateRefferal",obj);
  }

  saveRefferal(obj:any){
    return this.http.post(this.strUrl+"/saveRefferal",obj);
  }

getReferralHistorySno(obj:any){
    return this.http.post(this.strUrl+"/getReferralHistorySno",obj);
  }

  insertReferralHistory(obj:any){
    return this.http.post(this.strUrl+"/insertReferralHistory",obj);
  }

}
