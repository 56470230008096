export class PassportDetails {
    serialno:number;
    CONSULTANTID:string;
    DEPENDENTNO:Number=0;
    COMPANYCODE:number;
    passportnumber:string;
    issueddate:Date;
    expdate:Date;
    issuedby:string;
    country:string;
    COUNTRYNAME:string;
    COUNTRYCODE:string;
    dependentrelaname:string;
    dependentrelation:number;
    grpentityid:number;
    subgrpentityid:number;
    CREATEDUSERID=sessionStorage.getItem("logInuser"); 
    MODIFIEDUSERID=sessionStorage.getItem("logInuser");
    CREATEDDT:any;
    MODIFIEDDT:any;
}
