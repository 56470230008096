export class SubGroupEntity {
    grpentityid:number=Number(sessionStorage.getItem("entityId"));;
    subgrpentityid:number;
    grpentityname:string;
    shortname:string;
    
    InvoiceTypecode:number;
    INDUSTRYTYPEID:number;
    PAYMENTTERMSCODE:number;

    website:string;
    taxnumber:string;
    ACTIVEINACTIVEFLAG:string;
   
    STATUS:string='Y';

    COUNTRYNAME:string;
    COUNTRYCODE:string;

    STATECODE:string;
    STATEDESCRIPTION:string;

    CLIENTINDUSTRYTYPECODE:string;
    INDUSTRYTYPENAME:string;
    createuserid=sessionStorage.getItem("logInuser"); 
    modifieduserid=sessionStorage.getItem("logInuser");
    createddate:any;
    modifieddt:any; 
  
}
