import { Component, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import {
    AuthService,
    FacebookLoginProvider,
    GoogleLoginProvider
} from 'angular-6-social-login';
import { UserService } from 'src/app/service/menu/admin/user.service';
import { AuthenticationService} from 'src/app/service/menu/dashboard/auth.service';

import {mstUser} from 'src/app/user';
import {User} from 'src/app/model/menu/admin/user.model';
import { map } from 'rxjs/operators';
import * as CryptoJS from 'crypto-js';
import { NgbModal ,NgbModalOptions} from '@ng-bootstrap/ng-bootstrap';
import {NgbTypeaheadConfig} from '@ng-bootstrap/ng-bootstrap';
import {LoginEntityComponent} from '../loginentity/loginentity.component';
import { CommonConstants } from 'src/app/utility/common-constants';      
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { EmailArguments } from '../model/emailArguments.model';
import { EmailInformation } from '../utility/email-information';
import { CommonService } from '../service/common/common.service';
import { CommonNotifications } from '../utility/common-notifications';
import { NotificationService } from '@progress/kendo-angular-notification';
import { Auditlogmodel } from '../model/auditlog.model';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  @ViewChild('appendTo', { read: ViewContainerRef }) public appendTo: ViewContainerRef;
 // model: any = {};
 // model2:any={};
 //logo:string="./assets/logo/"+Number(sessionStorage.getItem("entityId"))+"/1/1.png";
 logo:string="./assets/logo/1/1/1.png";
  loading = false;
  returnUrl: string;
  mailValid=false;
  user:any=[];
  auditlogmodel:Auditlogmodel=new Auditlogmodel();

  loginflag:boolean=true;
  changepasswordflag:boolean=false;
  forgetpasswordflag:boolean=false;

  

  constructor(private route: ActivatedRoute,
    private router: Router,private socialAuthService: AuthService,
    private userService:UserService,
    private auth: AuthenticationService,
    private commonService:CommonService,
    private notificationService:NotificationService,
    private modalService: NgbModal,config: NgbTypeaheadConfig
  ) { 

//    window.location.reload();

  }

  ngOnInit() {
 //   this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
debugger
console.log("New Date");
    console.log(new Date())
    // var routdata=this.route.snapshot.queryParams['relogin'];
    // if(routdata==undefined){        
    //         this.entityLogin();
    // }


const params = new URL(location.href).searchParams;
const relogin=params.get('relogin');
if(relogin==undefined){        
            this.entityLogin();
}else{
  this.loginForm.controls.USERID.setValue(sessionStorage.getItem('loginemail'));
  sessionStorage.setItem("entityId",sessionStorage.getItem('entityId'));
  
}


   

    //To clear the error message when user try to enter diferent emailid in forget password screen 
    this.forgetPasswordForm.get("USERID").valueChanges.subscribe(x => {
      this.forgetPasswordError={isError:false,errorMessage:""};  
    })
    //same as above but in change password screen
    this.changePasswordForm.get('USERID').valueChanges.subscribe(x => {
      this.invalidemailError={isError:true,errorMessage:''};   
    })
    this.changePasswordForm.get('PASSWORD').valueChanges.subscribe(x => {
      this.invalidpasswordError={isError:true,errorMessage:''};       
    })
    this.changePasswordForm.get('newPassword').valueChanges.subscribe(x => {
      this.newpasswordError={isError:true,errorMessage:''};       
    })
    this.changePasswordForm.get('conformPassword').valueChanges.subscribe(x => {
      this.conformPasswordError={isError:true,errorMessage:''};      
    })

    //for login page
    this.loginForm.get('USERID').valueChanges.subscribe(x => {
      this.loginemailError={isError:true,errorMessage:''}; 
    })
    this.loginForm.get('PASSWORD').valueChanges.subscribe(x => {
      this.loginPasswordError={isError:true,errorMessage:''}; 
    })
  }

  

  entityLogin() {
    
    let ngbModalOptions: NgbModalOptions = {
        backdrop : 'static',
        keyboard : false,
        size:'lg',
        windowClass: 'modalXl'
   };

   
    const modalRef = this.modalService.open(LoginEntityComponent,ngbModalOptions);
    modalRef.result.then((result) => {
      console.log(result);
    }).catch((error) => {
      console.log(error);
    });
    // window.location.href=CommonConstants.loginUrl;
  }

  public loginForm: FormGroup = new FormGroup({
  //  'USERID':new FormControl('',Validators.compose([Validators.required, Validators.pattern("[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$")])),
  'USERID':new FormControl(),
    'PASSWORD':new FormControl('',Validators.compose([Validators.pattern("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*_=+-]).{8,12}$")])),
  });
  

  loginemailError:any={isError:false,errorMessage:''}; 
  loginPasswordError:any={isError:false,errorMessage:''}; 
  login() {
   debugger
    this.loginemailError={isError:false,errorMessage:''}; 
    this.loginPasswordError={isError:false,errorMessage:''}; 
    if(this.loginForm.get('USERID').value==null||this.loginForm.get('USERID').value== undefined ||this.loginForm.get('USERID').value== ''){
      this.loginemailError={isError:true,errorMessage:'Please enter Email Id.'}; 
    }
    else if(this.loginForm.controls.USERID.errors){
      this.loginemailError={isError:true,errorMessage:'Invalid Email Id.'}; 
    }
    else if(this.loginForm.get('PASSWORD').value==null||this.loginForm.get('PASSWORD').value==undefined|| this.loginForm.get('PASSWORD').value==''){
      this.loginPasswordError={isError:true,errorMessage:'Please enter Password.'}; 
    }
    else if(this.loginForm.controls.PASSWORD.errors){
       this.loginPasswordError={isError:true,errorMessage:'Invalid Password.'};
     }
    else{        
        this.loading = true;
        if(this.loginForm.get('USERID').value!=null && this.loginForm.get('USERID').value!= undefined){ 
          this.userService.getUserById(""+(this.loginForm.get('USERID').value).trim()).subscribe((data:any)=>{
              this.user=data;
              if(this.user.length>0){
                var decriptedpassword=this.commonService.decryptData(data[0].PASSWORD);
                  if(this.loginForm.get('PASSWORD').value===decriptedpassword){

                    this.auth.sendToken(this.loginForm.get('USERID').value)  ;             

                     this.returnUrl="home";
                      window.location.href=CommonConstants.homeUrl;
                    //since there is problem to load the homeurl on linux httpd server   with   ipaddress/home
                    //to overcome this problem i just refresh the page with IP address
                      window.location.href=CommonConstants.clientbaseurl;
                      //set logged in user id Session Storage
                      sessionStorage.setItem("logInuser",data[0].CONSULTANTID);
                      sessionStorage.setItem("subgrpentityid",data[0].subgrpentityid);
                      sessionStorage.setItem("loginemail",data[0].USERID);

console.log(data[0]);




                  //To store record of login details
                 // this.auditlogmodel.Auditid=null;  
                  //this.auditlogmodel.UserId=data[0].CONSULTANTID;
                  //this.auditlogmodel.ActionDate=new Date();
                 // this.auditlogmodel.grpentityid=data[0].grpentityid;
                 // this.auditlogmodel.subgrpentityid=data[0].subgrpentityid;

                 // this.userService.saveauditlog(this.auditlogmodel).subscribe();


                  //End


                      
                  }else{
                      this.returnUrl="login";
                      this.mailValid=true;
                      this.loginPasswordError={isError:true,errorMessage:'Invalid Password.'}; 
                      
                  }
              }else{
                this.loginemailError={isError:true,errorMessage:'Not a Valid Email ID.'}; 
              }
        });
          
      }
    }//closing of else
}

  public socialSignIn(socialPlatform : string) {
    let socialPlatformProvider;
    if(socialPlatform == "google"){
      socialPlatformProvider = GoogleLoginProvider.PROVIDER_ID;
      console.info(GoogleLoginProvider.PROVIDER_ID);
      this.socialAuthService.signIn(socialPlatformProvider).then((userData) => {
        
      this.userService.sendToRestApiMethod(userData.idToken).subscribe(res=>{
        //this.model=res;
        if(res!=null && res!= undefined)
        { 
          this.auth.sendToken(res[0].USERID)
          this.mailValid=false;
          this.returnUrl="dashboard";
          sessionStorage.setItem("logInuser",res[0].CONSULTANTID);
          sessionStorage.setItem("subgrpentityid",res[0].subgrpentityid);
          this.router.navigateByUrl('/'+this.returnUrl).then(()=>
          this.router.navigate([this.returnUrl])); 
          //  window.location.href=CommonConstants.homeUrl;
          window.location.href=CommonConstants.clientbaseurl;
        }else{
            alert("Not a Valid Mail Id");
            this.returnUrl="login";
            this.mailValid=true;
        }
    });      
  });
  }
};











//change password related code
invalidemailError:any={isError:false,errorMessage:''}; 
invalidpasswordError:any={isError:false,errorMessage:''}; 
newpasswordError:any={isError:false,errorMessage:''}; 
conformPasswordError:any={isError:false,errorMessage:''}; 


public changePasswordForm: FormGroup = new FormGroup({
  'USERID':new FormControl('',Validators.compose([Validators.required, Validators.pattern("[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$")])),
  'PASSWORD':new FormControl('',Validators.compose([Validators.required, Validators.pattern("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*_=+-]).{8,12}$")])),
  'newPassword':new FormControl('',Validators.compose([Validators.required, Validators.pattern("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*_=+-]).{8,12}$")])),
  'conformPassword':new FormControl(),  
});
get f() {
  return this.changePasswordForm.controls;
}


changePasswordClick(){
  this.changepasswordflag=true;
  this.loginflag=false;
  this.forgetpasswordflag=false;

  this.loginemailError={isError:false,errorMessage:''}; 
  this.loginPasswordError={isError:false,errorMessage:''};
  
}

cbackClick(){
  this.changepasswordflag=false;
  this.loginflag=true;
  this.forgetpasswordflag=false;

  this.pchangeReset();

  this.invalidemailError={isError:false,errorMessage:''};
  this.invalidpasswordError={isError:false,errorMessage:''};
  this.newpasswordError={isError:false,errorMessage:''};
  this.conformPasswordError={isError:false,errorMessage:''};

}

emailValueChange(){
  console.log("email value change");
  if(this.changePasswordForm.value.USERID.length>0){
    this.invalidemailError={isError:false,errorMessage:''};  
  }
}

changePassword(){
  this.invalidemailError={isError:false,errorMessage:''};
  this.invalidpasswordError={isError:false,errorMessage:''};
  this.newpasswordError={isError:false,errorMessage:''};
  this.conformPasswordError={isError:false,errorMessage:''};
  
  if(this.changePasswordForm.value.USERID==''){
    this.invalidemailError={isError:true,errorMessage:'Please eneter Emai Id.'};
  }else if(this.f.USERID.errors){
    this.invalidemailError={isError:true,errorMessage:'Not a Valid Email ID.'};
  }else if(this.changePasswordForm.value.PASSWORD==''){
    this.invalidpasswordError={isError:true,errorMessage:'Please enter password.'};
  }else if(this.f.PASSWORD.errors){
    this.invalidpasswordError={isError:true,errorMessage:'Not a Valid Password.'};
  }else if(this.changePasswordForm.value.newPassword=='' || this.changePasswordForm.value.newPassword==null){
    this.newpasswordError={isError:true,errorMessage:'Please enter new password.'};
  }else if(this.f.newPassword.errors){
    this.newpasswordError={isError:true,errorMessage:`Not a Valid Password – 
    Your Password should be min.8 char length 
    with combination of Upper Case, Lower Case,
    Special Character and Number.`};
  }else if(this.changePasswordForm.value.conformPassword=='' || this.changePasswordForm.value.conformPassword==null){
    this.conformPasswordError={isError:true,errorMessage:'Please enter password.'};
  }else if(this.changePasswordForm.value.conformPassword!=this.changePasswordForm.value.newPassword){
    this.conformPasswordError={isError:true,errorMessage:'Conform password is not same as new Password.'};
  }else{
                  this.userService.getUserById(this.changePasswordForm.value.USERID).subscribe((data:any)=>{
                    if(data.length>0){
                              var decriptedPassword=this.commonService.decryptData(data[0].PASSWORD);
                             
                              if(decriptedPassword===this.changePasswordForm.value.PASSWORD){
                                  //To incrip password before sending
                                  var password=this.changePasswordForm.get('newPassword').value;
                                  
                                  if(password!=null && password !=undefined && password !=''){
                                    var encriptedPassword=this.commonService.encryptData(password);
                                    this.changePasswordForm.controls['newPassword'].setValue(encriptedPassword);
                                  }
                                  

                                  this.userService.updatePassword(this.changePasswordForm.value).subscribe(
                                    response => { 
                                      this.notificationService.show({
                                        content: "Password changed successfully.",
                                        animation: { type: 'slide', duration: 800 },
                                        position: { horizontal: 'center', vertical: 'bottom' },
                                        type: {style: 'success', icon: true },
                                        //closable: true,
                                        appendTo: this.appendTo,
                                        hideAfter: 8000,
                                        height: 40,
                                        width: 280,
                                        cssClass: 'success-notification',
                                    });
                                      
                                      this.pchangeReset();
                                    },
                                    error=>{CommonNotifications.error(this.notificationService);}
                                  )
                          }else{
                            this.invalidpasswordError={isError:true,errorMessage:"Invalid Password."};
                          }
                          
                    }else{
                      this.invalidemailError={isError:true,errorMessage:"Not a Valid Email ID."};
                    }
                  })     
    }
}

pchangeReset(){
  this.changePasswordForm.controls['USERID'].reset(undefined);
  this.changePasswordForm.controls['PASSWORD'].reset(undefined);
  this.changePasswordForm.controls['newPassword'].reset(undefined);
  this.changePasswordForm.controls['conformPassword'].reset(undefined);
}















//forget password related code
emailobject:EmailArguments=new EmailArguments();
emailInformation:EmailInformation=new EmailInformation();
public forgetPasswordForm: FormGroup = new FormGroup({
  'USERID':new FormControl(),
});

forgetPasswordClick(){
  this.forgetpasswordflag=true;
  this.changepasswordflag=false;
  this.loginflag=false;

  this.loginemailError={isError:false,errorMessage:''}; 
  this.loginPasswordError={isError:false,errorMessage:''};
}

fbackClick(){
  this.forgetpasswordflag=false;
  this.changepasswordflag=false;
  this.loginflag=true;
  this.forgetPasswordForm.controls['USERID'].setValue(undefined);
  this.forgetPasswordError={isError:false,errorMessage:""};
}

forgetPasswordError:any={isError:false,errorMessage:''}; 
sendPassword(){
  if(this.forgetPasswordForm.value.USERID=='' || this.forgetPasswordForm.value.USERID==undefined||this.forgetPasswordForm.value.USERID==null){
    this.forgetPasswordError={isError:true,errorMessage:'Please eneter Emai Id.'}; 
  }else{
              this.userService.getUserById(this.forgetPasswordForm.value.USERID).subscribe((data:any)=>{
                console.log(data);
                if(data.length>0){
                  var name=data[0].Name;
                  if(data[0].Name==undefined){
                    name='     ';
                  }
                  
                    this.forgetPasswordError={isError:false,errorMessage:""};
                    //To assign value on emailobject
                    this.emailobject.from=this.emailInformation.infoEmailid;
                    this.emailobject.frompassword=this.emailInformation.infoPassword;
                    this.emailobject.to=data[0].USERID;

                    this.emailobject.subject="Forgotten Password details";
                    this.emailobject.html=`<b>This is a system generated mail. Please do not reply to this email ID.</b><br><br>
                                          Dear[<b>`+name+`</b>],<br><br>
                                          Your request on forgotten password has been accepted.  You can login with the new password given below:<br><br>
                                          <font color='red'><b>`+this.commonService.decryptData(data[0].PASSWORD)+`</b></font><br><br><br><br><br><br>
                                          --<br><br><br><br><br><br>
                                          <b>Regards</b>`


                    this.emailobject.text='';

                    this.commonService.sendMailWithoutAttachment(this.emailobject).subscribe(
                      response => { 
                                        this.notificationService.show({
                                        content: "Please check your mail for password reset details.",
                                        animation: { type: 'slide', duration: 800 },
                                        position: { horizontal: 'center', vertical: 'bottom' },
                                        type: {style: 'success', icon: true },
                                        //closable: true,
                                        appendTo: this.appendTo,
                                        hideAfter: 10000,
                                        height: 40,
                                        width: 420,
                                        cssClass: 'success-notification',
                                    });

                                    this.forgetPasswordForm.controls['USERID'].setValue(undefined);
                    },
                      error=>{CommonNotifications.error(this.notificationService);}
                        );

                }else{
                  this.forgetPasswordError={isError:true,errorMessage:"Not a Valid Email ID."};
                }
              })
    }
}


useridvalueChange(){
  console.log(this.forgetPasswordForm.value.USERID.length);
  if(this.forgetPasswordForm.value.USERID.length==0){
    this.forgetPasswordError={isError:false,errorMessage:""};
  }
}

}