import { Component, OnInit } from "@angular/core";
import { ChartDataSets, ChartOptions, ChartType } from "chart.js";
import { Color, Label } from "ng2-charts";
import { PlacementRolloffHistory } from "src/app/model/menu/dashboard/placement-rolloff-history.model";
import { PlacementRolloffHistoryService } from "src/app/service/menu/dashboard/placement-rolloff-history.service";

@Component({
  selector: 'app-placement-rolloff-history',
  templateUrl: './placement-rolloff-history.component.html',
  styleUrls: ['./placement-rolloff-history.component.css']
})
export class PlacementRolloffHistoryComponent implements OnInit {

  placementRolloffHistoryModel: PlacementRolloffHistory = new PlacementRolloffHistory();
  months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
  public barChartOptions: ChartOptions = {
  responsive: true,
    scales: { xAxes: [{}], yAxes: [{}] },
    legend: {
      
      labels: {
        fontSize: 12,
        boxWidth	:8,
      },
      onClick: (e) => e.stopPropagation()
     },
    //legend: { display: true },
    animation: {
      onComplete: function () {
          var chartInstance = this.chart,
          ctx = chartInstance.ctx;
          ctx.textAlign = 'center';
          ctx.textBaseline = 'bottom';
          ctx.fo
          this.data.datasets.forEach(function (dataset, i) {
              var meta = chartInstance.controller.getDatasetMeta(i);
              meta.data.forEach(function (bar, index) {
                  var data = dataset.data[index];
                  if (data > 0) {
                    ctx.fillText(data, bar._model.x, bar._model.y - 5);
                 }
              });
          });
      }
  }

  };



  public barChartLabels: Label[] = [];
  public barChartType: ChartType = 'bar';
 
  public barChartLegend = true;
  public barChartData: ChartDataSets[]=[];

  constructor(private placementRolloffHistoryService: PlacementRolloffHistoryService) { }
rolloff:any[];
placement:any[];
  ngOnInit(): void {
    // this.placementRolloffHistoryService.loadmonthlabel(this.placementRolloffHistoryModel).subscribe((data:any[])=>{
    //     this.barChartLabels=[];
    //     for(let i=0;i<data.length;i++){
    //       this.barChartLabels.push(data[i].monthyear);
    //     }
    //   });
    this.loadlabel();

    this.placementRolloffHistoryService.loadplacement(this.placementRolloffHistoryModel).subscribe((data1:any[])=>{
        this.barChartData=[];
        this.rolloff=[];
        this.placement=[];
        for(let i=0;i<data1.length;i++){
          this.rolloff.push(data1[i].value);
        }
        if(data1.length==12){
        this.barChartData.push({data:this.rolloff, label:'Placement', backgroundColor:'#7FFA9B', borderColor:'#7FFA9B', hoverBackgroundColor:'#63ff87', hoverBorderColor:'#63ff87'})
        }else{
          this.barChartData.push({data:this.complete12month(data1), label:'Placement', backgroundColor:'#7FFA9B', borderColor:'#7FFA9B', hoverBackgroundColor:'#63ff87', hoverBorderColor:'#63ff87'})
          
        }

        this.placementRolloffHistoryService.loadrolloff(this.placementRolloffHistoryModel).subscribe((data2:any[])=>{
          for(let i=0;i<data2.length;i++){
            this.placement.push(data2[i].value);
          }
          if(data2.length==12){
            this.barChartData.push({data:this.placement, label: 'Rolloff', backgroundColor:'#F4989C', borderColor:'#F4989C', hoverBackgroundColor:'#ff6e74', hoverBorderColor:'#ff6e74'})
          }else{
            this.barChartData.push({data:this.complete12month(data2), label: 'Rolloff', backgroundColor:'#F4989C', borderColor:'#F4989C', hoverBackgroundColor:'#ff6e74', hoverBorderColor:'#ff6e74'})
          }
        })
    })
  }


  placementRolloffHistory: PlacementRolloffHistory;
  employeeList: any[] = [];
  title: string;
  public chartClicked(e: any): void {
    if (e.active.length > 0) {
      const chart = e.active[0]._chart;
      const activePoints =chart.getElementAtEvent(e.event);
      if (activePoints.length > 0) {
        // get the internal index of slice in pie chart
        const clickedElementIndex = activePoints[0]._index;
        const label = chart.data.labels[clickedElementIndex];
        
        //get on which series clicked either on placement or rolloff
        var points = [];
        var pointSelected = e.active[0]._chart.tooltip._model.caretY;
        for (var i = 0; i < e.active.length; ++i) {
          points.push(e.active[i]._model.y);
        }
        let position = points.indexOf(pointSelected);
        this.placementRolloffHistoryModel.index=position;
        //End
        
        
        // get value by index
        const value = chart.data.datasets[position].data[clickedElementIndex];
        var val = ("" + label).split(" ");
        this.title = `List of Employees - ${label} - ${value}`;
        this.placementRolloffHistoryModel.month = (this.months.indexOf(val[0]) + 1)
        this.placementRolloffHistoryModel.year = Number(val[1]);
        this.employeeList = [];
        this.placementRolloffHistoryService.loadEmployee(this.placementRolloffHistoryModel).subscribe((data: any[]) => {
          this.employeeList = data;
        });
        this.placementRolloffHistory = new PlacementRolloffHistory();

      }
    }
  }

  loadlabel(){
    var label=[];
    var currentdate;
    var flag=false;
    for(let i=(new Date()).getMonth();i>=0;i--){
      currentdate=this.months[i]+" "+ (new Date()).getFullYear();
      this.barChartLabels.push(currentdate)
      if(i==0){
          flag=true;
      }
    }
    if(flag==true && label.length!=12){
      for(let i=11;i>(new Date()).getMonth();i--){
        currentdate=this.months[i]+" "+ ((new Date()).getFullYear()-1);
        this.barChartLabels.push(currentdate)
      }
    }
  }



  complete12month(data) {
    var currentmonth=(new Date().getMonth()+1);
    var flag=true;
    var m=[];
    for(let i=0;i<data.length;i++){
      if(flag==false){
        i=i-1
        flag=true;
      }
   if(currentmonth==data[i].monthnumber){
         m.push(data[i].value) 
         if(currentmonth==1){
          currentmonth=12
        }else
        currentmonth=currentmonth-1;
  }else{
         m.push(0);
         flag=false
         if(currentmonth==1){
          currentmonth=12
        }else
        currentmonth=currentmonth-1;
  }
  }
  return m
  }
 
}