import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppComponent } from './app.component';
import {
  AuthServiceConfig,
  GoogleLoginProvider
} from "angular-6-social-login";
import { SetupModule } from './menu/setup/setup.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { NotificationService, NotificationContainerComponent, NotificationComponent } from '@progress/kendo-angular-notification';
import { ScrollViewModule } from '@progress/kendo-angular-scrollview';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';





// Configs 
export function getAuthServiceConfigs() {
  let config = new AuthServiceConfig(
        [{
          id: GoogleLoginProvider.PROVIDER_ID,
         // provider: new GoogleLoginProvider("722047169653-648gajboleu4ufgn4rncga36787in0dg.apps.googleusercontent.com") //172.20.1.157
          //provider: new GoogleLoginProvider("261465140783-mitdustm06vom7ql44lik1kgr1u1gpj5.apps.googleusercontent.com") //for ipp.tekplay.com
          
         provider: new GoogleLoginProvider("701412373976-icpk06ddt6d2nqcrd9ui4emndoi7bbfb.apps.googleusercontent.com") //for ipp.tekplay.com
          


        }]
  );
  return config;
};


@NgModule({
  declarations: [
    NotificationContainerComponent,
    NotificationComponent,
    
  ],
  imports: [
    SetupModule, 
    BrowserModule,   
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,
    ScrollViewModule,
   

   
   
  ],
 
  bootstrap: [AppComponent],
  providers:[NotificationService,NgbActiveModal],
  entryComponents:[NotificationContainerComponent,
    NotificationComponent],
  
})


export class AppModule {
    strName:string;
    constructor(){
      console.log("App Module loading");
    }
 }
