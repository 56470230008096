import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { CommonConstants } from 'src/app/utility/common-constants';
import { SubGroupEntityAddress } from 'src/app/model/menu/masters/sub-group-entity-address.model';


const httpOptions = {
  headers: new HttpHeaders({ 
    'Access-Control-Allow-Origin':'*',
    'Authorization':'authkey',
    'Access-Control-Allow-Methods': 'GET, PUT, POST, DELETE, HEAD',
  })
};

@Injectable({
  providedIn: 'root'
})
export class SubGroupEntityAddressService extends BehaviorSubject<any[]> {
  constructor(private http: HttpClient) {
      super([]);
  }

 

  customersObservable : Observable<String[]>;
  public getSubGroupEntityAddress(subGroupEntity:any):Observable<any>  {
      debugger
          return this.http.get(CommonConstants.baseUrl+'/'+CommonConstants.subGroupEntity+'/'+"subGroupEntityAddress"+'/'+subGroupEntity.subgrpentityid,httpOptions);
          }


          createSubGroupEntityAddress(subGroupEntityAddress: SubGroupEntityAddress) {
            debugger
              return this.http.post(CommonConstants.baseUrl+'/'+CommonConstants.subGroupEntity+'/'+"subGroupEntityAddress"+'/'+CommonConstants.add, subGroupEntityAddress).subscribe();
            }
          
          updateSubGroupEntityAddress(subGroupEntityAddress: SubGroupEntityAddress) {
              return this.http.post(CommonConstants.baseUrl+'/'+CommonConstants.subGroupEntity+'/'+"subGroupEntityAddress"+'/'+CommonConstants.edit+'/' + subGroupEntityAddress.subgrpentityid, subGroupEntityAddress,httpOptions).subscribe(response => console.log(response));
            }

            deleteSubGroupEntityAddress(subGroupEntityAddress: SubGroupEntityAddress) {
              return this.http.post(CommonConstants.baseUrl+'/'+CommonConstants.subGroupEntity+'/'+"subGroupEntityAddress"+'/'+CommonConstants.delete+'/'+ subGroupEntityAddress.subgrpentityid, subGroupEntityAddress,httpOptions);
            }

                      
                                  public getMaxSubGroupEntityAddressSlno(subgrpEntityId:number):Observable<any>  {
                                      debugger;
                                      return this.http.get(CommonConstants.baseUrl+'/'+CommonConstants.subGroupEntity+'/'+"getMaxSubGroupEntityAddress"+'/'+subgrpEntityId,httpOptions);
                                          }

                                       
}