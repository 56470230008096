import { NgModule, ModuleWithProviders } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';

import {HomeComponent} from './home/home.component';
import { LoginComponent } from 'src/app/login/login.component';
import { DashboardComponent} from './menu/Dashboard/dashboard/dashboard.component';
import { AuthGuard } from './auth.guard';
import {LoginEntityComponent } from 'src/app/loginentity/loginentity.component';
import { BlankcomponentComponent } from './menu/Dashboard/blankcomponent/blankcomponent.component';
import { CustomPreloadingStrategy } from './service/common/custom-preloading-strategy';
const appRoutes: Routes = [
     {path: 'blank', component:BlankcomponentComponent},
     {path: 'login', component: LoginComponent},
     {path: 'dashboard', component: DashboardComponent},

    { path: 'Accounts',
      loadChildren: () => import('src/app/menu/accounts/accounts.module').then(m => m.AccountsModule),
      data : {preload:false}
    },
    { path: 'Admin',
      loadChildren: () => import('src/app/menu/admin/admin.module').then(m => m.AdminModule),
      //loadChildren:'src/app/menu/admin/admin.module#AdminModule',
      data : {preload:false}
    },
    { path: 'Masters',
      loadChildren: () => import('src/app/menu/masters/masters.module').then(m => m.MastersModule),
     // loadChildren:'src/app/menu/masters/masters.module#MastersModule',
      data : {preload:false}
    },
    { path: 'ERM',
      loadChildren: () => import('src/app/menu/erm/erm.module').then(m => m.ErmModule),
      //loadChildren:'src/app/menu/erm/erm.module#ErmModule'
    },
    { path: 'Marketing',
      loadChildren: () => import('src/app/menu/marketing/marketing.module').then(m => m.MarketingModule),
      //loadChildren:'src/app/menu/marketing/marketing.module#MarketingModule'
    },
    { path: 'MyTimeSheet',
      loadChildren: () => import('src/app/menu/mytimesheet/mytimesheet.module').then(m => m.MytimesheetModule),
      //loadChildren:'src/app/menu/mytimesheet/mytimesheet.module#MytimesheetModule'
    },
    { path: 'MyVacation',
      loadChildren: () => import('src/app/menu/myvacation/myvacation.module').then(m => m.MyvacationModule),
      //loadChildren:'src/app/menu/myvacation/myvacation.module#MyvacationModule'
    },
    { path: 'Reports',
      loadChildren: () => import('src/app/menu/reports/reports.module').then(m => m.ReportsModule),
     // loadChildren:'src/app/menu/reports/reports.module#ReportsModule'
    },
    { path: 'MyProfile',
      loadChildren: () => import('src/app/menu/myprofile/myprofile.module').then(m => m.MyprofileModule),
      //loadChildren:'src/app/menu/myprofile/myprofile.module#MyprofileModule'
    },
 { path: 'MyProjects',
    loadChildren: () => import('src/app/menu/myproject/myproject.module').then(m => m.MyprojectModule),
    //loadChildren:'src/app/menu/myprofile/myprofile.module#MyprofileModule'
  },
    { path: 'Recruitment',
      loadChildren: () => import('src/app/menu/recruitment/recruitment.module').then(m => m.RecruitmentModule),
      //loadChildren:'src/app/menu/recruitment/recruitment.module#RecruitmentModule'
    },
    { path: 'MySkillSet',
      loadChildren: () => import('src/app/menu/my-skillset/my-skillset.module').then(m => m.MySkillsetModule),
      //loadChildren:'src/app/menu/my-skillset/my-skillset.module#MySkillsetModule'
    },



    //Hr Employeee Routing
    { path: 'Employee',
      loadChildren: () => import('src/app/menu/hr-employee/hr-employee.module').then(m => m.HrEmployeeModule),
     //loadChildren:'src/app/menu/hr-employee/hr-employee.module#HrEmployeeModule'
    },   
    { path: 'HR Home',
      loadChildren: () => import('src/app/menu/hrhome/hrhome.module').then(m => m.HRHomeModule),
      //loadChildren:'src/app/menu/hrhome/hrhome.module#HRHomeModule'
    },   
    { path: 'New Employee',
      loadChildren: () => import('src/app/menu/hr-new-employee/hr-new-employee.module').then(m => m.HrNewEmployeeModule),
      //loadChildren:'src/app/menu/hr-new-employee/hr-new-employee.module#HrNewEmployeeModule'
    },
    { path: 'Communications',
      loadChildren: () => import('src/app/menu/hr-rules/hr-rules.module').then(m => m.HrRulesModule),
      //loadChildren:'src/app/menu/hr-rules/hr-rules.module#HrRulesModule'
    },


    { path : '', component: HomeComponent, pathMatch : 'full' , canActivate: [AuthGuard]},
    { path: '', redirectTo: 'Entity', pathMatch: 'full' },
    { path: 'Entity', component: LoginEntityComponent},
    
    
    {
      path: 'Home',
      component: HomeComponent,
      canActivate:[AuthGuard],
      children: [
          {path:'dashboard', component: DashboardComponent},
         ],
    },

   
   // { path: '**', component: PageNotFoundError },

];



@NgModule({
  //**************preload all lazy loading modules *******
   /*imports: [RouterModule.forRoot(appRoutes,{
     preloadingStrategy:PreloadAllModules
   }), 
   ],*/

   //**************preload customise lazy loading modules *******
   imports: [RouterModule.forRoot(appRoutes,{
     preloadingStrategy:CustomPreloadingStrategy
   }), 
   ],

//************ Lazy Loading************* */
  //imports: [RouterModule.forRoot(appRoutes)],
  exports: [RouterModule],
  declarations:[],
  providers:[CustomPreloadingStrategy]
  
})
export class AppRoutingModule {
  constructor(){
    console.log("App Routing module loaded");
  }
 }
