export class EmployeeVacationDetails {
    COMPANYCODE:number=Number(sessionStorage.getItem("entityId"));
    CONSULTANTID:string;
    DEPENDANTNUMBER:number=0;
    VACATIONSTARTDT:any;
    ESTIMATEDENDDT:any;
    REMARKS:string;
    RETURNTAG:string;
    RETURNDT:any;
    REPORTEDDT:any;
    isoutofcountry:any;
    grpentityid:number=Number(sessionStorage.getItem("entityId"));
    subgrpentityid:number;
    CREATEDUSERID=sessionStorage.getItem("logInuser"); 
    MODIFIEDUSERID=sessionStorage.getItem("logInuser");
    CREATEDDT:any;
    MODIFIEDDT:any;
    APPROVEDFLG:any='N'
    REPORTINGMANAGERCOMMENTS:string;
}
