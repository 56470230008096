import { Injectable } from '@angular/core';
import { CommonConstants } from 'src/app/utility/common-constants';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class EmployeeService {
  baseUrl: string = CommonConstants.baseUrl+'/dashboard';

  constructor(public http:HttpClient) { }
  
  employeecount(obj){
    return this.http.post(this.baseUrl+"/employeecount",obj);
  }

  employeelist(obj){
    return this.http.post(this.baseUrl+"/employeelist",obj);
  }
}
