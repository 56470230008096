export class EmailArguments{
    from:String;
    frompassword:String;
    to:any;
    subject:String;
    text:String;
    html:String;
   
        filename: String;
        content: any;
        contentType: String;
        cc:any;
   }