import { Component, OnInit, Input, ViewEncapsulation, Output, EventEmitter } from '@angular/core';
import { MarketingSkillSetDetails } from 'src/app/model/menu/marketing/marketing-skill-set-details.model';
import { CommonService } from 'src/app/service/common/common.service';
import { FormGroup, FormControl } from '@angular/forms';
import { MarketingSkillSetDetailsService } from 'src/app/service/menu/marketing/marketing-skill-set-details.service';
import { SkillNatureTypeService } from 'src/app/service/menu/masters/skillset/skill-nature-type.service';

@Component({
  encapsulation: ViewEncapsulation.Emulated,
  selector: 'app-skillset-details-edit',
  templateUrl: './skillset-details-edit.component.html',
  styleUrls: ['./skillset-details-edit.component.css']
})
export class SkillsetDetailsEditComponent implements OnInit {

  private active = false;
  @Input() private isNew = false;
  //skillsetList:any=[];
  
  selectedListData:any=[];
  clonedArray:any=[];
consultingTypeList:any=[];
consultingTitleOrRoleList:any=[];
lobList:any=[];
expertiseIndOrDomainList:any=[];
expertiseTechnologyList:any=[];
sapSkillsList:any=[];
programmingSkillsList:any=[];
otherSkillsList:any=[];
consultingTypeDropdownSettings:any={
  singleSelection:false,
  idField:'SKILLDESCID',
  textField:'SKILLDESC',
  selectAllText:'Select All',
  unSelectAllText:'UnSelect All',
  //itemsShowLimit:5,
  allowSearchFilter:true
};
consultingTitleOrRoleDropdownSettings:any={
  singleSelection:false,
  idField:'SKILLDESCID',
  textField:'SKILLDESC',
  selectAllText:'Select All',
  unSelectAllText:'UnSelect All',
  //itemsShowLimit:5,
  allowSearchFilter:true
};
lobDropdownSettings:any={
  singleSelection:false,
  idField:'SKILLDESCID',
  textField:'SKILLDESC',
  selectAllText:'Select All',
  unSelectAllText:'UnSelect All',
  //itemsShowLimit:5,
  allowSearchFilter:true
};
expertiseIndOrDomainDropdownSettings:any={
  singleSelection:false,
  idField:'SKILLDESCID',
  textField:'SKILLDESC',
  selectAllText:'Select All',
  unSelectAllText:'UnSelect All',
  //itemsShowLimit:5,
  allowSearchFilter:true
};
expertiseTechnologyDropdownSettings:any={
  singleSelection:false,
  idField:'SKILLDESCID',
  textField:'SKILLDESC',
  selectAllText:'Select All',
  unSelectAllText:'UnSelect All',
  //itemsShowLimit:5,
  allowSearchFilter:true
};
sapSkillsDropdownSettings:any={
  singleSelection:false,
  idField:'SKILLDESCID',
  textField:'SKILLDESC',
  selectAllText:'Select All',
  unSelectAllText:'UnSelect All',
  //itemsShowLimit:5,
  allowSearchFilter:true
};
programmingSkillsDropdownSettings:any={
  singleSelection:false,
  idField:'SKILLDESCID',
  textField:'SKILLDESC',
  selectAllText:'Select All',
  unSelectAllText:'UnSelect All',
  //itemsShowLimit:5,
  allowSearchFilter:true
};
otherSkillsDropdownSettings:any={
  singleSelection:false,
  idField:'SKILLDESCID',
  textField:'SKILLDESC',
  selectAllText:'Select All',
  unSelectAllText:'UnSelect All',
  //itemsShowLimit:5,
  allowSearchFilter:true
};
//skillsetNatureList:any=[];
editConsultingType:any=[];
editConsultingTitle:any=[];
editLob:any=[];
editExpertiseIndOrDomain:any=[];
editExpertiseTech:any=[];
editSAPSkills:any=[];
editProgrammingSkills:any=[];
editOtherSkills:any=[];
maxSlNo:any;
strArrayList:any=[];
@Output() cancel: EventEmitter<any> = new EventEmitter();
@Output() save: EventEmitter<MarketingSkillSetDetails> = new EventEmitter();

  constructor(private commonService:CommonService,private skillsetDetailsService:MarketingSkillSetDetailsService,
              private skillNatureTypeService:SkillNatureTypeService) { }

  ngOnInit() {
  }

   public editForm: FormGroup = new FormGroup({
    'CONSULTANTID':new FormControl(),  
    'SKILLDTLID':new FormControl(),
    'SKILLNATUREID':new FormControl(),
    'SKILLNATURETYPEID':new FormControl(),
    'SKILLDESCID':new FormControl(),
    'CONSULTINGTYPE':new FormControl(),//Temporay FormControl  start
    'CONSULTINGTITLEORROLE':new FormControl(),
    'LOB':new FormControl(),
    'EXPERTISEINDUSTRYORDOMAIN':new FormControl(),
    'EXPERTISETECH':new FormControl(),
    'SAPSKILLS':new FormControl(),
    'PROGRAMMINGSKILLS':new FormControl(),
    'OTHERSKILLS':new FormControl(),//End
    'grpentityid':new FormControl(),
    'subgrpentityid':new FormControl(),
    'CREATEDUSERID':new FormControl(),
    'CREATEDDT':new FormControl(),
    'MODIFIEDUSERID':new FormControl(),
    'MODIFIEDDT':new FormControl(),
  });
   
  @Input() public set model(skillSetModel:MarketingSkillSetDetails) {
    this.active = skillSetModel !== undefined;
    this.editForm.reset(skillSetModel);
    this.loadDropDown(skillSetModel);
    if(this.isNew==true){//Add Case
      this.skillsetDetailsService.loadMaxIdByConsultantId(skillSetModel).subscribe((data:any)=>{
        this.maxSlNo=data[0].SlNo;
      });
    }
      
  setTimeout(() => {
    
    if(this.isNew==false){//For Edit
        
       this.skillsetDetailsService.loadSavedSkillDtlByConsultantId(skillSetModel).subscribe((data:any)=>{
            if(data.length>0){
                for(let i=0;i<data.length;i++){
                  this.selectedListData.push({SKILLNATUREID:data[i].SKILLNATUREID,SKILLNATURETYPEID:data[i].SKILLNATURETYPEID,
                    SKILLDESCID:data[i].SKILLDESCID,SKILLDESC:data[i].SKILLDESC});
                     if(data[i].SKILLNATUREID==1 && data[i].SKILLNATURETYPEID==1){
                         this.editConsultingType.push(data[i]);
                         this.editForm.controls['CONSULTINGTYPE'].reset(this.editConsultingType);
                     }else if(data[i].SKILLNATUREID==1 && data[i].SKILLNATURETYPEID==2){
                      this.editConsultingTitle.push(data[i]);
                      this.editForm.controls['CONSULTINGTITLEORROLE'].reset(this.editConsultingTitle);
                     }else if(data[i].SKILLNATUREID==2 && data[i].SKILLNATURETYPEID==1){
                      this.editLob.push(data[i]);
                      this.editForm.controls['LOB'].reset(this.editLob);
                     }else if(data[i].SKILLNATUREID==3 && data[i].SKILLNATURETYPEID==1){
                      this.editExpertiseIndOrDomain.push(data[i]);
                      this.editForm.controls['EXPERTISEINDUSTRYORDOMAIN'].reset(this.editExpertiseIndOrDomain);
                     }else if(data[i].SKILLNATUREID==3 && data[i].SKILLNATURETYPEID==2){
                      this.editExpertiseTech.push(data[i]);
                      this.editForm.controls['EXPERTISETECH'].reset(this.editExpertiseTech);
                     }else if(data[i].SKILLNATUREID==4 && data[i].SKILLNATURETYPEID==1){
                      this.editSAPSkills.push(data[i]);
                      this.editForm.controls['SAPSKILLS'].reset(this.editSAPSkills);
                     }else if(data[i].SKILLNATUREID==4 && data[i].SKILLNATURETYPEID==2){
                      this.editProgrammingSkills.push(data[i]);
                      this.editForm.controls['PROGRAMMINGSKILLS'].reset(this.editProgrammingSkills);
                     }else if(data[i].SKILLNATUREID==4 && data[i].SKILLNATURETYPEID==3){
                      this.editOtherSkills.push(data[i]);
                      this.editForm.controls['OTHERSKILLS'].reset(this.editOtherSkills);
                     }
                }// end for loop
                
            }


            var e=document.getElementById('clickme');
            e.click();
            // setTimeout(() => {
            //   this.editForm.controls['CONSULTINGTYPE'].reset(this.editConsultingType);
            // this.editForm.controls['CONSULTINGTITLEORROLE'].reset(this.editConsultingTitle);
            // this.editForm.controls['LOB'].reset(this.editLob);
            // this.editForm.controls['EXPERTISEINDUSTRYORDOMAIN'].reset(this.editExpertiseIndOrDomain);
            //  this.editForm.controls['EXPERTISETECH'].reset(this.editExpertiseTech);
            //  this.editForm.controls['SAPSKILLS'].reset(this.editSAPSkills);
            // this.editForm.controls['OTHERSKILLS'].reset(this.editOtherSkills);
            // this.editForm.controls['PROGRAMMINGSKILLS'].reset(this.editProgrammingSkills);
            // console.log(this.editForm.value);
            // }, 50);
       });
      
    }
  }, 10);
  
    
  }
  
  loadDropDown(value:any){
    //Load Consulting Type List
    this.skillsetDetailsService.loadConsultingTypeDropDown(value).subscribe((data:any)=>{
      this.consultingTypeList=data;
    });
    this.skillsetDetailsService.loadConsultingTitleOrRoleDropDown(value).subscribe((data:any)=>{
      this.consultingTitleOrRoleList=data;
    });
    this.skillsetDetailsService.loadLineOfBusinessDropDown(value).subscribe((data:any)=>{
      this.lobList=data;
    });
    this.skillsetDetailsService.loadExpertiseIndOrDomainDropDown(value).subscribe((data:any)=>{
      this.expertiseIndOrDomainList=data;
    });
    this.skillsetDetailsService.loadExpertiseTechnologyDropDown(value).subscribe((data:any)=>{
      this.expertiseTechnologyList=data;
    });
    this.skillsetDetailsService.loadSAPSkillsDropDown(value).subscribe((data:any)=>{
      this.sapSkillsList=data;
    });
    this.skillsetDetailsService.loadProgrammingSkillsDropDown(value).subscribe((data:any)=>{
      this.programmingSkillsList=data;
    });
    this.skillsetDetailsService.loadOtherSkillsDropDown(value).subscribe((data:any)=>{
      this.otherSkillsList=data;
    });

  }
  onItemSelect(obj:any,SKILLNATUREID:any,SKILLNATURETYPEID:any){
    debugger;
    console.log("Function called");
    var flag=0;
    // if(this.selectedListData.length>0){
    //      for(let i=0;i<this.selectedListData.length;i++){
    //           if(obj.SKILLDESCID !=this.selectedListData[i].SKILLDESCID && SKILLNATUREID ==this.selectedListData[i].SKILLNATUREID
    //             && SKILLNATURETYPEID ==this.selectedListData[i].SKILLNATURETYPEID){
    //               this.selectedListData.push({SKILLNATUREID:SKILLNATUREID,SKILLNATURETYPEID:SKILLNATURETYPEID,SKILLDESCID:obj.SKILLDESCID,
    //                                           SKILLDESC:obj.SKILLDESC});

    //               break;                            
    //              }else if(SKILLNATUREID !=this.selectedListData[i].SKILLNATUREID
    //               && SKILLNATURETYPEID !=this.selectedListData[i].SKILLNATURETYPEID){
    //                 this.selectedListData.push({SKILLNATUREID:SKILLNATUREID,SKILLNATURETYPEID:SKILLNATURETYPEID,SKILLDESCID:obj.SKILLDESCID,
    //                                             SKILLDESC:obj.SKILLDESC});
  
    //                 break;                            
    //                }
    //      }
    // }else{
    //   this.selectedListData.push({SKILLNATUREID:SKILLNATUREID,SKILLNATURETYPEID:SKILLNATURETYPEID,SKILLDESCID:obj.SKILLDESCID,SKILLDESC:obj.SKILLDESC});
    // }
    this.selectedListData.push({SKILLNATUREID:SKILLNATUREID,SKILLNATURETYPEID:SKILLNATURETYPEID,SKILLDESCID:obj.SKILLDESCID,SKILLDESC:obj.SKILLDESC});
    console.log(this.selectedListData);
  }
  onSelectAll(obj:any,SKILLNATUREID:any,SKILLNATURETYPEID:any){
    debugger;
    console.log("Function called");
    var flag=0;
    if(obj.length>0){
      if(this.selectedListData.length>0){
          for(let i=0;i<obj.length;i++){
            flag=0;
              for(let j=0;j<this.selectedListData.length;j++){
                  if(obj[i].SKILLDESCID ==this.selectedListData[j].SKILLDESCID && SKILLNATUREID ==this.selectedListData[j].SKILLNATUREID
                      && SKILLNATURETYPEID ==this.selectedListData[j].SKILLNATURETYPEID) {
                      flag=1;
                      break;
                      }   
              }
              if(flag!=1){
                this.selectedListData.push({SKILLNATUREID:SKILLNATUREID,SKILLNATURETYPEID:SKILLNATURETYPEID,SKILLDESCID:obj[i].SKILLDESCID,SKILLDESC:obj[i].SKILLDESC});
              }
          }
       }else{
        for(let i=0;i<obj.length;i++){
          this.selectedListData.push({SKILLNATUREID:SKILLNATUREID,SKILLNATURETYPEID:SKILLNATURETYPEID,SKILLDESCID:obj[i].SKILLDESCID,SKILLDESC:obj[i].SKILLDESC});
        }
      }
        
        console.log(this.selectedListData);
    }
  }
  onDeSelect(obj:any,SKILLNATUREID:any,SKILLNATURETYPEID:any){
    debugger;
    console.log("Function called");
    this.clonedArray=[];
    this.clonedArray=this.selectedListData.map((x)=>x);
    if(this.clonedArray.length>0){
          this.selectedListData=[];
          for(let i=0;i<this.clonedArray.length;i++){
               if(obj.SKILLDESCID==this.clonedArray[i].SKILLDESCID && SKILLNATUREID ==this.clonedArray[i].SKILLNATUREID
                && SKILLNATURETYPEID ==this.clonedArray[i].SKILLNATURETYPEID){
                   //this.selectedListData.push(this.clonedArray[i]);
                }else{
                  this.selectedListData.push(this.clonedArray[i]);
                }
          }
          // this.selectedListData=this.selectedListData.map((x)=>x.SKILLDESCID!=obj.SKILLDESCID && x.SKILLNATUREID==obj.SKILLNATUREID
          //                                                 && x.SKILLNATURETYPEID==obj.SKILLNATURETYPEID);
    }
    //this.clonedArray=[];
    console.log(this.clonedArray);
    console.log(this.selectedListData);

  }
  onDeSelectAll(obj:any,SKILLNATUREID:any,SKILLNATURETYPEID:any){
    debugger;
    console.log("Function called");
    var flag=0;
    this.clonedArray=[];
    this.clonedArray=this.selectedListData.map((x)=>x);
    if(obj.length>0){
          if(this.clonedArray.length>0){
            this.selectedListData=[];
              for(let i=0;i<obj.length;i++){
                var arr=[];

                  for(let j=0;j<this.clonedArray.length;j++){

                      if(obj[i].SKILLDESCID ==this.clonedArray[j].SKILLDESCID && SKILLNATUREID ==this.clonedArray[j].SKILLNATUREID
                          && SKILLNATURETYPEID ==this.clonedArray[j].SKILLNATURETYPEID) {
                         
                      }  else{
                               arr.push(this.clonedArray[j]); 
                      } 
                  }
                  this.clonedArray=arr;
              }//outer Loop
              console.log(this.clonedArray);
              this.selectedListData=this.clonedArray.map((x)=>x);
          }
        }
        console.log(this.selectedListData);
  }
  onSave(){
      debugger;
      console.log(this.selectedListData);
      console.log(this.editForm.value);
      if(this.selectedListData.length>0){
        this.save.emit(this.editForm.value);
          if(this.isNew==false){
              this.maxSlNo=0;
              this.skillsetDetailsService.delete(this.editForm.value).subscribe((data:any)=>{
                   this.skillsetDetailsService.loadSavedSkillDtlByConsultantId(this.editForm.value).subscribe((data:any)=>{
                     if(data.length==0){
                        for(let i=0;i<this.selectedListData.length;i++){
                          this.maxSlNo++
                          this.editForm.controls['SKILLDTLID'].setValue(this.maxSlNo);
                          this.editForm.controls['SKILLNATUREID'].setValue(this.selectedListData[i].SKILLNATUREID);
                          this.editForm.controls['SKILLNATURETYPEID'].setValue(this.selectedListData[i].SKILLNATURETYPEID);
                          this.editForm.controls['SKILLDESCID'].setValue(this.selectedListData[i].SKILLDESCID);
                          //this.editForm.controls['CONSULTANTID'].setValue('SSIU0021');
                          this.skillsetDetailsService.save(this.editForm.value).subscribe((data:any)=>{
                                console.log(data);
                          });
                       }
                        //this.save.emit(this.editForm.value);
                        this.closeForm();
                       
                     }
                   });
              });
          }else{
            for(let i=0;i<this.selectedListData.length;i++){
              this.maxSlNo++
              this.editForm.controls['SKILLDTLID'].setValue(this.maxSlNo);
              this.editForm.controls['SKILLNATUREID'].setValue(this.selectedListData[i].SKILLNATUREID);
              this.editForm.controls['SKILLNATURETYPEID'].setValue(this.selectedListData[i].SKILLNATURETYPEID);
              this.editForm.controls['SKILLDESCID'].setValue(this.selectedListData[i].SKILLDESCID);
              //this.editForm.controls['CONSULTANTID'].setValue('SSIU0021');
              this.skillsetDetailsService.save(this.editForm.value).subscribe((data:any)=>{
                     console.log(data);
              });
            }// end for
           
              //this.save.emit(this.editForm.value);
              this.closeForm();
          }
          
      }//end If
  }// end of function
  oncancel(){
    this.closeForm();
  }
  closeForm(){
    this.cancel.emit();
    this.active=false;
    this.selectedListData=[];
    this.clonedArray=[];
    this.consultingTypeList=[];
    this.editConsultingType=[];
    this.editConsultingTitle=[];
    this.editLob=[];
    this.editExpertiseIndOrDomain=[];
    this.editExpertiseTech=[];
    this.editSAPSkills=[];
    this.editProgrammingSkills=[];
    this.editOtherSkills=[];
    this.editForm.reset();
  }

}
