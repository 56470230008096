import { Component, OnInit } from "@angular/core";
import { DashboardActiveProject } from "src/app/model/menu/dashboard/dashboard-active-project.model";
import { ActiveProjectService } from "src/app/service/menu/dashboard/active-project.service";

@Component({
  selector: "app-active-project-dashboard",
  templateUrl: "./active-project-dashboard.component.html",
  styleUrls: ["./active-project-dashboard.component.css"],
})
export class ActiveProjectDashboardComponent implements OnInit {
  dashboardActiveProjectModel: DashboardActiveProject = new DashboardActiveProject();
  pieChartLabels: string[] = [];
  pieChartData: number[] = [];

  constructor(private activeProjectService: ActiveProjectService) {
    this.activeProjectService
      .dashboardActiveProjects(this.dashboardActiveProjectModel)
      .subscribe((data: any[]) => {
        if (data.length > 0) {
          for (let i = 0; i < data.length; i++) {
            if (data[i].modecount != null) {
              this.pieChartLabels.push(data[i].modecount);
              this.pieChartData.push(data[i].projectcounts);
            }
          }
        }
      });
  }

  ngOnInit() {}

  public pieChartColors: Array<any> = [{
    backgroundColor: [
      "#c397fc",
      "#F4989C",
      "#ACFCE7",
      "#EBD2B4",
      "#7FFA9B",
    ],
    borderColor: ["white", "white", "white", "white", "white"],
  }];

  public pieChartType: string = "pie";
  public pieChartOptions: any = {
    legend: {
      position: "right",
      labels: {
        fontSize: 12,
        boxWidth: 10,
      },
      onClick: (e) => e.stopPropagation(),
    },
  };

  activeProjectModel: DashboardActiveProject;
  chartClicked(e: any) {
    if (e.active.length > 0) {
      this.activeProjectModel = new DashboardActiveProject();
    }
  }
}
