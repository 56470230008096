import { Component, OnInit } from "@angular/core";
import { PlacementThisMonth } from "src/app/model/menu/dashboard/placement-this-month.model";
import { PlacementThisMonthService } from "src/app/service/menu/dashboard/placement-this-month.service";

@Component({
  selector: "app-placement-this-month",
  templateUrl: "./placement-this-month.component.html",
  styleUrls: ["./placement-this-month.component.css"],
})
export class PlacementThisMonthComponent implements OnInit {
  // Model to hold placement data for the current month
  placementThisMonthModel: PlacementThisMonth = new PlacementThisMonth();
  title: string = "";

  // Labels for the bar chart
  barChartLabels: string[] = [
    "Project Ending",
    "Placed",
    "Roll Off",
    "Internal Projects",
  ];

  // Data for the bar chart
  barChartData: number[] = [];

  clickedOn: string = "";

  constructor(private placementThisMonthService: PlacementThisMonthService) {
    // Fetching placement data for the current month and initializing bar chart data
    this.placementThisMonthService
      .PlacementThisMonth(this.placementThisMonthModel)
      .subscribe((data: any[]) => {
        this.barChartData.push(data[0].projectending);
        this.barChartData.push(data[0].placement);
        this.barChartData.push(data[0].rolloff);
        this.barChartData.push(data[0].internalprojects);
      });
  }

  ngOnInit() {}

  // Color settings for the bar chart
  public barChartColors: Array<any> = [
    {
      backgroundColor: [
        "#c397fc",
        "#7FFA9B",
        "#F4989C",
        "#EBD2B4",
      ],
      borderColor: ["white", "white", "white", "white"],
    },
  ];

  public barChartType: string = "bar";
  // Options for the bar chart
  public barChartOptions: any = {
    layout: {
      padding: { left: 5, right: 5, top: 20, bottom: 0 },
    },
    legend: {
      display: false,
      position: "right",
      labels: { fontSize: 10, boxWidth: 10 },
    },
    scales: {
      xAxes: [{ ticks: { fontSize: 11, fontStyle: "bold" } }],
      yAxes: [{ ticks: { steps: 20, stepValue: 20, min: 0 } }],
    },
    scaleShowVerticalLines: false,
    responsive: true,
    scaleShowValues: true,
    scaleValuePaddingX: 10,
    scaleValuePaddingY: 10,
    animation: {
      onComplete: function () {
        var chartInstance = this.chart,
          ctx = chartInstance.ctx;
        ctx.textAlign = "center";
        ctx.textBaseline = "bottom";
        this.data.datasets.forEach(function (dataset, i) {
          var meta = chartInstance.controller.getDatasetMeta(i);
          meta.data.forEach(function (bar, index) {
            var data = dataset.data[index];
            ctx.fillText(data, bar._model.x, bar._model.y - 5);
          });
        });
      },
    },
  };

  placementThisMonth: PlacementThisMonth;
  employeeList: any[] = [];
  // Handler for bar chart click events
  public chartClicked(e: any): void {
    if (e.active.length > 0) {
      const chart = e.active[0]._chart;
      const activePoints = chart.getElementAtEvent(e.event);
      if (activePoints.length > 0) {
        // get the internal index of slice in pie chart
        const clickedElementIndex = activePoints[0]._index;
        const label = chart.data.labels[clickedElementIndex];
        // Get the value of the clicked bar
        const value = chart.data.datasets[0].data[clickedElementIndex];

        // Set the title and selected option based on the clicked bar
        if (label == this.barChartLabels[0]) {
          this.title = `List of Employees - ${this.barChartLabels[0]} - ${value}`;
          this.placementThisMonthModel.selectedoption = 1;
        } else if (label == this.barChartLabels[1]) {
          this.title = `List of Employees - ${this.barChartLabels[1]} - ${value}`;
          this.placementThisMonthModel.selectedoption = 2;
        } else if (label == this.barChartLabels[2]) {
          this.title = `List of Employees - ${this.barChartLabels[2]} - ${value}`;
          this.placementThisMonthModel.selectedoption = 3;
        } else if (label == this.barChartLabels[3]) {
          this.title = `List of Employees - ${this.barChartLabels[3]} - ${value}`;
          this.placementThisMonthModel.selectedoption = 4;
        }

        this.employeeList = [];
        // Load the employee list based on the selected option
        this.placementThisMonthService
          .loadEmployee(this.placementThisMonthModel)
          .subscribe((data: any[]) => {
            this.employeeList = data;
          });
        this.placementThisMonth = new PlacementThisMonth();
      }
    }
  }
}
