import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {


  count:Number=1;

  constructor() {

   /* if(this.count=1)
    {
      debugger;
      window.location.reload();
    this.count=4;
    }
*/
   }

  ngOnInit() {
    
   
  }

}
