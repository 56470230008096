import { Component, OnInit, ViewEncapsulation, SystemJsNgModuleLoader, HostListener, Inject } from '@angular/core';
import {Router} from "@angular/router";
import { AdminService } from 'src/app/admin.service';
import {AuthenticationService} from '../../../service/menu/dashboard/auth.service';
import { Menu } from 'src/app/menu';
import { subMenu } from 'src/app/subMenu';
import { SharedService } from 'src/app/shared.service';
//import { NavserviceService } from './navservice.service';
import { FormGroup, FormControl } from '@angular/forms';
//import { CommonConstants } from 'src/app/utility/common-constants';
import { CommonConstants } from 'src/app/utility/common-constants';
import { MyProject } from 'src/app/model/menu/myproject/my-project.model';
import { CommonService } from 'src/app/service/common/common.service';
import { FormAccess } from 'src/app/model/formAccess.model';
import { DialogCloseResult, DialogService } from '@progress/kendo-angular-dialog';
import { Dialog } from 'src/app/utility/dialog';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { PlacementThisMonth } from 'src/app/model/menu/dashboard/placement-this-month.model';
import { ReloadDialog } from 'src/app/model/menu/dashboard/reload-diaslog.model';
import { Auditlogmodel } from 'src/app/model/auditlog.model';
import { UserService } from 'src/app/service/menu/admin/user.service';
import { DOCUMENT } from "@angular/common";
var dateFormat = require('dateformat');


const expirationtime =14400000 // in ms 14400000
//const expirationtime =300000

//const expirationtime = 80000// in ms 14400000
@Component({
  selector: 'app-nav-menu',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './nav-menu.component.html',
  styleUrls: ['./nav-menu.component.css'],
 
})
export class NavMenuComponent implements OnInit {
  formAccess:FormAccess=new FormAccess();
  fullname:string="";
  logo:string="./assets/logo/"+this.formAccess.grpentityid+"/"+this.formAccess.subgrpentityid+"/"+this.formAccess.subgrpentityid+".png";
  myProjects:MyProject=new MyProject();

  // to logout when user close the tab of IPP Portal
  @HostListener('window:unload', [ '$event' ])
  unloadHandler(event) {
    alert("window closing work");
     // Runs whenever the user closes tab or browser
     this.logout;
  }
  

@HostListener('window:click', ['$event'])
methodToExecuteOnEvent(event){
 this.resetTimer();
}

 //reloaddialog:ReloadDialog=new ReloadDialog();

 // subMenus:subMenu[];
currentDate:string="";

timer:any= 0;

auditlogmodel:Auditlogmodel=new Auditlogmodel();
public reloaddialog:ReloadDialog;

//reloaddialog:ReloadDialog;  

 subMenus:any[];
  array:Array<any>[];
  dummyArray:any[]=[];
  arr:any[]=[];

  menuDesc:string;
  hide:boolean=false;
  hideEmpDashboard:boolean=false;
  flag:boolean=false;
  ss;

 items: any[] =[] 
 categoryWithSubgroupDesc:Array<any>=[];
 displayInTreeView:boolean=false;
 navmenuwidth:boolean=false;
 loginTime:string='';
 //returnUrl:string='/login';
 idleTime:string='';



 constructor(private adminservice:AdminService,
  private router: Router,
  private auth:AuthenticationService,
  ss: SharedService,
  private commenservice:CommonService,
  private dialogService: DialogService,
  public activeModal: NgbActiveModal,
 private userService:UserService,
 @Inject(DOCUMENT) private document: Document

  ) {
//display consult name
//commenservice.

adminservice.getAuditlogTime(this.myProjects).subscribe((data:any[])=>{
  this.loginTime='';
  if(data.length>0){
   this.loginTime=data[0].dttime;
  }
debugger

adminservice.getServerTime(this.myProjects).subscribe((datas:any[])=>{
             //To store record of login details
           
             this.auditlogmodel.ActionDate=datas[0].dttime;
             this.auditlogmodel.Auditid=null;  
             this.auditlogmodel.UserId=sessionStorage.getItem("logInuser");
         //    this.auditlogmodel.ActionDate=dateFormat(new Date(), "yyyy-mm-dd hh:mm:ss");
		   console.log("Action Date");
             console.log(this.auditlogmodel.ActionDate);

             this.auditlogmodel.grpentityid=data[0].grpentityid;
             this.auditlogmodel.subgrpentityid=data[0].subgrpentityid;
             console.log("audit log");
             console.log(this.auditlogmodel);
             debugger
			console.log(this.auditlogmodel);
             this.userService.saveauditlog(this.auditlogmodel).subscribe(()=>{
               console.log("save audit log");
             }                    
             );
  
})
})




























adminservice.getConsultantName(this.myProjects).subscribe((data:any[])=>{
  if(data.length>0){
    this.fullname=data[0].fullname;
  }
  
})
   
    this.ss = ss;
    //this service is used to load all the menu list
    //this.adminservice.getMenuList().subscribe((menuList:any)=>{
    
    //this service fetch the menu according to authontication of the login user
      this.commenservice.getMenuAccordingToFormAccess(this.formAccess).subscribe((menuList:any[])=>{
       if(menuList.length>0){
              //Here i am trying to add one blank menu as a seperator
              var tempMunuList=[];
              for(let i=0;i<menuList.length;i++){
                    tempMunuList.push(menuList[i])
                    if(menuList[i].MENUID==7){//MENUID 7 means report menu after that i want to add blank space
                      tempMunuList.push({MENUDESC: "   "})
                      tempMunuList.push({MENUDESC: "   "})
                      tempMunuList.push({MENUDESC: "   "})
                      tempMunuList.push({MENUDESC: "   "})

                    }
              }
              menuList=tempMunuList;
              console.log(tempMunuList);


                        //this service call all the list from mennuList view
                        this.adminservice.loadMstmenuviewTable().subscribe((data:any)=>{
                        this.arr=data;
                        console.log(this.arr);
                        if(menuList.length>0){
                                      for(let i=0;i<menuList.length;i++){
                                              //since HR HOME is menu but it's open under Hr hence we restrict here
                                              if(menuList[i].MENUDESC!='HR Home'){
                                                          var flag=0;
                                                          //here we check does any subchild is available for this menu or not
                                                          //if childsubmenu is not availabel then direct add to menu .
                                                          for(let j=0;j<this.arr.length;j++){
                                                                if(this.arr[j].menudesc==menuList[i].MENUDESC && this.arr[j].childsubgroupdesc!=null){
                                                                    flag=1;
                                                                    break;
                                                                }
                                                          }
                                                          // this.adminservice.checkMenuContainSubmenuOrNot(menuList[i].MENUDESC).subscribe((data:any)=>{
                                                        if(flag==0){
                                                            this.items.push({text:menuList[i].MENUDESC,value:menuList[i].MENUID})
                                                        }else{
                                                          this.formAccess.MENUID=menuList[i].MENUID;
                                                          this.commenservice.distinctSubmenuFormAccessDetails(this.formAccess).subscribe((subMenuList:any)=>{
                                                        //this.adminservice.getsubMenuList(menuList[i].MENUID).subscribe((subMenuList:any)=>{
                                                        for(let j=0;j<subMenuList.length;j++){
                                                                      this.flag=false;
                                                                      var menuid=undefined;
              
                                                                      for(let k=0;k<menuList.length;k++){
                                                                        if(menuList[k].MENUDESC==subMenuList[j].subgroupdesc){
                                                                          this.flag=true;
                                                                          menuid=menuList[k].MENUID;
                                                                        }
                                                                      }

                                                                      if(this.flag==true){
                                                                        this.dummyArray.push({text:subMenuList[j].subgroupdesc,value:menuid});
                                                                      }else{
                                                                        this.dummyArray.push({text:subMenuList[j].subgroupdesc});
                                                                      }
                                                        } 
                                                        })
                                                        console.log(this.dummyArray);
                                                        this.items.push({text:menuList[i].MENUDESC,items:this.dummyArray})  
                                 }
                  //  })
                        }
            }
}
     
    }); 
  }//closing of if 
  });

 }
  

  ngOnInit() {

    debugger;
    var monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
    "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    var date=new Date()
    var dt=("0" + date.getDate()).slice(-2)+"-"+monthNames[(date.getMonth())]+"-"+date.getFullYear();
    this.currentDate=dt;

   // this.autologout(expirationtime);
   //this.timer = (this.startIdleTimer,expirationtime);
   
  /*  setInterval(() =>{ 
      this.reloaddialog=new ReloadDialog();
    },expirationtime);
    */

    this.timer =  setInterval(() =>{ 
      this.reloaddialog=new ReloadDialog();
    },expirationtime);
   

  }




//









 
  hideShowLink(subMenu:any)
  {
    debugger;
    if(subMenu.subgroupdesc=="Employee")
    {
      this.hide=false;
    }
  }



  getsubMenu(menu:any)
  {  
    
    this.menuDesc=menu.item.text;
    //this.ss.show();
    debugger;
    //this.ss.getEmittedValue()
    // .subscribe(item => this.hide=item);
   
    
    //this service is used to share the data from nav menu to Emp Dashboard
   // this.service.login();
   debugger  
    this.hide=true;
    this.adminservice.getsubMenuList(menu.item.value).subscribe((subMenuList)=>{
    this.subMenus=subMenuList; 
 
    //this  navmenuwidth is used to decide vertical navmenu width is 20% or 0%
    
     
  
    
    //Code to create array that is used in tree view if subgroup is not null
    this.adminservice.getCategoryFromSelectedMenu(menu.item.value).subscribe((data:any)=>{
                    
                     if(data.length>1){
                                         
                                  this.categoryWithSubgroupDesc=[];
                                  this.displayInTreeView=true;
                                  
                                  for(let i=0;i<data.length;i++){
                                        
                                    var subgroupDesc:Array<any>=[];
                                    for(let j=0;j<this.subMenus.length;j++){
                                                if(data[i].category==this.subMenus[j].CATEGORY){
                                                  subgroupDesc.push({text:this.subMenus[j].subgroupdesc});
                                                }
                                    }
                                    if(data[i].category==null){
                                      //all the null category elements is belongs to others
                                      this.categoryWithSubgroupDesc.push({text:"Others", items:subgroupDesc});
                                    }else{
                                      this.categoryWithSubgroupDesc.push({text:data[i].category, items:subgroupDesc});
                                    }   
                                    
                              }
                    }else{
                      this.displayInTreeView=false
                    }
                  
   });// End of getCategoryFromSelectedMenu

  });//End of getsubMenuList
}

 //   logout(): void {
//     //this.router.navigate(['login']);
//     //window.location.href="http://localhost:4200/login";
//     window.location.href=CommonConstants.loginUrl;
//     debugger;
//  // window.location.reload();
//   };

//auto logout after 4 hour code
//
autologout(expirationtime:number){
  setInterval(() =>{ 
  this.reloaddialog=new ReloadDialog();
},expirationtime);}
    
//     var dialog = this.dialogService.open({
   
//       title: "WARNING!!",
//       content: "Session has timed out. Please login again.",
//       actions: [
//           { text: "RE-LOGIN" , primary: true},
//       ],
//       minWidth: 250,
//       height:130,
// });

//               dialog.result.subscribe((result) => {
//                       if (result instanceof DialogCloseResult) {
//                               setTimeout(() => {
//                                 this.autologout(expirationtime);
//                               }, expirationtime);
//                       } 
//                       else {
//                             if(result.primary){
//                                var loginemail=sessionStorage.getItem('loginemail')
//                                var grpentity=sessionStorage.getItem('entityId')
//                                localStorage.clear();
//                                sessionStorage.clear();
//                                sessionStorage.setItem("entityId",grpentity);
//                                sessionStorage.setItem("loginemail",loginemail);
                                                       
//                                window.location.href=`${CommonConstants.loginUrl}?relogin=Y`;
//                             }
                           
//                       }
//               });
  //},expirationtime);
//}



logout(): void {
   localStorage.removeItem("LoggedInUser");
   window.location.href=CommonConstants.loginUrl;
   window.location.href=CommonConstants.clientbaseurl;
};

  public onSelect( item:any ): void {
    console.log(item);
    //To clear the Screen before load the component so that old screen not seen

    //here first argument for route and second that pass as a argument
    //like this.router.navigate([admin,123]) 
   if(item.item.text.replace(/\s/g, "") !='')//value 14 is seperator menu only.
    this.router.navigate([item.item.text]); 
    //whose menu SUBGROUPID is zero means direct open and non zero id menas it contains subgroup
    // if(item.item.value!=undefined){

    //         this.adminservice.getSubgroupidOfMenu(item.item.value).subscribe((data:any)=>{
    //           if(data[0].SUBGROUPID==0){
    //             this.hide=false;
    //             this.navmenuwidth=false
    //             this.router.navigate([ item.item.text ]);
    //           }else{
    //                     this.adminservice.checkMenuContainSubmenuOrNot(item.item.text).subscribe((data:any)=>{
    //                       this.array=data;
    //                         if(this.array.length==0){
    //                         this.navmenuwidth=true
    //                         this.getsubMenu(item)
    //                       }
    //                     });
    //           }
    //         })
    // }else{
     
    //         this.navmenuwidth=false
    //         if(item.item.text=="Employee" || item.item.text=="New Employee"){
    //           this.hide=false;
    //           this.navmenuwidth=false
    //         }
    //         console.log(this.router.navigate([ item.item.text ]))
    //         this.router.navigate([ item.item.text ]);
    // }

  }

  treeViewNodeClick({item}){
    if(item.dataItem.items==undefined){
       this.router.navigate([ item.dataItem.text ]);
    }
  }
  




         
 resetTimer():void {
 

debugger;
   
   clearInterval(this.timer);
 
   this.timer =
      // setInterval(this.startIdleTimer,expirationtime);

       setInterval(() =>{ 
        this.reloaddialog=new ReloadDialog();
      },expirationtime);
};





//window.onkeypress = resetTimer;
 startIdleTimer():void {
     
   /* Increment the
       timer seconds */
//currSeconds++;

this.reloaddialog=new ReloadDialog();
 
   /* Set the timer text
       to the new value */

}


// export interface LoggedInUserEventArgs{
//   flag:boolean;
// }



}
