import { Injectable } from '@angular/core';
import { HttpClient,HttpHeaders } from '@angular/common/http';
import {User} from "../../../model/menu/admin/user.model";

import { mstUser } from 'src/app/user';
import { Observable } from  "rxjs";
import { CommonConstants } from 'src/app/utility/common-constants';

const httpOptions = {
  headers: new HttpHeaders({ 
    'Access-Control-Allow-Origin':'*',
    'Authorization':'authkey',
    'userid':'1',

    
'Access-Control-Allow-Methods':' GET, POST, PATCH, PUT, DELETE, OPTIONS',
'Access-Control-Allow-Headers': 'Origin, Content-Type, X-Auth-Token',



  })
};

@Injectable({
  providedIn: 'root'
})
export class UserService {
  constructor(private http: HttpClient) { }
  baseUrl: string = CommonConstants.portalUser;
  strUrl:string=CommonConstants.baseUrl+CommonConstants.user;

  customersObservable : Observable<String[]>;

  user:User;

  getUsers(obj:any) {
    return this.http.post(this.strUrl,obj);
  }

  getUserById(id: String) {
    debugger;
    return this.http.get<User>(this.baseUrl + '/' + id);
  }
  
  createUser(obj: any) {
    debugger;
   return this.http.post(this.strUrl+'/'+CommonConstants.add, obj);
  }

  updateUser(obj:any) {
   debugger;
    return this.http.post(this.strUrl+'/'+CommonConstants.edit, obj);
  }

  deleteUser(obj:any) {
    return this.http.post(this.strUrl+'/'+CommonConstants.delete, obj);
  }

  updatePassword(obj:any){
    return this.http.post(this.strUrl+'/updateUserPassword',obj);
  }


  saveauditlog(obj:any){
    return this.http.post(CommonConstants.baseUrl+'/saveauditlog',obj);
  }

  




//    output: getUserById(googleId:String)
 // {
//debugger;
  //  return this.httpClient.get(`${this.apiURL}/googleUser/${googleId}`);

  //}

//}


public emailverification(googleId)  {


return  this.http.get("https://oauth2.googleapis.com/tokeninfo?id_token="+googleId,httpOptions);

}



public sendToRestApiMethod(googleId)  {


  


 return this.http.post(CommonConstants.baseUrl+CommonConstants.googleuser+CommonConstants.slash,
     {
      googleId: googleId,
      clientId:"701412373976-icpk06ddt6d2nqcrd9ui4emndoi7bbfb.apps.googleusercontent.com"
     },httpOptions);
  }


  public postUser(googleId)  {
    return this.http.post(CommonConstants.baseUrl+CommonConstants.user+CommonConstants.slash,
        {
         googleId: googleId
        },httpOptions);
     }

      
   
    }