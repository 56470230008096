import { Injectable } from '@angular/core';
import { HttpClient,HttpHeaders,HttpParams } from '@angular/common/http';
import { mstUser} from 'src/app/user';
//import { User} from 'src/app/model/menu/admin/user.model';
import { Observable } from  "rxjs";

import { Menu} from "./menu";
import { subMenu } from 'src/app/subMenu';
import { CommonConstants } from './utility/common-constants';






const httpOptions = {
  headers: new HttpHeaders({ 
    'Access-Control-Allow-Origin':'*',
    'Authorization':'authkey',
    'userid':'1'
  })
  
};

@Injectable({
  providedIn: 'root'
})
export class AdminService {
   

   apiURL:String=CommonConstants.baseUrl;

  constructor(private httpClient: HttpClient) {}
  customersObservable : Observable<String[]>;


public getMenuIdByName(menudesc:any){
    return this.httpClient.get(this.apiURL+'/getMainMenuId/'+menudesc,menudesc)
}  

public getMenuList():Observable<Menu[]>  {
  debugger;
  return  this.httpClient.get<Menu[]>(this.apiURL+'/'+'admin/mainMenu',
     httpOptions);  
  };

  public getsubMenuList(menuId):Observable<subMenu[]>  {
    debugger;

   // let params = new HttpParams().set('menuId', menuId);
    return  this.httpClient.post<subMenu[]>(this.apiURL+'/'+'admin/subMenu',{ menuId: menuId },httpOptions);  
  
        
    };

    public checkMenuContainSubmenuOrNot(name){
      return this.httpClient.get(this.apiURL+'/ContainSubmenuOrNot/'+name,httpOptions)
    }

    public getEntity(entity:string):Observable<any>  {
      debugger;
      return  this.httpClient.get(this.apiURL+'/entity/'+entity, httpOptions);  
    
          
      };

      
      


      public getMainGroupCompany():Observable<any>  {
        debugger;
        return  this.httpClient.post(this.apiURL+'/groupEntity/', httpOptions);  
      
            
        };
  
        public getSubGroupCompany(entity:any):Observable<any>  {
          debugger;
          return  this.httpClient.post(this.apiURL+'/maingroupcompany/',entity, httpOptions);  
        
              
          };
    
          public getMainGroupCompanyObject(entity:string):Observable<any>  {
            debugger;
            return  this.httpClient.get(this.apiURL+'/groupEntity/'+entity, httpOptions);  
          
                
            };


            public getMainGroupName(entityName:string):Observable<any>  {
              debugger;
              return  this.httpClient.get(this.apiURL+'/mainEntity/'+entityName, httpOptions);  
            
                  
              };


            public getSubgroupidOfMenu(menuid:any){
              return  this.httpClient.get(this.apiURL+'/getSubgroupidOfMenu/'+menuid, httpOptions);  
            }

            public getCategoryFromSelectedMenu(menuid:any){
              return  this.httpClient.get(this.apiURL+'/getCategoryFromSelectedMenu/'+menuid, httpOptions);  
            }

            public loadMstmenuviewTable():Observable<any>  {
              debugger;
              return  this.httpClient.get(this.apiURL+'/loadMstmenuviewTable', httpOptions);  
            };


            
            public getConsultantName(obj:any):Observable<any>  {
              return  this.httpClient.post(this.apiURL+'/getConsultantDetailById',obj, httpOptions);  
            };


            
            public  getAuditlogTime(obj:any):Observable<any>  {
              return  this.httpClient.post(this.apiURL+'/getAuditlogTime',obj, httpOptions);  
            };


            public  getServerTime(obj:any):Observable<any>  {
              return  this.httpClient.post(this.apiURL+'/getServerTime',obj, httpOptions);  
            };

        


}
