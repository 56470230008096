import { NotificationService } from '@progress/kendo-angular-notification';
import { ViewEncapsulation } from '@angular/core';
import { CommonError } from 'src/app/utility/common-error';

export class CommonNotifications{
constructor( ){
    encapsulation: ViewEncapsulation.None
}

    public static success(notificationService:NotificationService,successmsg): void {
       

            notificationService.show({
                
            content: successmsg,
            animation: { type: 'slide', duration: 400 },
            position: { horizontal: 'center', vertical: 'bottom' },
            type: {style: 'success', icon: true },
            //closable: true,
            hideAfter: 1500,
            height: 50,
            width: 500,
            cssClass: 'success-notification',
            
          
            
        });
     }

   
public static error(notificationService:NotificationService): void {
  
    notificationService.show({
       
    content: 'Whoops, Something went wrong. Probably.',
    animation: { type: 'slide', duration: 400 },
    position: { horizontal: 'center', vertical: 'bottom' },
    type: {style: 'error', icon: true },
    //closable: true,
    hideAfter: 1500,
    height: 50,
    width: 500,
    cssClass: 'error-notification',
    
});
}

public static warning(notificationService:NotificationService,warningmsg): void {
  
    notificationService.show({
       
    content: warningmsg,
    animation: { type: 'slide', duration: 400 },
    position: { horizontal: 'center', vertical: 'bottom' },
    type: {style: 'warning', icon: true },
    closable: true,
    //hideAfter: 1200,
    height: 50,
    width: 500,
    cssClass: 'warning-notification',
    
});
}

}