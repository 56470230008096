import { Component, Input, OnInit } from "@angular/core";
import { ActiveProjects } from "src/app/model/menu/reports/projects/active-projects.model";
import { printDiv } from "../print-div";

@Component({
  selector: "app-consultant-status-popup",
  templateUrl: "./consultant-status-popup.component.html",
  styleUrls: ["./consultant-status-popup.component.css"],
})
export class ConsultantStatusPopupComponent implements OnInit {
  public active: boolean = false;
  constructor() {}

  text: string = "";
  @Input() employeeList: any[] = [];
  @Input() title: any;
  @Input() concatLabel: any;
  ngOnInit() {}

  @Input() public set model(obj: ActiveProjects) {
    this.text = "- " + this.concatLabel + " On:";
    if (obj !== undefined) {
      this.active = true;
    }
  }

  closeForm() {
    this.active = false;
  }

  printData(id) {
    debugger;
    printDiv(id, this.title);
  }
}
