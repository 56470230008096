import { Component, OnInit, ViewChild } from '@angular/core';
import { AnniversaryWishesService } from 'src/app/service/menu/dashboard/anniversary-wishes.service';
import { AnniversaryWishes } from 'src/app/model/menu/dashboard/anniversary-wishes.model';
import { EmployeeService } from 'src/app/service/employee.service';

@Component({
  selector: 'app-anniversary-wishes',
  templateUrl: './anniversary-wishes.component.html',
  styleUrls: ['./anniversary-wishes.component.css']
})
export class AnniversaryWishesComponent implements OnInit {

  @ViewChild('sv') private scrollView;
  public paused = false;
  anniversaryWishesModel:AnniversaryWishes=new AnniversaryWishes();
  public items: any[]=[];
  
    
    
  
  constructor(private anniversaryWishesService:AnniversaryWishesService,
    private employeeService:EmployeeService,
    ) { }

  ngOnInit() {
    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];


    this.anniversaryWishesService.anniversaryWishes(this.anniversaryWishesModel).subscribe((data:any[])=>{
     //   console.log(data);
        for(let i=0;i<data.length;i++){
           this.employeeService.getEmployeeImage(data[i]).subscribe((img:any)=>{
            
             var msg=data[i].FIRSTNAME+" "+data[i].LASTNAME+" "+monthNames[new Date(data[i].HIREDDT).getMonth()]+"/"+new Date(data[i].HIREDDT).getDate()+" - "+Math.abs((new Date().getFullYear())-new Date(data[i].HIREDDT).getFullYear())+" + Years";
             
            if(img.fileUrl!=''){
              this.items.push({title:msg , url: img.fileUrl+'?'+(new Date()).getTime()});
           }else{
               this.items.push({title:msg , url:'assets/picture.jpg'});
            }
        });
         
      }  
      //console.log(this.items1);
      
    })

    
  }

//   public items: any[] = [
//     { title: 'Flower', url: 'https://bit.ly/2cJjYuB' },
//     { title: 'Mountain', url: 'https://bit.ly/2cTBNaL' },
//     { title: 'Sky', url: 'https://bit.ly/2cJl3Cx' }
// ];


  
public width = '100%';
public height = '200px';



  private interval;

  public ngAfterViewInit() {
      this.interval = setInterval(() => {
          if (!this.paused) {
              this.scrollView.next();
          }
      }, 3000);
  }

  public ngOnDestroy() {
      clearInterval(this.interval);
  }

  


}
