import { Component, OnInit, Input, ViewEncapsulation, ViewChild } from "@angular/core";
import { DashboardActiveProject } from "src/app/model/menu/dashboard/dashboard-active-project.model";
import { ActiveProjects } from "src/app/model/menu/reports/projects/active-projects.model";
import { ActiveProjectService } from "src/app/service/menu/dashboard/active-project.service";

@Component({
  selector: "app-active-project-popup",
  templateUrl: "./active-project-popup.component.html",
  styleUrls: ["./active-project-popup.component.css"],
  encapsulation: ViewEncapsulation.None,
})
export class ActiveProjectPopupComponent implements OnInit {
  public active: boolean = false;
  tabDesc: any[] = [];
  gridView: any[] = [];
  partTimeGridView: any[] = [];
  onCallBasisgridView: any[] = [];
  offShoregridView: any[] = [];
  tekPlayGridView: any[] = [];

  //public gridView: gridView:any[]=[];DataResult;
  public group: any[] = [];
  public group1: any[] = [];
  public group2: any[] = [];
  public group3: any[] = [];
  public group4: any[] = [];

  activeProjectModel: DashboardActiveProject = new DashboardActiveProject();

  constructor(private activeProjectService: ActiveProjectService) {}
  @Input() pieChartLabels: any;
  @Input() public set model(obj: ActiveProjects) {
    this.tabDesc = this.pieChartLabels;

    if (obj !== undefined) {
      this.active = true;
    }

    this.group = [{ field: "clientname", color: "blue" }];

    this.activeProjectService.loadFullTime(obj).subscribe((data: any[]) => {
      this.gridView = [];
      if (data.length > 0) {
        this.gridView = data;
      }
    });

    this.group1 = [{ field: "clientname", color: "blue" }];
    this.activeProjectService
      .loadPartTime(this.activeProjectModel)
      .subscribe((data: any[]) => {
        this.partTimeGridView = [];
        if (data.length > 0) {
          this.partTimeGridView = data;
        }
      });

    this.group2 = [{ field: "clientname", color: "blue" }];
    this.activeProjectService
      .loadOnCallBasis(this.activeProjectModel)
      .subscribe((data: any[]) => {
        this.onCallBasisgridView = [];
        if (data.length > 0) {
          this.onCallBasisgridView = data;
        }
      });

    this.group3 = [{ field: "clientname", color: "blue" }];
    this.activeProjectService
      .loadOffShoreBasis(this.activeProjectModel)
      .subscribe((data: any[]) => {
        this.offShoregridView = [];
        if (data.length > 0) {
          this.offShoregridView = data;
        }
      });

    this.group4 = [{ field: "clientname", color: "blue" }];
    this.activeProjectService
      .loadTekPlay(this.activeProjectModel)
      .subscribe((data: any[]) => {
        this.tekPlayGridView = [];
        if (data.length > 0) {
          this.tekPlayGridView = data;
        }
      });
  }

  ngOnInit() {}

  closeForm() {
    this.active = false;
  }
}
