import {  Component,OnInit, Output, EventEmitter, Input } from '@angular/core';
import { RecruitmentEligibility } from 'src/app/model/menu/recruitment/recruitment-eligibility.model';
import { UploadDocument } from 'src/app/model/menu/recruitment/upload-document.model';
import { FileRestrictions, SelectEvent, RemoveEvent } from '@progress/kendo-angular-upload';

import {  Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpProgressEvent, HttpEventType, HttpResponse, HttpClient } from '@angular/common/http';
import { Observable, of, concat } from 'rxjs';
import { delay } from 'rxjs/operators';
import { EmployeeService } from 'src/app/service/employee.service';
import { UploadImageService } from 'src/app/service/menu/recruitment/uploadImage.service';
import { Dialog } from 'src/app/utility/dialog';
import { DialogCloseResult, DialogService } from '@progress/kendo-angular-dialog';





@Component({
  selector: 'app-upload-component',
  templateUrl: './upload-component.component.html',
  styleUrls: ['./upload-component.component.css']
})
export class UploadComponentComponent implements OnInit {
  uploadDocument:UploadDocument=new UploadDocument();
  images:any=[];
  //allfiles:any=[];
  constructor(private employeeService:EmployeeService,
    private uploadImageService:UploadImageService,
    private http:HttpClient,
    private dialogService: DialogService,
    ) { }

    imageClick(link:any){
      console.log(link);
    }

  ngOnInit() {

  }
  public active = false;

  @Output() cancel: EventEmitter<any> = new EventEmitter();
  @Input() public selectedRow;
  @Input() public pathDirectory:any;
  @Input() public set model(newTechnical:UploadDocument) {
         debugger;
         
         if(newTechnical !== undefined){
          this.active=true;
         }

         this.filesToUpload=[];
         this.dummyfilesToUpload=[];
         this.alreadyUploadedFile=[];
         this.selectedFileToDeleteFromServer=[];
              this.uploadDocument.CONSULTANTID=this.selectedRow.CONSULTANTID;
         this.uploadDocument.subgrpentityid=this.selectedRow.subgrpentityid;
         this.uploadDocument.pathDirectory=this.pathDirectory;

         

         //this.selectedRow.uploadimage==1 means that consultant id resume is already uploded
         //need to display all the uploaded images
          
         if(this.selectedRow.uploadimage==1){
                        this.alreadyUploadedFile=[];
                        this.uploadImageService.downloadImage(this.uploadDocument).
                        subscribe((files:any[])=>{
                        this.alreadyUploadedFile=files;
                       
                        });  
        }
      
    
  }

  
  

  filesToUpload: Array<File> = [];
  dummyfilesToUpload: Array<File> = [];
  alreadyUploadedFile:any[]=[];
  selectedFileToDeleteFromServer:any[]=[];

  deleteImage:string="./assets/recyclebin.png";
  generalFileImage:string="./assets/generalFileImage.png";



  closeForm(){
    this.active = false;
    this.cancel.emit();
    this.filesToUpload=[];
    this.images=[];
    this.selectedFileToDeleteFromServer=[];
  }


  clickOnSubmit() {
    debugger;
      // var flag=0;
      // debugger
      //   if(this.selectedFileToDeleteFromServer.length>0){
      //     var dialog = Dialog.deleteDialog(this.dialogService);
      //     dialog.result.subscribe((result) => {
      //             if (result instanceof DialogCloseResult) {

      //             } 
      //             else {
      //                   if(!result.primary){
      //                     this.submit();
      //                     //Code to remove the file from the server
      //                     this.removeFileFromServer();        
      //                   }
      //             }
                
      //     });
      //   }
        // flag 1 means user want to delete file from the server
              if(this.selectedFileToDeleteFromServer.length>0){
                    this.uploadImageService.removeFileFromServer(this.selectedFileToDeleteFromServer)
                    .subscribe((response:any)=>{
                      console.log(response);
                           if(response.success==true){
                              this.submit();
                              
                           }
                    });    
              }else{
                this.submit();
              }
              // if(this.filesToUpload.length>0){
              //        this.submit();
              // }
           
             
      
}

submit(){
  const formData: any = new FormData();
  const files: Array<File> = this.filesToUpload;
  for(let i =0; i < files.length; i++){
     formData.append("uploads[]", files[i], files[i]['name']);
  }
  this.uploadImageService.uploadImage(formData,this.uploadDocument); 
  setTimeout(() => {
    this.active = false;
    this.filesToUpload=[];
    this.cancel.emit();
    this.images=[];
    this.selectedFileToDeleteFromServer=[];
  }, 50); 
}

// removeFileFromServer(files:any){
// this.uploadImageService.removeFileFromServer(files);
// }


fileChangeEvent(e: any) {
    this.filesToUpload = <Array<File>>e.target.files;

    const files=e.target.files;
    console.log(files);
    if(files){
      for(let i=0;i<files.length;i++){
        const image={
          name:'',
          type:'',
          size:'',
          url:''
        };
      //  this.allfiles.push(files[i]);
        image.name=files[i].name;
        image.type=files[i].type;

//to calculate the size of image
        const size=files[i].size / 1000;
        const mbc=size+'';
        const mb=mbc.split('.')[0];
        const length=mb.length;
        if(length===4|| length===5){
                  const mbsize=size/1000;
                  const splitdata=mbsize+'';
                  const splitvalues=splitdata.split('.');
                  let secondvariable='';
                  for(let j=0;j<splitvalues.length;j++){
                    if(j===1){
                      secondvariable=splitvalues[j].slice(0,2)
                    }
                  }
                  image.size=splitvalues[0]+'_'+ secondvariable+' MB'
        }else{
          const splitdata=size+'';
          const splitvalues=splitdata.split('.');
          let secondvariable='';
          for(let j=0;j<splitvalues.length;j++){
            if(j===1){
              secondvariable=splitvalues[j].slice(0,2);
            }
          }
          image.size=splitvalues[0]+'.'+secondvariable+' KB'
        }
//end to calculate teh size

        const reader=new FileReader();
        reader.onload=(filedata)=>{
          image.url=reader.result+'';
          this.images.push(image);
        };
        reader.readAsDataURL(files[i]);

      }
    }



}

removeFromList(index:number){
  this.dummyfilesToUpload=this.filesToUpload
  this.filesToUpload=[];
  for(let i=0;i<this.dummyfilesToUpload.length;i++){
    if(i!=index){
      this.filesToUpload.push(this.dummyfilesToUpload[i])
    }
  }
  this.images.splice(index,1);

}


removeFromAlreadyUploadedList(index:number){
  var dialog = Dialog.customdeleteDialog("Do you want to remove selected image from the list ?",this.dialogService);
  dialog.result.subscribe((result) => {
          if (result instanceof DialogCloseResult) {
          } 
          else {
                      if(!result.primary){
                                
                        this.dummyfilesToUpload=this.alreadyUploadedFile
                        this.alreadyUploadedFile=[];
                        for(let i=0;i<this.dummyfilesToUpload.length;i++){
                          if(i!=index){
                            this.alreadyUploadedFile.push(this.dummyfilesToUpload[i])
                          }
                          else{
                            this.selectedFileToDeleteFromServer.push(this.dummyfilesToUpload[i]);
                          }
                        }
                                
                      }
              }
      });  
          
          //this.filesToUpload=this.alreadyUploadedFile;
      // this.dummyfilesToUpload=[];
}

// fileUploadedOrNot(){
//   this.uploadImageService.fileUploadedOrNot(this.selectedRow.CONSULTANTID); 
// }


}




