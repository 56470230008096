import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { CommonConstants } from 'src/app/utility/common-constants';
import { Observable } from 'rxjs';

const httpOptions = {
  headers: new HttpHeaders({ 
    'Access-Control-Allow-Origin':'*',
    'Authorization':'authkey',
    'Access-Control-Allow-Methods': 'GET, PUT, POST, DELETE, HEAD',
  })
};
@Injectable({
  providedIn: 'root'
})

export class SubGroupEntityService {
  
  baseUrl=CommonConstants.baseUrl+'/'+CommonConstants.subGroupEntity;

  constructor(private http:HttpClient) { 
  }

  getSubGroupEntityDetails(subGroupEntity){
    
    return this.http.post(this.baseUrl,subGroupEntity,httpOptions);
  }

  createSubGroupEntity(subGroupEntity){
   debugger
    return this.http.post(this.baseUrl +'/'+CommonConstants.add,subGroupEntity,httpOptions);
  }

  updateSubGroupEntity(subGroupEntity){
    return this.http.post(this.baseUrl +'/'+CommonConstants.edit,subGroupEntity,httpOptions);
  }

  deleteSubGroupEntity(subGroupEntity){
   return this.http.post(this.baseUrl +'/'+CommonConstants.delete,subGroupEntity,httpOptions);
  }

  

  getMainGroupWithId(subGroupEntity){
    return this.http.post(this.baseUrl+"/getMainGroup",subGroupEntity,httpOptions);
  }

 
}
