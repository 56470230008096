export class ConfidentialDetails {
    CONSULTANTID:string;
    COMPANYCODE:number;
    SSNUMBER:string;
    PANNUMBER:string;
    AADHARNUMBER:string;
    SSRECEIVEDDT:Date;
    grpentityid:number;
    subgrpentityid:number;
    CREATEDUSERID=sessionStorage.getItem("logInuser"); 
    MODIFIEDUSERID=sessionStorage.getItem("logInuser");
    CREATEDDT:any;
    MODIFIEDDT:any;
    
    BIRTHDT:Date;
    REFERRALID:number;

}
