import { Component, Input, OnInit } from '@angular/core';
import { PlacementRolloffHistory } from 'src/app/model/menu/dashboard/placement-rolloff-history.model';
import { printDiv } from '../print-div';
@Component({
  selector: 'app-placement-rolloff-history-popup',
  templateUrl: './placement-rolloff-history-popup.component.html',
  styleUrls: ['./placement-rolloff-history-popup.component.css']
})
export class PlacementRolloffHistoryPopupComponent implements OnInit {

  public active:boolean=false;
  constructor() { }

  //@Input() inactiveEmployee:any[]=[];
  //@Input() hiredEmployee:any[]=[];
  @Input() employeeList:any[]=[];
  @Input() title:any;
  
  ngOnInit() {
  }

  @Input() public set model(obj:PlacementRolloffHistory){
     if(obj !== undefined){
       this.active=true;
      }
  }

  closeForm(){
    this.active=false;
  }
  printData(id)
  {
debugger;
    printDiv(id,this.title);
  }

}
