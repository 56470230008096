import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ViewContainerRef } from '@angular/core';
import { CommonService } from 'src/app/service/common/common.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { EmployeeDetailsService } from 'src/app/service/menu/hr/hr-employee/employee-details.service';
import { Employee } from 'src/app/model/Employee.model';
import { Country } from 'src/app/model/menu/masters/country.model';
import { SubGroupEntity } from 'src/app/model/menu/masters/sub-group-entity.model';
import { ImmigrationDetailsService } from 'src/app/service/menu/hr/hr-employee/immigration-details.service';
import { Router } from '@angular/router';
import { ReferralDetails } from 'src/app/model/menu/masters/referral-details.model';
import { EmployeeService } from 'src/app/service/employee.service';
import { ContactDetailsService } from 'src/app/service/menu/hr/hr-employee/contact-details.service';
import { EmpAddressDetailsService } from 'src/app/service/menu/hr/hr-employee/emp-address-details.service';
import { EmpAddressDetails } from 'src/app/model/menu/hr/hr-employee/emp-address-details.model';
import { PassportDetails } from 'src/app/model/menu/hr/hr-employee/passport-details.model';
import { PassportDetailsService } from 'src/app/service/menu/hr/hr-employee/passport-details.service';
import { ClientAddress } from 'src/app/model/menu/masters/clientAddress.model';
import { LicenseDetailsService } from 'src/app/service/menu/hr/hr-employee/license-details.service';
import { LicenseDetails } from 'src/app/model/menu/hr/hr-employee/license-details.model';
import { ConfidentialDetails } from 'src/app/model/menu/hr/hr-employee/confidential-details.model';
import { CandidateDetailsService } from 'src/app/service/menu/hr/hr-employee/candidate-details.service';
import { ConfidentialDetailsService } from 'src/app/service/menu/hr/hr-employee/confidential-details.service';
import { NotificationService } from '@progress/kendo-angular-notification';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-employee-view-details',
  templateUrl: './employee-view-details.component.html',
  styleUrls: ['./employee-view-details.component.css']
})
export class EmployeeViewDetailsComponent implements OnInit {

  @ViewChild('appendTo', { read: ViewContainerRef }) public appendTo: ViewContainerRef;

  countryArray:any=[];
  licenceState:any=[];
 // driverState:any=[];
  paddressState:any=[];
  presentaddressState:any=[];
  kaavianYear:Array<any>=[];
  immegrationStstus:any=[];

  loadReferralName:any[];
  referralSearchList:any=[];
  addRefferalImage:string="../assets/plussign.jpg";
  emp:Employee;
  year:any;

  dobflag:boolean;
  saveFlag:boolean=true;
  //serialNo:any;
  public country=new SubGroupEntity();

  loadedata:any[]=[];

  private empCurrentAddressDetails=new EmpAddressDetails;
   private empPermanentAddressDetails=new EmpAddressDetails;
   private passportDetails=new PassportDetails;
   licenseDetailsModel:LicenseDetails=new LicenseDetails();
   confidentialDetail:ConfidentialDetails=new ConfidentialDetails();

   confidentialDetails:ConfidentialDetails=new ConfidentialDetails();



  constructor( private commonService:CommonService,
    private employeeDetailsService:EmployeeDetailsService,
    private employeeDetailService:EmployeeDetailsService,
  /**** */
    private employeeService:EmployeeService,
    private contactDetailsService:ContactDetailsService,
    private empAddressDetailsService:EmpAddressDetailsService,
    private passportDetailsService:PassportDetailsService,
    private licenseDetailsService:LicenseDetailsService,
    private candidateDetailsService:CandidateDetailsService,
    private confidentialDetailsService:ConfidentialDetailsService,
    private notificationService:NotificationService,
    private datePipe :DatePipe,
    
   /**** */
    private router:Router,
    ) { }

  ngOnInit() {

    this.commonService.getCountry().subscribe((countryList)=>{
      this.countryArray=countryList;
    });

    this.year=(new Date()).getFullYear();
    for(let i=2010;i<=(this.year+3);i++){
      this.kaavianYear.push(i);
    }
}

initializeModels(){
  // this.assignEmployeeValue();
  // this.assignEmployeeGeneralDetails();
  // this.assignEmployeeContactDetails();
  this.assignLicenceDetails();
   this. assignPassportDetails();
   this.assignCurrentAddress();
   this.assignPermanentAddress();
 
 }

  private editForm:FormGroup=new FormGroup({
    'CONSULTANTID':new FormControl(),
    'CONSULTANTTYPE':new FormControl(),
    'COMPANYCODE':new FormControl(),
    'grpentityid':new FormControl(),
    'subgrpentityid':new FormControl(),
    'CREATEDUSERID':new FormControl(),
    'MODIFIEDUSERID':new FormControl(),

    
        'FIRSTNAME':new FormControl('',Validators.required),
        'LASTNAME':new FormControl(),
        'BIRTHDT':new FormControl(),
        'GENDER':new FormControl(),
        'MARITALSTATUS':new FormControl(),
        'REFERRALID':new FormControl(),
        'KaavianTag':new FormControl(),
        'yearslot':new FormControl(),



        'MOBILENUMBER':new FormControl(),
        'HOUSEPHONENUMBER':new FormControl(),
        'INDIACONTACTNO':new FormControl(),
        'EMAILID1':new FormControl(),
        'LICENSENO':new FormControl(),
        'LICENSEEXPIRYDT':new FormControl(),
        'COUNTRYID':new FormControl(),
        'STATEID':new FormControl(),
        'CITY':new FormControl(),


        'passportnumber':new FormControl(),
        'issueddate':new FormControl(),
        'expdate':new FormControl(),
        'country':new FormControl(),
        'IMIGRATIONID':new FormControl(),

        'currentaddress1':new FormControl(),
        'currentaddress2':new FormControl(),
        'currentcity':new FormControl(),
        'currentzipcode':new FormControl(),
        'currentcountry':new FormControl(),
        'currentstate':new FormControl(),

        'presentaddress1':new FormControl(),
        'presentaddress2':new FormControl(),
        'presentcity':new FormControl(),
        'presentzipcode':new FormControl(),
        'presentcountry':new FormControl(),
        'presentstate':new FormControl(),



    'EMPLOYEMENTSTATUSID':new FormControl(),
    'EMPLOYEMENTSTATUSDT':new FormControl(),
    'DESIGNATIONID':new FormControl(),
    'DESIGNATIONDT':new FormControl(),
    'PROJECTSTATUSID':new FormControl(),
});



  public active = false;
  newCandidate:any;
  //@Input() public isView = false;
  @Output() cancel: EventEmitter<any> = new EventEmitter();
  @Input() public editDataviewItem;
  @Input() public callthrough;
  @Input() public set model(emp:any) {
    debugger
//if user open this screen through hr then DOB is disable mode but if open through recruitment then DOB is enabled.
    if(this.callthrough==='hr'){
      this.dobflag=true;
    }else if(this.callthrough==='recruitment'){
      this.dobflag=false;
    }
         this.active = emp !== undefined;
         this.newCandidate=this.editDataviewItem;
         this.employeeDetailsService.loadImmigrationClassification(this.newCandidate).subscribe((data:any)=>{
          this.immegrationStstus=data;
        });

        this.employeeDetailService.loadAllReferralList(this.newCandidate).subscribe((data:any)=>{
          this.loadReferralName=data;
          this.referralSearchList= this.loadReferralName.slice();
        });

         //Load Employee Details
         this.editForm.reset();
          this.emp=this.newCandidate;
          this.emp.CONSULTANTID=this.newCandidate.consultantid;
          this.saveFlag=true;
          this.employeeDetailsService.loadEmployeeDetails(this.newCandidate).subscribe((data:any[])=>{
          if(data.length>0){
            this.loadedata=data;
            //record availabel means going to edit and button came update instead of save
            this.saveFlag=false;
           
                                 
            this.country.grpentityid=data[0].grpentityid;
            this.country.subgrpentityid=data[0].subgrpentityid;

            this.editForm.controls['grpentityid'].setValue(data[0].grpentityid) 
            this.editForm.controls['subgrpentityid'].setValue(data[0].subgrpentityid) 
            this.editForm.controls['CONSULTANTID'].setValue(data[0].CONSULTANTID)
            this.editForm.controls['COMPANYCODE'].setValue(data[0].subgrpentityid)

            this.editForm.controls['MODIFIEDUSERID'].setValue(sessionStorage.getItem("logInuser"))
            this.editForm.controls['CREATEDUSERID'].setValue(sessionStorage.getItem("logInuser"))




            this.editForm.controls['FIRSTNAME'].setValue(data[0].FIRSTNAME)
            this.editForm.controls['LASTNAME'].setValue(data[0].LASTNAME)

            if(data[0].BIRTHDT!=null){
                var splitdt=(""+data[0].BIRTHDT).split('-');
                var dt;
                if(splitdt.length==3 && (""+data[0].BIRTHDT).length==10){
                  dt=this.datePipe.transform((data[0].BIRTHDT),'MM/dd/yyyy')
                  this.editForm.controls['BIRTHDT'].setValue(new Date(dt))
                }else{
                  dt=this.datePipe.transform((this.commonService.decryptData(data[0].BIRTHDT)),'MM/dd/yyyy')
                  this.editForm.controls['BIRTHDT'].setValue(new Date(dt)); 
                }

            }else{
              this.editForm.controls['BIRTHDT'].setValue(undefined);
            }
            
            

            this.editForm.controls['GENDER'].setValue(data[0].GENDER)
            this.editForm.controls['MARITALSTATUS'].setValue(data[0].MARITALSTATUS)
            this.editForm.controls['REFERRALID'].setValue({'REFERRALID':data[0].REFERRALID})
            this.editForm.controls['KaavianTag'].setValue(data[0].KaavianTag)
            this.editForm.controls['yearslot'].setValue(data[0].yearslot)

            this.editForm.controls['MOBILENUMBER'].setValue(data[0].MOBILENUMBER)
            this.editForm.controls['HOUSEPHONENUMBER'].setValue(data[0].HOUSEPHONENUMBER)
            this.editForm.controls['INDIACONTACTNO'].setValue(data[0].INDIACONTACTNO)
            this.editForm.controls['EMAILID1'].setValue(data[0].EMAILID1)

            this.editForm.controls['LICENSENO'].setValue(data[0].LICENSENO)
            if(data[0].LICENSEEXPIRYDT!=null){
              this.editForm.controls['LICENSEEXPIRYDT'].setValue(new Date(data[0].LICENSEEXPIRYDT))
            }
            
            this.editForm.controls['COUNTRYID'].setValue(data[0].COUNTRYID)
            debugger
            
            this.country.COUNTRYCODE=data[0].COUNTRYID;
            this.commonService.getStateByCountryCodeNew(this.country).subscribe((state:any[])=>{
              this.licenceState=state
              this.editForm.controls['STATEID'].setValue(data[0].STATEID)
            })
            
            this.editForm.controls['CITY'].setValue(data[0].CITY)

            this.editForm.controls['passportnumber'].setValue(data[0].passportnumber)
            if(data[0].issueddate!=null){
              this.editForm.controls['issueddate'].setValue(new Date(data[0].issueddate))
            }
            if(data[0].expdate!=null){
              this.editForm.controls['expdate'].setValue(new Date(data[0].expdate))
            }
            
            this.editForm.controls['country'].setValue(data[0].country)
            this.editForm.controls['IMIGRATIONID'].setValue(data[0].IMIGRATIONID);



            this.editForm.controls['currentaddress1'].setValue(data[0].currentaddress1)
            this.editForm.controls['currentaddress2'].setValue(data[0].currentaddress2)
            this.editForm.controls['currentcity'].setValue(data[0].currentcity)
            this.editForm.controls['currentzipcode'].setValue(data[0].currentzipcode)
            this.editForm.controls['currentcountry'].setValue(data[0].currentcountry)

            this.country.COUNTRYCODE=data[0].currentcountry;
            this.commonService.getStateByCountryCodeNew(this.country).subscribe((state:any[])=>{
              this.paddressState=state
              this.editForm.controls['currentstate'].setValue(data[0].currentstate)
            })
            //this.editForm.controls['currentstate'].setValue(data[0].currentstate)

            this.editForm.controls['presentaddress1'].setValue(data[0].presentaddress1)
            this.editForm.controls['presentaddress2'].setValue(data[0].presentaddress2)
            this.editForm.controls['presentcity'].setValue(data[0].presentcity)
            this.editForm.controls['presentzipcode'].setValue(data[0].presentzipcode)
            this.editForm.controls['presentcountry'].setValue(data[0].presentcountry)

            this.country.COUNTRYCODE=data[0].presentcountry;
            this.commonService.getStateByCountryCodeNew(this.country).subscribe((state:any[])=>{
              this.presentaddressState=state
              this.editForm.controls['presentstate'].setValue(data[0].presentstate)
            })
            //this.editForm.controls['presentstate'].setValue(data[0].presentstate)
          }else{
            //there is no consultant id it means this is candidate entry
            this.editForm.reset(this.newCandidate);
           }
          
    });


  }

  closeForm(){
    this.active = false;
    this.editForm.reset();
    this.cancel.emit();
  }

  onDriverCountrySelection(obj:any){
    this.editForm.controls['STATEID'].setValue(undefined);
      this.commonService.getStateByCountryName(obj).subscribe((state) =>{
      this.licenceState=state;
      //console.log(state);
      });
  }

  onPaddressCountrySelection(obj:any){
    this.editForm.controls['currentstate'].setValue(undefined);
      this.commonService.getStateByCountryName(obj).subscribe((state) =>{
      this.paddressState=state;
      });
  }

  onPreaddressCountrySelection(obj:any){
    this.editForm.controls['presentstate'].setValue(undefined);
    this.commonService.getStateByCountryName(obj).subscribe((state) =>{
      this.presentaddressState=state;
      });
  }


  filterHandler(value:any){
    this.referralSearchList = this.loadReferralName.filter((s) => s.referalname.toLowerCase().indexOf(value.toLowerCase()) !== -1);
  }
  
  referral:any;
  refrrelButtonClick(){
    this.referral=new ReferralDetails();
  }


  saveClick(){
    debugger
    this.initializeModels();
    
   //we check is there any record availabel of that employee if exist then update if not exist then insert
   //no need to check that in employee table record is available or not
   //these two tables only update is required because at the time of employee creation already inserted
   if(this.editForm.controls.BIRTHDT.value!=undefined){
    var strDob=this.datePipe.transform(new Date(this.editForm.get('BIRTHDT').value),'MM/dd/yyyy');
    if(strDob !=null && strDob!=undefined && strDob !=''){
      var encriptedDateOfBirth=this.commonService.encryptData(strDob);
      this.editForm.value.BIRTHDT=encriptedDateOfBirth;
    }
  }

    this.employeeDetailsService.loadEmployeeDetails(this.newCandidate).subscribe((data:any[])=>{
      if(data.length>0){
        this.candidateDetailsService.updateCandidateGeneralDetails(this.editForm.value).subscribe();
        this.candidateDetailsService.editCandidateDetails(this.editForm.value).subscribe();
      }else{

        this.employeeService.createEmployee(this.editForm.value).subscribe();
        this.employeeService.insertEmployeeGeneralDetails(this.editForm.value).subscribe();
      }
    })

    
    
    //this.employeeService.editEmployeeGeneralDetails(this.editForm.value).subscribe();
   
    //this is inserted in licence history table but current licence history is updated in mstempgeneraldetails
    //Licence detailes is insertion only not updatiom , no need to check
    if(this.editForm.value.LICENSENO!=null&&this.editForm.value.LICENSENO!=''){
      if(this.editForm.value.LICENSENO!=this.loadedata[0].LICENSENO||(new Date(this.editForm.value.LICENSEEXPIRYDT)).getTime()!=(new Date(this.loadedata[0].LICENSEEXPIRYDT)).getTime()||this.editForm.value.CITY!=this.loadedata[0].CITY||this.editForm.value.COUNTRYID!=this.loadedata[0].COUNTRYID||this.editForm.value.STATEID!=this.loadedata[0].STATEID){
              this.licenseDetailsService.getMaxSerialNo(this.licenseDetailsModel).subscribe((data:any[])=>{
                  if(data.length>0){
                    this.licenseDetailsModel.SERIALNO=(data[0].SERIALNO)+1;
                    this.licenseDetailsService.save(this.licenseDetailsModel).subscribe();
                  }
              })
      }
    }
    

    
    this.contactDetailsService.getEmpContactDetails(this.editForm.value).subscribe((data:any[])=>{
              if(data.length>0){
                this.contactDetailsService.updateEmpContactDetails(this.editForm.value).subscribe();
              }else{
                //check either user enter any information of contact or not
                  if(this.editForm.value.MOBILENUMBER!=null||this.editForm.value.HOUSEPHONENUMBER!=null||this.editForm.value.INDIACONTACTNO!=null||this.editForm.value.EMAILID1!=null){
                        this.contactDetailsService.saveEmpContactDetails(this.editForm.value).subscribe();
                  }
               }
    })
    
    //for current address
    this.empAddressDetailsService.getData(this.empCurrentAddressDetails).subscribe((data:any[])=>{
              var caddress=undefined;
              var paddress=undefined;
              for(let i=0;i<data.length;i++){
                if(data[i].ADRESSTYPEID=='C'){
                  caddress=data[i].ADRESSTYPEID;
                }
                if(data[i].ADRESSTYPEID=='P'){
                  paddress=data[i].ADRESSTYPEID;
                }
              }

            //For current address
            if(caddress=='C'){
              this.empAddressDetailsService.edit(this.empCurrentAddressDetails).subscribe();
            }else{
                  if(this.editForm.value.currentaddress1!=null||this.editForm.value.currentaddress2!=null||this.editForm.value.currentcity!=null||this.editForm.value.currentzipcode!=null||this.editForm.value.currentcountry!=null||this.editForm.value.currentstate!=null){
                    this.empAddressDetailsService.save(this.empCurrentAddressDetails).subscribe();
                  }
            }

                //for permanent Address
                    if(paddress=='P'){
                      this.empAddressDetailsService.edit(this.empPermanentAddressDetails).subscribe();
                    }else{
                              if(this.editForm.value.presentaddress1!=null||this.editForm.value.presentaddress2!=null|| this.editForm.value.presentcity!=null||this.editForm.value.presentzipcode!=null||this.editForm.value.presentcountry!=null||this.editForm.value.presentstate!=null){
                                    this.empAddressDetailsService.save(this.empPermanentAddressDetails).subscribe();
                              }
                    }
     })

    
    
    //for passprt

    this.passportDetailsService.loadSelfPassportDetails(this.passportDetails).subscribe((data:any[])=>{
          if(data.length>0){
                    this.passportDetailsService.getSerailNo(this.passportDetails).subscribe((data:any[])=>{    
                        if(data.length>0){
                          this.passportDetails.serialno=data[0].serialno;
                          this.passportDetailsService.updatePassportDetails(this.passportDetails).subscribe();
                        }
                    })
          }else{
                      if(this.passportDetails.passportnumber!=null||this.passportDetails.issueddate!=null||this.passportDetails.expdate!=null||this.passportDetails.country!=null){
                            this.passportDetails.serialno=1;  
                            this.passportDetailsService.savePassportDetails(this.passportDetails).subscribe();
                      }
                }
      })

      //for  refferal 
      if(this.editForm.value.REFERRALID!=undefined && this.editForm.value.REFERRALID!=null){
                this.confidentialDetail=this.editForm.value;
                this.confidentialDetail.REFERRALID=this.editForm.value.REFERRALID.REFERRALID;
                this.employeeDetailsService.isRefferalAvailable(this.editForm.value).subscribe((data:any[])=>{
                  if(data.length>0){
                    this.employeeDetailsService.updateRefferal(this.confidentialDetail).subscribe();
                  }else{
                    this.confidentialDetailsService.save(this.confidentialDetail).subscribe();
                  }
                })
      }

              this.notificationService.show({
                content: "Process completed successfully.",
                animation: { type: 'slide', duration: 800 },
                position: { horizontal: 'center', vertical: 'bottom' },
                type: {style: 'success', icon: true },
                //closable: true,
                appendTo: this.appendTo,
                hideAfter: 2000,
                height: 40,
                width: 500,
                cssClass: 'success-notification',
            });
    }



  public editDataItem: ReferralDetails;
  public isNew: boolean;
  public listItem:any[];
  public isEdit:any=false;
  public fName:any="";
  public lName:any="";
  public options:boolean;

refferalAddHandler(){
  this.editDataItem=null;
  this.listItem=[];
  this.editDataItem = new ReferralDetails();
  this.isNew = true;
  this.isEdit=false;
  this.fName="";
  this.lName="";
  this.options=true;//by default checked radio button
}


public saveHandler(type: ReferralDetails) {
  setTimeout(() => {  
    this.employeeDetailService.loadAllReferralList(this.newCandidate).subscribe((data:any)=>{
      this.loadReferralName=data;
      this.referralSearchList= this.loadReferralName.slice();
      this.editForm.controls['REFERRALID'].setValue({CONSULTANTID:type.CONSULTANTID,referalname:type.FIRSTNAME+" "+type.LASTNAME});
    });
    this.editDataItem = undefined;
   },100);  

}



refferalSelectionChange(data:any){
  this.editForm.value.REFERRALID=data.REFERRALID;
}



assignCurrentAddress(){
  // to save Current address details
  this.empCurrentAddressDetails.COMPANYCODE=this.editForm.value.COMPANYCODE
  this.empCurrentAddressDetails.CONSULTANTID=this.editForm.value.CONSULTANTID
  this.empCurrentAddressDetails.ADRESSTYPEID='C'
  this.empCurrentAddressDetails.ADDRESS1=this.editForm.value.currentaddress1
  this.empCurrentAddressDetails.ADDRESS2=this.editForm.value.currentaddress2
  this.empCurrentAddressDetails.CITY=this.editForm.value.currentcity
  this.empCurrentAddressDetails.COUNTRYCODE=this.editForm.value.currentcountry
  this.empCurrentAddressDetails.STATEID=this.editForm.value.currentstate
  this.empCurrentAddressDetails.ZIPCODE=this.editForm.value.currentzipcode
  this.empCurrentAddressDetails.grpentityid=this.editForm.value.grpentityid
  this.empCurrentAddressDetails.subgrpentityid=this.editForm.value.subgrpentityid
  this.empCurrentAddressDetails.CREATEDUSERID=this.editForm.value.CREATEDUSERID
  this.empCurrentAddressDetails.MODIFIEDUSERID=this.editForm.value.CREATEDUSERID;
}

assignPermanentAddress(){
  // to save Permanent address details
  this.empPermanentAddressDetails.COMPANYCODE=this.editForm.value.COMPANYCODE
  this.empPermanentAddressDetails.CONSULTANTID=this.editForm.value.CONSULTANTID
  this.empPermanentAddressDetails.ADRESSTYPEID='P'
  this.empPermanentAddressDetails.ADDRESS1=this.editForm.value.presentaddress1
  this.empPermanentAddressDetails.ADDRESS2=this.editForm.value.presentaddress2
  this.empPermanentAddressDetails.CITY=this.editForm.value.presentcity
  this.empPermanentAddressDetails.COUNTRYCODE=this.editForm.value.presentcountry
  this.empPermanentAddressDetails.STATEID=this.editForm.value.presentstate
  this.empPermanentAddressDetails.ZIPCODE=this.editForm.value.presentzipcode
  this.empPermanentAddressDetails.grpentityid=this.editForm.value.grpentityid
  this.empPermanentAddressDetails.subgrpentityid=this.editForm.value.subgrpentityid
  this.empPermanentAddressDetails.CREATEDUSERID=this.editForm.value.CREATEDUSERID
  this.empPermanentAddressDetails.MODIFIEDUSERID=this.editForm.value.MODIFIEDUSERID
}

 assignPassportDetails(){
   //to save Passport details
   this.passportDetails.COMPANYCODE=this.editForm.value.COMPANYCODE
   this.passportDetails.CONSULTANTID=this.editForm.value.CONSULTANTID
   this.passportDetails.DEPENDENTNO=0;//0 for self passport
   //this is assign at the saving time
   //this.passportDetails.serialno;
   this.passportDetails.passportnumber=this.editForm.value.passportnumber
   this.passportDetails.issueddate=this.editForm.value.issueddate
   this.passportDetails.expdate=this.editForm.value.expdate
   this.passportDetails.country=this.editForm.value.country
   this.passportDetails.CREATEDUSERID=this.editForm.value.CREATEDUSERID
   this.passportDetails.grpentityid=this.editForm.value.grpentityid
   this.passportDetails.subgrpentityid=this.editForm.value.subgrpentityid
   this.passportDetails.MODIFIEDUSERID=this.editForm.value.MODIFIEDUSERID;
 }

 assignLicenceDetails(){
  this.licenseDetailsModel.COMPANYCODE=this.editForm.value.COMPANYCODE
  this.licenseDetailsModel.CONSULTANTID=this.editForm.value.CONSULTANTID
  //this is assign at the saving time
  this.licenseDetailsModel.SERIALNO;
 this.licenseDetailsModel.LICENSENO=this.editForm.value.LICENSENO
  this.licenseDetailsModel.LICENSEEXPIRYDT=this.editForm.value.LICENSEEXPIRYDT
  this.licenseDetailsModel.CITY=this.editForm.value.CITY
  this.licenseDetailsModel.STATEID=this.editForm.value.STATEID
  this.licenseDetailsModel.COUNTRYID=this.editForm.value.COUNTRYID
  this.licenseDetailsModel.grpentityid=this.editForm.value.grpentityid
  this.licenseDetailsModel.subgrpentityid=this.editForm.value.subgrpentityid

}

 


}
