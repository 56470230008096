import { Injectable } from '@angular/core';
import { CommonConstants } from 'src/app/utility/common-constants';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { EmailArguments } from 'src/app/model/emailArguments.model';
import * as XLSX from "xlsx";
import * as CryptoJS from 'crypto-js';

const httpOptions = {
  headers: new HttpHeaders({ 
    'Access-Control-Allow-Origin':'*',
    'Authorization':'authkey',
    'Access-Control-Allow-Methods': 'GET, PUT, POST, DELETE, HEAD',
  })
};

const getFileName = (name: string) => {
  console.log(name);
  let timeSpan = new Date().toISOString();
  let sheetName = name;
  let fileName = `${sheetName}-${timeSpan}`;
  return {
    sheetName,
    fileName
  };
};

@Injectable({
  providedIn: 'root'
})
export class CommonService {

  public employeeObject:any;
 public static employeeId:string="SSIU1069";
public static monthList:any=[{text:'January',value:'01'},{text:'February',value:'02'},{text:'March',value:'03'},
                               {text:'April',value:'04'},{text:'May',value:'05'},{text:'June',value:'06'},
                               {text:'July',value:'07'},{text:'August',value:'08'},{text:'September',value:'09'},
                               {text:'October',value:'10'},{text:'November',value:'11'},{text:'December',value:'12'}];

public static genderList:any=[{text:"Male",value:"M"},{text:"Female",value:"F"}];
  public static yesOrNoList:any=[{text:'Yes',value:'Y'},{text:'No',value:'N'}];
  
  constructor(private http:HttpClient) { 
  }

  // ********** Encrypt and Decrypt Data
  encryptSecretKey:any='Tekplay@2019!'

  encryptData(data) {

    try {
      return CryptoJS.AES.encrypt(JSON.stringify(data), this.encryptSecretKey).toString();
    } catch (e) {
      console.log(e);
    }
  }

  decryptData(data) {

    try {
      const bytes = CryptoJS.AES.decrypt(data, this.encryptSecretKey);
      if (bytes.toString()) {
        return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
      }
      return data;
    } catch (e) {
      console.log(e);
    }
  }


  // ***************To send mail***************
  sendMail(mailobject:EmailArguments){
      return this.http.post(CommonConstants.baseUrl+'/sendmail',mailobject);
  }
  //End





// ***************To send mail Without Attachment***************
  sendMailWithoutAttachment(mailobject:EmailArguments){
      return this.http.post(CommonConstants.baseUrl+'/sendmailWithoutAttachment',mailobject);
     
  }
  //End

// ***************Set Logged in Subgroupid***************

setLoggedInSubgroupEntityId(obj:any):any{
        debugger;
        var subgroupEntityId=Number(sessionStorage.getItem("subgrpentityid"));
        var temp=[];
        if(obj.length>0){
           for(let i=0;i<obj.length;i++){
              if(subgroupEntityId==obj[i].subgrpentityid){
                  temp.push(obj[i]);
                  break;
              }
                
           }
        }
        return temp;
  }
//End
 
  getSubGroupEntityDetails(subGroupEntity){
    return this.http.post(CommonConstants.baseUrl+'/'+ CommonConstants.subGroupEntity,subGroupEntity,httpOptions);
  }
  
public getClientTypeList(obj:any):Observable<any>  {
    debugger;
    return this.http.post(CommonConstants.baseUrl+'/'+CommonConstants.clients+'/'+CommonConstants.clientTypeList,obj);
  }
  public getIndustryTypeListBySubgroupId(obj:any):Observable<any>  {
    return this.http.post(CommonConstants.baseUrl+'/'+CommonConstants.clients+'/industryTypeList',obj);
  }
  public getPaymentTermsListBySubgroupId(obj:any):Observable<any>  {
    return this.http.post(CommonConstants.baseUrl+'/'+CommonConstants.clients+'/paymentTermsList',obj);
 }
 public getInvoiceTypeListBySubgroupId(obj:any):Observable<any>  {
   return this.http.post(CommonConstants.baseUrl+'/'+CommonConstants.clients+'/invoiceTypeList',obj);
 }

 public getIndustryTypeList():Observable<any>  {
    return this.http.get(CommonConstants.baseUrl+'/'+CommonConstants.groupEntity+'/industryTypeList',httpOptions);
  }  
  public getContactNature():Observable<any>  {
    return this.http.get(CommonConstants.baseUrl+'/'+CommonConstants.groupEntity+'/contactNature',httpOptions);
  }
  public getPaymentTermsList():Observable<any>  {
        return this.http.get(CommonConstants.baseUrl+'/'+CommonConstants.groupEntity+'/paymentTermsList',httpOptions);
  }   
  public getInvoiceTypeList():Observable<any>  {
          return this.http.get(CommonConstants.baseUrl+'/'+CommonConstants.groupEntity+'/invoiceTypeList',httpOptions);
  }  
  
 
  

  getStateByCountryCode(countryCode){
       return this.http.post(CommonConstants.baseUrl+'/'+CommonConstants.groupEntity +'/allStateList',countryCode,httpOptions)
  }

getStateByCountryCodeNew(obj){
    return this.http.post(CommonConstants.baseUrl+'/'+CommonConstants.EmployeeAddressDetails +'/allStateList',obj)
  }


  public getStateByCountryName(country:any):Observable<any>  {
      return this.http.get(CommonConstants.baseUrl+'/'+CommonConstants.groupEntity+'/state'+ '/' + country.COUNTRYNAME,httpOptions);
  }
  public getCountry():Observable<any>  {
      return this.http.get(CommonConstants.baseUrl+'/'+CommonConstants.groupEntity+'/'+"getCountry",httpOptions);
  }
  public getStateList():Observable<any>  {
      return this.http.get(CommonConstants.baseUrl+'/'+CommonConstants.groupEntity+'/state',httpOptions);
  }  

 getAllEmployeeList(obj:any){
    return this.http.post(CommonConstants.baseUrl+'/'+CommonConstants.employeesWagesHistoryReport+'/getAllEmployeeList',obj);
  }


getEmployeeList(obj){
    
    return this.http.post(CommonConstants.baseUrl+'/'+CommonConstants.manualExpense+'/getEmployeeList',obj)

  } 

getActiveEmployeeWihoutSubcontractor(obj){
    
    return this.http.post(CommonConstants.baseUrl+'/'+CommonConstants.employeeUtilization+'/activeemployeelist',obj)

  } 
getAllEmployeeWihoutSubcontractor(obj){
    
    return this.http.post(CommonConstants.baseUrl+'/'+CommonConstants.employeeUtilization+'/employeelist',obj)

  } 


getReportingManagerList(obj){
    return this.http.post(CommonConstants.baseUrl+'/'+CommonConstants.reportingManagerReport+'/getmanagerlist',obj)
  }

getReportingManagerListByEmployeeName(obj){
    return this.http.post(CommonConstants.baseUrl+'/'+CommonConstants.reportingManagerReport+'/getmanagerlist/empname',obj)
  }

getUserList(obj){
    return this.http.post(CommonConstants.baseUrl+'/'+CommonConstants.hrRules+'/getUserList',obj)
  }
  
 public getEmployeeStatus(obj:any):Observable<any>  {
    return this.http.post(CommonConstants.baseUrl+'/'+CommonConstants.status+'/empstatus',obj);
  }   
  public getProjectStatus(obj:any):Observable<any>  {
    return this.http.post(CommonConstants.baseUrl+'/'+CommonConstants.status+'/projstatus',obj);
  }  
  public getInternalStatus(obj:any):Observable<any>  {
    return this.http.post(CommonConstants.baseUrl+'/'+CommonConstants.status+'/internalstatus',obj);
  }  

 public getConsultantRelation(obj:any):Observable<any>  {
    return this.http.post(CommonConstants.baseUrl+'/consultantrelation',obj);
  } 



  //Reports
  getEmployementStatus(obj){
    return this.http.post(CommonConstants.baseUrl+'/'+CommonConstants.licenseHistoryReport+'/'+'employementStatus',obj);

  }
  getEmployeeListByEmployementStatus(obj){
    
    return this.http.post(CommonConstants.baseUrl+'/'+CommonConstants.licenseHistoryReport+'/getEmployeeList',obj)

  }  

 loadQualificationDropdownByTypeId(obj:any){
    return this.http.post(CommonConstants.baseUrl+'/'+CommonConstants.qualificationDetailsReport+'/qualificationtypeid',obj);
  }
  
  loadDesignationDropdown(obj:any){
    return this.http.post(CommonConstants.baseUrl+'/'+CommonConstants.employeesWagesHistoryReport+'/designation',obj);
  }  
 
loadEmployeeTypeDropdown(obj:any){
    return this.http.post(CommonConstants.baseUrl+'/'+CommonConstants.employeeDependentDetailsReport+'/employeetype',obj);
  }
  loadImmigrationStatusDropdown(obj:any){
    return this.http.post(CommonConstants.baseUrl+'/'+CommonConstants.employeeDependentDetailsReport+'/immgstatus',obj);
  }
loadImmigClassificationDropdown(obj:any){
    return this.http.post(CommonConstants.baseUrl+'/'+CommonConstants.employeeImmgReport+'/immgclassification',obj);
  }
  loadImmigTypeDropdown(obj:any){
    return this.http.post(CommonConstants.baseUrl+'/'+CommonConstants.employeeImmgReport+'/immgtype',obj);
  }
  loadVisaStatusDropdown(obj:any){
    return this.http.post(CommonConstants.baseUrl+'/'+CommonConstants.employeeImmgReport+'/visastatus',obj);
  }
  loadVisaTaskDropdown(obj:any){
    return this.http.post(CommonConstants.baseUrl+'/'+CommonConstants.employeeImmgReport+'/visatask',obj);
  }
  loadLCATypeDropdown(obj:any){
    return this.http.post(CommonConstants.baseUrl+'/'+CommonConstants.lcaDetailWithProj+'/lcatype',obj);
  }
  loadClientListDropdown(obj:any){
    return this.http.post(CommonConstants.baseUrl+'/'+CommonConstants.employeeImmgProject+'/client',obj);
  }
  loadReferralNameListDropdown(obj:any){
    return this.http.post(CommonConstants.baseUrl+'/'+CommonConstants.employeeImmgProject+'/referral',obj);
  }
getActiveEmployee(obj:any){
    return this.http.post(CommonConstants.baseUrl+'/'+CommonConstants.emailForm+'/activeemployee',obj);
  }


  
//Get GroupEntityId From Session
strDbValue:string;
  isExist:boolean;
  grpEntityId:number=Number(sessionStorage.getItem("entityId"));
  public checkDuplicateEntryValue(strFormValue:string,formSubGroupEnityId:string,strArray:any[],subGroupEntityIdArray:any[],groupEntityIdArray:any[]):boolean{
    for(let i =0;i<strArray.length;i++){
          this.strDbValue=strArray[i];
          if(strFormValue.toUpperCase()== this.strDbValue.toUpperCase() && formSubGroupEnityId==subGroupEntityIdArray[i] && this.grpEntityId==groupEntityIdArray[i]){
            this.isExist=true;
            break;
          }else{
            this.isExist=false
          }
      }
      return this.isExist;
  }

public getSubgroupEntityShortName(obj:any):Observable<any>{
    return this.http.post(CommonConstants.baseUrl+'/subgroupEntityShortname',obj);
  } 
  public getMaxNumberConsultantIdByShortName(obj:any):Observable<any>{
    return this.http.post(CommonConstants.baseUrl+'/maxConsultantIdByShortname',obj);
  }

  getUserGroups(obj:any):Observable<any>{
    return this.http.post(CommonConstants.baseUrl+'/loadUsergroup',obj);
  }
  loadMenuGroup(obj:any):Observable<any>{
    return this.http.post(CommonConstants.baseUrl+'/menugroup',obj);
  }
loadRulesGroupBySubGroupId(obj:any){
    return this.http.post(CommonConstants.baseUrl+'/rulesGroup',obj); 
  }



getBase64ImageFromURL(url) {
    return new Promise((resolve, reject) => {
      var img = new Image();
      img.setAttribute("crossOrigin", "anonymous");
  
      img.onload = () => {
        var canvas = document.createElement("canvas");
        canvas.width = img.width;
        canvas.height = img.height;
  
        var ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0);
  
        var dataURL = canvas.toDataURL("image/png");
  
        resolve(dataURL);
      };
  
      img.onerror = error => {
        reject(error);
      };
  
      img.src = url;
    });
  }


//To enter fix value in kendo numeric text box this function is used
//arguments 1-Formname 2-controlname 3-maximumlength
setLengthKendoNumericTextbox(formname:any,controlname:string,maxlength:number,isDecimal:boolean){
          var enteredvalue=""+formname.get(controlname).value;
          if(isDecimal==false){
                if(enteredvalue.length>maxlength){
                    formname.controls[controlname].setValue(Number(enteredvalue.substring(0, enteredvalue.length - 1)))
                }
          }else{
                      if(enteredvalue.indexOf(".") !== -1){
                              if(enteredvalue.length>maxlength){
                                formname.controls[controlname].setValue(Number(enteredvalue.substring(0, enteredvalue.length - 1)))
                              }
                      }else{
                          if(enteredvalue.length>maxlength-3){
                                formname.controls[controlname].setValue(Number(enteredvalue.substring(0, enteredvalue.length - 1)))
                          }
                      }
          }
  
}

  





//To export entire table in excel
// exportToExcel(tableId: string, name?: string) {
//   let timeSpan = new Date().toISOString();
//   //let prefix = name || "ExportResult";
//   let fileName = `${name}-${timeSpan}`;
//   let targetTableElm = document.getElementById(tableId);
//   let wb = XLSX.utils.table_to_book(targetTableElm, <XLSX.Table2SheetOpts>{ sheet: "Sheet1" });
//   XLSX.writeFile(wb, `${fileName}.xlsx`);
// }


//To export array in excel
exportArrayToExcel(arr: any[], name?: string) {
  let { sheetName, fileName } = getFileName(name);
var wb = XLSX.utils.book_new();
  var ws = XLSX.utils.json_to_sheet(arr);
  XLSX.utils.book_append_sheet(wb, ws, sheetName);
  XLSX.writeFile(wb, `${fileName}.xlsx`);
}




exportArrayToExcelForSkills(prepend:any[],arr: any[], name?: string) {
  let { sheetName, fileName } = getFileName(name);
var wb = XLSX.utils.book_new();
debugger;
var empty={};
var newArray:any[]=[];
//var exporta = XLSX.utils.sheet_to_json(wb , {defval:""});
//XLSX.utils.book_append_sheet(wb, exporta, sheetName);

  
//var header = XLSX.utils.json_to_sheet(prepend);
var ws = XLSX.utils.json_to_sheet(newArray);


XLSX.utils.sheet_add_json(ws,
  
 prepend
  ,
  {
    skipHeader:true,
    }
  );



  XLSX.utils.sheet_add_json(ws,arr,
    {
origin: "A11"
    }

    

    );

    XLSX.utils.book_append_sheet(wb, ws, sheetName);


  
 //XLSX.utils.book_append_sheet(wb, ws, sheetName);
  XLSX.writeFile(wb, `${fileName}.xlsx`);
}








//*********** User form access related service */

getSubmenuAccordingToFormAccess(obj){
  return this.http.post(CommonConstants.baseUrl+'/'+'submenuFormAccessDetails',obj)
} 

getMenuAccordingToFormAccess(obj){
  return this.http.post(CommonConstants.baseUrl+'/'+'menuFormAccessDetails',obj)
} 

submenuCategoryDetails(obj){
  return this.http.post(CommonConstants.baseUrl+'/'+'submenuCategoryDetails',obj)
} 

childSubmenuFormAccessDetails(obj){
  return this.http.post(CommonConstants.baseUrl+'/'+'childSubmenuFormAccessDetails',obj)
}

distinctSubmenuFormAccessDetails(obj){
  return this.http.post(CommonConstants.baseUrl+'/'+'distinctSubmenuFormAccessDetails',obj)
}









  getdateinmmddyyyy(dt) {
    if (dt != undefined) {
      dt = new Date(dt);
      var month;
      var date
      if ((Number(dt.getMonth()) + 1) < 10) {
        month = '0' + (Number(dt.getMonth()) + 1);
      } else {
        month = (Number(dt.getMonth()) + 1)
      }
      if (dt.getDate() < 10) {
        date = '0' + dt.getDate();
      } else {
        date = dt.getDate();
      }
      return month + '/' + date + '/' + dt.getFullYear();
    } else {
      return '';
    }
  }

}
 