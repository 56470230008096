import { Injectable } from '@angular/core';
import { CommonConstants } from 'src/app/utility/common-constants';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class PlacementThisMonthService {

  baseUrl: string = CommonConstants.baseUrl+'/dashboard';
    
    constructor(public http:HttpClient) { }
   
    PlacementThisMonth(obj){
      return this.http.post(this.baseUrl+'/placementThisMonth',obj);
    }
    loadEmployee(obj){
      return this.http.post(this.baseUrl+'/placementThisMonth/loadEmployee',obj);
    }

    
}