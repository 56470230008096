import { Component, OnInit } from "@angular/core";
import { AttritionRate } from "src/app/model/menu/dashboard/attrition-rate.model";
import { Employee } from "src/app/model/menu/dashboard/employee.model";
import { EmployeeService } from "src/app/service/menu/dashboard/employee.service";

@Component({
  selector: 'app-employee',
  templateUrl: './employee.component.html',
  styleUrls: ['./employee.component.css']
})
export class EmployeeComponent implements OnInit {

  employeeModel:Employee=new Employee();
  title:string='';
  concatLabel:string='';
  totalEmployee:number;
  barChartTitle:string;
  barChartLabels:string[]=["Citizen","Green Card","EAD","H1B","OPT","H4 EAD"];
  barChartData = new Array<number>(6);
  empCount:any[]=[];
  clickedOn:string='';
  attritionRate:AttritionRate;
  employeeList:any[]=[];

  constructor(private employeeService:EmployeeService) { 
    this.employeeModel.groupid=1;

    this.employeeService.employeecount(this.employeeModel).subscribe((data:any[])=>{
      if(data.length>0){
        this.totalEmployee=0;

        for(let i=0;i<data.length;i++){
          if(data[i].empCount==null){
            data[i].empCount=0;
          }

          if(data[i].immigrationid==2){ // US Citizen
            this.barChartData[0]=data[i].empCount
            this.totalEmployee=this.totalEmployee+Number(data[i].empCount);
          }else if(data[i].immigrationid==16){  //Green card
            this.barChartData[1]=data[i].empCount
            this.totalEmployee=this.totalEmployee+Number(data[i].empCount);
          }else if(data[i].immigrationid==6){ //EAD
            this.barChartData[2]=data[i].empCount
            this.totalEmployee=this.totalEmployee+Number(data[i].empCount);
          }else if(data[i].immigrationid==1){ //H1B
            this.barChartData[3]=data[i].empCount
            this.totalEmployee=this.totalEmployee+Number(data[i].empCount);
          }else if(data[i].immigrationid==7){ //OPT
            this.barChartData[4]=data[i].empCount
            this.totalEmployee=this.totalEmployee+Number(data[i].empCount);
          }else if(data[i].immigrationid==18){  //H4 EAD
            this.barChartData[5]=data[i].empCount
            this.totalEmployee=this.totalEmployee+Number(data[i].empCount);
          }
        }
        
        this.barChartTitle="Employee - "+this.totalEmployee;
      }          
    })  
  }

  ngOnInit() {}

  public barChartType:string = 'bar';
  public barChartColors: Array < any > = [{
    backgroundColor: ['#7FFA9B', '#ACFCE7','#c397fc','#EBD2B4','#F4989C','#FADD7F'],
    borderColor: ['white', 'white', 'white', 'white', 'white', 'white']
  }];

  public barChartOptions:any = {
    layout: {
      padding: {
          left: 5,
          right: 5,
          top: 20,
          bottom: 0
      }
    },
    legend: {
      display: false,
      position: 'right',
      labels: {
        fontSize: 10,
        boxWidth:10,
      }
     },
    scales: {
      xAxes: [{
        ticks: {
          fontSize: 10,
          fontStyle: "bold"
        }
      }],
      yAxes: [{
        ticks: {
          steps : 20,
          stepValue : 20,
          min: 0
        }
      }]
    },
    scaleShowVerticalLines: false,
    responsive: true,
    scaleShowValues: true,
    scaleValuePaddingX: 10,
    scaleValuePaddingY: 10,
    animation: {
      onComplete: function () {
        var chartInstance = this.chart,
        ctx = chartInstance.ctx;
        ctx.textAlign = 'center';
        ctx.textBaseline = 'bottom';
        this.data.datasets.forEach(function (dataset, i) {
          var meta = chartInstance.controller.getDatasetMeta(i);
          meta.data.forEach(function (bar, index) {
              var data = dataset.data[index];
              ctx.fillText(data, bar._model.x, bar._model.y - 5);
          });
        });
      }
    }
  }

  public chartClicked(e: any): void {
    if (e.active.length > 0) {
      const chart = e.active[0]._chart;
      const activePoints = chart.getElementAtEvent(e.event);

      if ( activePoints.length > 0) {
        // get the internal index of slice in pie chart
        const clickedElementIndex = activePoints[0]._index;
        const label = chart.data.labels[clickedElementIndex];
        // get value by index
        const value = chart.data.datasets[0].data[clickedElementIndex];

        this.title='List Of Employees -'+value;

        this.employeeList=[];

        this.employeeModel.groupid=1;
        if(label==this.barChartLabels[0]){
          this.employeeModel.immigrationid=2;
        }
        if(label==this.barChartLabels[1]){
          this.employeeModel.immigrationid=16;
        }
        if(label==this.barChartLabels[2]){
          this.employeeModel.immigrationid=6;
        }
        if(label==this.barChartLabels[3]){
          this.employeeModel.immigrationid=1;
        }
        if(label==this.barChartLabels[4]){
          this.employeeModel.immigrationid=7;
        }
        if(label==this.barChartLabels[5]){
          this.employeeModel.immigrationid=18;
        }

        this.employeeService.employeelist(this.employeeModel).subscribe((data:any[])=>{
          this.employeeList=data;
          if(data.length>0){
            this.attritionRate=new AttritionRate();
          }
        })
      }
    }
  }

}
