import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ReferralDetails } from 'src/app/model/menu/masters/referral-details.model';
import { CommonConstants } from 'src/app/utility/common-constants';


const httpOptions = {
  headers: new HttpHeaders({ 
    'Access-Control-Allow-Origin':'*',
    'Authorization':'authkey',
    //'userid':'1',
    'Access-Control-Allow-Methods': 'GET, PUT, POST, DELETE, HEAD',
  })
};

@Injectable({
  providedIn: 'root'
})
export class ReferralDetailsService {

  //uri = 'http://localhost:3000';
  strUrl:string=CommonConstants.baseUrl+'/'+CommonConstants.referralDetails;
  constructor(private http:HttpClient) { 

  }
  // loadCompany(){
  //   return this.http.get(CommonConstants.baseUrl+'/company');
  // }
  loadEmployeeName(refDetails:ReferralDetails){
    
    return this.http.post(CommonConstants.baseUrl+'/employee/'+refDetails.subgrpentityid,refDetails);
  }
  loadEmployeeEdit(refDetails:ReferralDetails){
    debugger;
    return this.http.post(CommonConstants.baseUrl+'/employee',refDetails);
  }
  getReferralDetailsList(refDetails:ReferralDetails){
    return this.http.post(this.strUrl,refDetails);
  }
  createReferral(refDetails:ReferralDetails) {
    return this.http.post(this.strUrl+'/'+CommonConstants.add, refDetails);
  }

  getreferralid(refDetails:ReferralDetails) {
    return this.http.post(this.strUrl+'/getreferralid', refDetails);
  }
    
  updateReferral(refDetails:ReferralDetails) {
    debugger;
    return this.http.post(this.strUrl+'/'+CommonConstants.edit+ '/' + refDetails.REFERRALID, refDetails,httpOptions);
   }
   deleteReferralDetails(refDetails:ReferralDetails){
    return this.http.post(this.strUrl+'/'+CommonConstants.delete+'/'+refDetails.REFERRALID,refDetails);
  }
  getEmpNameFromMaster(refDetails:ReferralDetails){
    return this.http.get(CommonConstants.baseUrl+'/employee/'+refDetails.CONSULTANTID);
  }
}
