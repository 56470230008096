import { Component, Input, OnInit } from '@angular/core';
import { PlacementThisMonth } from 'src/app/model/menu/dashboard/placement-this-month.model';
import { printDiv } from '../print-div';
@Component({
  selector: 'app-placement-this-month-popup',
  templateUrl: './placement-this-month-popup.component.html',
  styleUrls: ['./placement-this-month-popup.component.css']
})
export class PlacementThisMonthPopupComponent implements OnInit {

  public active:boolean=false;
  constructor() { }

  //@Input() inactiveEmployee:any[]=[];
  //@Input() hiredEmployee:any[]=[];
  @Input() employeeList:any[]=[];
  @Input() title:any;
  
  ngOnInit() {
  }

  @Input() public set model(obj:PlacementThisMonth){
     if(obj !== undefined){
       this.active=true;
      }
  }

  closeForm(){
    this.active=false;
  }

  printData(id)
  {
debugger;
    printDiv(id,this.title);
  }

}
