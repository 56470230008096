import { Component, OnInit } from '@angular/core';
import {NgbModalOptions} from '@ng-bootstrap/ng-bootstrap';
import {NgbTypeaheadConfig} from '@ng-bootstrap/ng-bootstrap';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import {AdminService} from 'src/app/admin.service';
import { NgForm } from '@angular/forms';
import { CommonService } from '../service/common/common.service';
import { FormAccess } from '../model/formAccess.model';

@Component({
  selector: 'app-entity',
  templateUrl: './loginentity.component.html',
  styleUrls: ['./loginentity.component.css'] 
})
export class LoginEntityComponent implements OnInit {
  formAccess:FormAccess=new FormAccess();

  //logo:string="./assets/logo/"+this.formAccess.grpentityid+"/"+this.formAccess.subgrpentityid+"/"+this.formAccess.subgrpentityid+".png";
  logo:string="./assets/logo/1/1/1.png";
  model: any = {};
  entityValid:boolean=false;
  logoValid:boolean=false;
  //logo:any;
  mainGroupCompany:Array<any>;
  subGroupCompany:Array<any>;
  
/*  public loginForm: FormGroup = new FormGroup({
    //'grpentityid': new FormControl('', Validators.required),
    'subEntityName': new FormControl('',[Validators.required]),
    //'subgrpentityid': new FormControl('',[Validators.required])
   
   
    
  });
*/
  constructor(config: NgbTypeaheadConfig,public activeModal: NgbActiveModal,private adminService:AdminService) { }

  ngOnInit() {


  /*  this.adminService.getMainGroupCompany().subscribe((mainGroupCompany) =>{
      debugger;
      this.mainGroupCompany=mainGroupCompany;
  
    });
*/
   
 
  console.log(this.editForm.invalid);
  this.editForm.reset();
  console.log("HIIIII");
  }
  
  public editForm: FormGroup = new FormGroup({
    'entityName':new FormControl('',Validators.required),  
  });


/*  entityLogin() {
    
    let ngbModalOptions: NgbModalOptions = {
      backdrop : 'static',
      keyboard : false,
      size:'lg',
      windowClass: 'modal-xl'
};

   debugger;
    const modalRef = this.modalService.open(EntityComponent,ngbModalOptions);
    
    modalRef.result.then((result) => {
      console.log(result);
    }).catch((error) => {
      console.log(error);
    });
  }




public selectionChange(entity: any): void {

  debugger;
  //this.editAddressForm.reset({STATEID:0});
  if(entity.grpentityid !='0')
  {

    this.adminService.getSubGroupCompany(entity).subscribe( (data)=>
    this.subGroupCompany=data
  
  
  );
    this.logoValid=true;
    this.logo=entity.grpentityname;

  }
  else{
    this.entityValid=true;

  }


  

  
  };*/







  private submitForm() {
    

    // this.model.entityname.valid=false;
      this.adminService.getMainGroupName(this.editForm.get('entityName').value).subscribe( (data)=>{

            if(data !=undefined && data != null && data.length>0 )

            {
              
                  for (var item of data) {
                            debugger
                                      if(item.grpentityname.toLowerCase() == this.editForm.get('entityName').value.toLowerCase())
                                      {
                                        localStorage.setItem("entityId",data[0].grpentityid);
                                        sessionStorage.setItem("entityId",data[0].grpentityid);
                                        this.activeModal.close();
                                        this.entityValid=false;
                                      }
                                      else{
                                        this.entityValid=true;
                                        this.editForm.controls['entityName'].setValue(undefined);
                                      }
                  



                  }
         }else{
            this.entityValid=true;
            this.model.entityName= undefined;
         }
      
       }) ;

// this.activeModal.close(this.editForm.value);
  }
  strValue:any;
  onKey(){
   
    this.strValue=this.editForm.get('entityName').value;
    console.log(this.strValue);
    if( this.strValue=="" || this.strValue==null || this.strValue==undefined){
      this.entityValid=false;
    }
  }
}
