import { Injectable } from '@angular/core';
import { CommonConstants } from 'src/app/utility/common-constants';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class CandidateDetailsService {
  baseUrl:string=CommonConstants.baseUrl+'/hrcandidateDetails';
  constructor(private http:HttpClient) { }

  updateCandidateGeneralDetails(obj){
    return this.http.post(this.baseUrl+'/editgeneraldetails',obj);
  }

  editCandidateDetails(obj){
    return this.http.post(this.baseUrl+'/editCandidateDetails',obj);
  }

}