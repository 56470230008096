export class CommonConstants {
  // Production API access point
  public static url: string = 'https://ssiportal.smartsoftus.com:3000';
  public static portalUser = 'https://ssiportal.smartsoftus.com:3000/portalUsers';

  // Production
  public static baseUrl='https://ssiportal.smartsoftus.com:3000';
  public static clientbaseurl='https://ssiportal.smartsoftus.com';
  public static homeUrl='https://ssiportal.smartsoftus.com/home';
  public static loginUrl='https://ssiportal.smartsoftus.com/login';

  // Local
  // public static baseUrl = 'http://localhost:3000';
  // public static clientbaseurl = 'http://localhost:4200';
  // public static homeUrl = 'http://localhost:4200/home';
  // public static loginUrl = 'http://localhost:4200/login';
  // public static portalUser = 'http://localhost:3000/portalUsers';

  public static user = '/user';
  public static entity = '/entity';
  public static googleuser = '/googleUser';

  public static clients = "clients";
  public static clientsAddress = "clientAddress";
  public static clientTypeList = "clientTypeList";
  public static clientIndustryTypeList = "clientIndustryTypeList";
  public static paymentTermsList = "paymentTermsList";
  public static invoiceTypeList = "invoiceTypeList";

  public static newEmployee = '/newEmployee';
  public static employee = '/employee';
  public static mainEntity = '/mainEntity';
  public static employeeImage = '/employeeImage';

  //common for all 
  public static slash: string = "/";
  public static add: string = "add";
  public static edit: string = "edit";
  public static delete: string = "delete";
  //End
  public static country = 'country';
  public static state = 'state';
  public static vendorType: string = "vendortype";
  public static clientIndType: string = "clientIndtype";
  public static expensesType: string = "expensestype";
  public static immigrationClassification: string = "immigrationclassification";
  public static immigrationStatus: string = "immigrationstatus";
  public static immigrationTask: string = "immigrationtask";
  public static immigrationType: string = "immigrationtype";
  public static contactPerson: string = "contactperson";
  public static qualificationDetails: string = "qualificationdetails";
  public static qualificationType: string = "qualificationtype";
  public static referralDetails: string = "referral";
  public static vendors: string = "vendors";
  public static vendorsAddress: string = "vendorsaddress";
  public static clientType: string = "clientType";
  public static company: string = "company";
  public static employmentStatus: string = "employmentStatus";
  public static gCCommunication: string = "gCCommunication";
  public static holidays: string = "holidays";
  public static paymentTerms: string = "paymentTerms";
  public static projectStatus: string = "projectStatus";
  public static projectType: string = "projectType";
  public static skillSet: string = "skillSet";
  public static dependentRelation: string = "dependentRelation";
  public static jobTitle: string = "jobTitle";
  public static subGroupEntity: string = "subGroupEntity";
  public static groupEntity: string = "groupEntity";
  public static gcCategory: string = "gcCategory";
  public static openRequestType: string = "openRequestType";
  public static subCompany: string = "subCompany";
  public static internalStatus: string = "internalstatus";
  public static wagesHistoryLCA: string = "wagesHistoryLCA";
  public static exemptNonexempt: string = "exemptNonexempt";
  public static billingCycle: string = "billingCycle";
  public static projectMode: string = "projectMode";
  public static projectHours: string = "projectHours";
  public static currency: string = "currency";
  public static projectPerDiem: string = "projectPerDiem";
  public static projectRatePer: string = "projectRatePer";
  public static projectContactType: string = "projectContactType";
  public static timesheetProject: string = "timesheetProject";
  public static employmentStatusMapping: string = "employmentStatusMapping";
  public static groupStatus: string = "empgroupStaus";
  public static emailReportConfiguration: string = "emailReportConfiguration";
  public static projectPlacedby: string = "projectPlacedBy";

  //admin menu
  public static fieldMapping: string = "fieldMapping";
  public static mailAlert: string = "mailalert";
  public static configForPayroll: string = "configforpayroll";
  public static scheduleJob: string = "scheduleJob";
  public static formAccessDetails: string = "formAccessDetails";
  public static menuSequence: string = "menusequence";

  //For Hr Menu
  public static contactDetails: string = "contactdetails";
  public static addOrUpdate: string = "addorupdate";
  public static passportDetails: string = "passportdetails";
  public static vacationDetails: string = "vacationdetails";
  public static gcCommunication: string = "empgccommunication";
  public static immigrationDetails: string = "immigrationDetails";
  public static projectImmigration: string = "projectimmigration";
  public static status: string = "status";

  public static visaProduct: string = "visaProduct";

  //accounts
  public static standatdExpense: string = "standardExpense";
  public static projectPercentage: string = "projpercentage";
  public static manualExpense: string = "manualExpense";
  public static assignStandardFees: string = "assignStandardFees";
  public static openingBalance: string = "openingBalance";

  //Hr->Employee-> Licence Details
  public static EmployeeLicenceDetails: string = "employeeLicenceDetails";

  //Hr->Employee-> Dependent Details
  public static EmployeeDependentDetails: string = "employeeDependentDetails";

  //Hr->Employee->GcWages
  public static EmployeeGcWages: string = "employeeGcWages";
  //  Hr->Employee->PreviousEmployement
  public static EmployeePreviousEmployement: string = "employeePreviousEmployement"
  // Hr-> Employee->Confidential Details
  public static EmployeeConfidentialDetails: string = "employeeConfidentialDetails"
  //Hr->Employee->Reporting Manager
  public static EmployeeReportingManager: string = "employeeReportingManager";
  //Hr->Employee->Certification
  public static EmployeeCertification: string = "employeeCertification";
  //Hr->Employee->Qualification Details
  public static EmployeeQualificationDetails: string = "employeeQualificationDetails"
  //Hr->Employee->Skill Set Details
  public static EmployeeSkillSetDetails: string = "employeeSkillSetDetails";
  //Hr->Employee Address Details
  public static EmployeeAddressDetails: string = "employeeAddressDetails";
  //Hr->Employee Wages History
  public static EmpWagesHistory: string = "employeeWagesHistory";

  //Hr->Employee Employee Details
  public static EmployeeDetails: string = "employeeDetails";

  //ERM->openRequest
  public static ErmOpenRequest: string = "ermOpenRequest";
  public static ErmSearch: string = "ermSearch";

  //Marketing->Projects
  public static MarketingProjects: string = "marketingProjects";
  public static empVacationDetails: string = "empvacationdetails"
  public static marketingSkillSetDetails: string = "employeeSkillSetDetails";
  public static empSSNLookup: string = "empssnlookup";
  public static skillSetSearch: string = "skillsetsearch";
  public static thirdPartyConsultant: string = "thirdpartyconsultant";

  //Hr Home
  public static hrHome: string = "hrHome";
  public static hrHomeVacationDetails: string = "hrhomevacationdetails";
  public static impotantLinks: string = "importantlinks";
  public static impotantMessages: string = "impotantmessages";
  public static emailForm: string = "emailform";

  //hr rules
  public static hrRules: string = "hrRules";

  //MyTimeSheet
  public static pendingApproval: string = "pendingapproval"
  public static myTimesheetEnterTime: string = "myTimesheetEnterTime";
  public static myTeam: string = "myTeam";

  //My Vacation 
  public static applyVacation: string = "applyvacation"
  public static pendingApprovlas: string = "pendingapprovals"

  //MyProfile
  public static myProfile: string = "myProfile";


  //Reports
  public static licenseHistoryReport: string = "licenseHistoryReport";
  public static vendorAddressReport: string = "vendorAddressReport";
  public static clientAddressReport: string = "clientAddressReport";
  public static dependentVacationListReport: string = "dependentVacationListReport";
  public static activeProjectsReport: string = "activeProjectsReport";
  public static projectClientAddressReport: string = "projectClientAddressReport";
  public static projectHistoryReport: string = "projectHistoryReport";

  public static ClientVsIndustryReport: string = "ClientVsIndustryReport";

  public static projectEndingReport: string = "projectEndingReport";
  public static projectDetailsReport: string = "projectDetailsReport";

  public static employeesInProjectReport: string = "employeesInProjectReport";
  public static employeesNotInProjectReport: string = "employeesNotInProjectReport";
  public static projectRateReport: string = "projectRateReport";
  public static projectByClientTypeReport: string = "projectByClientTypeReport";
  public static placedBySummaryReport: string = "placedBySummaryReport";
  public static monthlyEmployeeRollOffReport: string = "monthlyEmployeeRollOffReport";
  public static monthlyEmployeePlacementReport: string = "monthlyEmployeePlacementReport";
  public static employeeProjectAddressReport: string = "employeeProjectAddressReport";
  public static projectDetailsLongTermReport: string = "projectDetailsLongTermReport";
  public static projectInformationReport: string = "projectInformationReport";
  public static pendingPOFollowUpProjDetails: string = "pendingPOFollowUpProjDetails";



  public static activeEmployee: string = "activeemployee"
  public static passportDetailsReport: string = "passportdetailsreport"
  public static empContactDetailsReport: string = "empcontactdetailsreport"
  public static qualificationDetailsReport: string = "qualificationdetailsreport"
  public static employeesAddressReport: string = "employeesaddressreport"
  public static reportingManagerReport: string = "reportingmanagerreport"
  public static employeesAddressHistoryReport: string = "employeesaddresshistoryreport"
  public static employementStatusHistoryReport: string = "employementstatushistoryreport"
  public static referralHistoryReport: string = "referralhistoryreport"
  public static employeesWagesHistoryReport: string = "employeeswageshistoryreport"

  public static gcCommunicationReport: string = "gccommunicationreport"
  public static empPercentageReport: string = "employeepercentagereport"
  public static recruitmentDetailReport: string = "recruitmentdetailreport"
  public static i94NumberDetailsReport: string = "i94numberdetailsreport"
  public static employeeReferralDetailsReport: string = "employeereferraldetailsreport"
  public static employeesVisaHistoryReport: string = "employeesvisahistoryreport"
  public static h1BDetailsReport: string = "h1bdetailsreport"
  public static eadDetailsReport: string = "eaddetailsreport"
  public static employeeDependentDetailsReport: string = "employeedependentdetailsreport"
  public static greenCardDetailsReport: string = "greencarddetailsreport"
  public static confidentialDetailsReport: string = "confidentialdetailsreport"
  public static previousEmploymentDetailsReport: string = "previousemploymentdetailsreport"
  public static recruitmentSummaryReport: string = "recruitmentsummaryreport"
  public static employeesInformationReport: string = "employeesinformationreport"
  public static employeeImmgReport: string = "employeesimmigreport"
  public static notorizedList: string = "notorizedlist"
  public static lcaDetailWithProj: string = "lcadetailwithproj"
  public static employeeImmgProject: string = "employeeimmgproject"
  public static customReport: string = "customreport"
  public static employeeUtilization: string = "employeeutilization"
  public static h1BTimesheetDetails: string = "h1btimesheetdetails"
  public static employeeWages: string = "employeewages"

  //***** Timesheet *****
  public static timesheetMonthlyReport: string = "timesheetmonthlyreport";

  //Recruitment
  public static recruitment: string = "recruitment";
}

