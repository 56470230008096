import { Component, Input, OnInit } from '@angular/core';
//import { PlacementThisMonth } from 'src/app/model/menu/dashboard/placement-this-month.model';
import { ReloadDialog } from 'src/app/model/menu/dashboard/reload-diaslog.model';
import { CommonConstants } from 'src/app/utility/common-constants';

@Component({
  selector: 'app-relogindialog',
  templateUrl: './relogindialog.component.html',
  styleUrls: ['./relogindialog.component.css']
})
export class RelogindialogComponent implements OnInit {
  public active:boolean=false;

  constructor() { }

  ngOnInit() {

  }

  @Input() public set model(obj:ReloadDialog){
      debugger;
    if(obj !== undefined){
      this.active=true;
     }
 }

 closeForm(){
   this.active=false;
 }


 relogin(){
  debugger;
  var loginemail=sessionStorage.getItem('loginemail')
                                 var grpentity=sessionStorage.getItem('entityId')
                                 localStorage.clear();
                                 sessionStorage.clear();
                                 sessionStorage.setItem("entityId",grpentity);
                                 sessionStorage.setItem("loginemail",loginemail);
                                                         
                                 window.location.href=`${CommonConstants.loginUrl}?relogin=Y`;
 }
}
