export class MyProject {
    CONSULTANTID:string=sessionStorage.getItem("logInuser");
    grpentityid:number=Number(sessionStorage.getItem("entityId"));
    subgrpentityid:number=1;
    companycode:number=Number(sessionStorage.getItem("entityId"));
    CREATEDUSERID=sessionStorage.getItem("logInuser"); 
    MODIFIEDUSERID=sessionStorage.getItem("logInuser");
    CREATEDDT:any;
    MODIFIEDDT:any;
}
