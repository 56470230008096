export class EmpAddressDetails {
    COMPANYCODE:number;
    CONSULTANTID:string;
    ADRESSTYPEID:string;
    ADDRESS1:string;
    ADDRESS2:string;
    CITY:string;
    COUNTRYCODE:string;
    STATEID:number;
    ZIPCODE:number;
    PHONE1:number;
    PHONE2:number;
    wefdt:Date;
    grpentityid:number;
    subgrpentityid:number;
    CREATEDUSERID=sessionStorage.getItem("logInuser"); 
    MODIFIEDUSERID=sessionStorage.getItem("logInuser");
    CREATEDDT:any;
    MODIFIEDDT:any;
}
