import { Component, Input, OnInit } from '@angular/core';
import { Candidate } from 'src/app/model/menu/dashboard/candidate.model';
import { printDiv } from '../print-div';
@Component({
  selector: 'app-candidate-popup',
  templateUrl: './candidate-popup.component.html',
  styleUrls: ['./candidate-popup.component.css']
})
export class CandidatePopupComponent implements OnInit {

  public active:boolean=false;
  constructor() { }

  //@Input() inactiveEmployee:any[]=[];
  //@Input() hiredEmployee:any[]=[];
  @Input() employeeList:any[]=[];
  @Input() title:any;
  
  ngOnInit() {
  }

  @Input() public set model(obj:Candidate){
     if(obj !== undefined){
       this.active=true;
      }
  }

  closeForm(){
    this.active=false;
  }

  printData(id)
  {
debugger;
    printDiv(id,this.title);
  }
}
