import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { GroupEntity } from 'src/app/model/menu/masters/group-entity.model';
import { GroupEntityService } from 'src/app/service/menu/masters/group-entity.service';
import { DialogService, DialogCloseResult } from '@progress/kendo-angular-dialog';
import { Dialog } from 'src/app/utility/dialog';
import { FormControl, FormGroup } from '@angular/forms';
import { MatPaginator, MatSort, MatTableDataSource } from '@angular/material';

@Component({
  selector: 'app-group-entity',
  templateUrl: './group-entity.component.html',
  styleUrls: ['./group-entity.component.css']
})
export class GroupEntityComponent implements OnInit {

  displayedColumns:string[]=["grpentityid","grpentityname","shortname","actions"];
  public company:GroupEntity[];
  viewData:MatTableDataSource<any>;
  public dataArray:any;
  public editDataItem:GroupEntity;
  public isNew:boolean;
 
  private groupEntityModel:GroupEntity=new GroupEntity();

  dialogwidth:number=400;
  searchkey:string;
  @ViewChild(MatSort) sort:MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
    public editForm: FormGroup = new FormGroup({
      'searchField':new FormControl(),
    });

  constructor(private groupEntityService:GroupEntityService,private dialogService: DialogService) { }

  ngOnInit() {
      this.groupEntityService.getGroupEntityDetails(this.groupEntityModel).subscribe((data:any[])=>{
        this.dataArray=[];
        this.dataArray=data;
        this.viewData=new MatTableDataSource(this.dataArray);
        this.viewData.sort=this.sort;
        this.viewData.paginator=this.paginator;
      });

  }
  onSearchClear(){
    this.searchkey='';
    this.applyFilter();
  }
 applyFilter() {
    this.viewData.filter = this.searchkey.trim().toLowerCase();
 }
  public addHandler() {
    this.editDataItem = new GroupEntity();
    this.dialogwidth=400;
    this.isNew = true;
  }

  public editHandler(obj:any) {
        debugger;
        this.editDataItem = obj;
        this.dialogwidth=1000;
        // this.groupEntityService.getStateByCountryCode(this.editDataItem).subscribe((data:any[])=>{
        //   this.stateList=data;
        // });
        this.isNew = false;
 
  }

public cancelHandler() {
    this.editDataItem = undefined;
}
public saveHandler() {
    setTimeout(() => { this.groupEntityService.getGroupEntityDetails(this.groupEntityModel).subscribe((data:any[])=>{
      this.dataArray=[];
      this.viewData=null;
      this.dataArray=data;
      this.viewData=new MatTableDataSource(this.dataArray);
      this.viewData.sort=this.sort;
      this.viewData.paginator=this.paginator;
    });},100);  
     this.editDataItem = undefined;
}

public removeHandler(obj:any) {
 
  var dialog = Dialog.deleteDialog(this.dialogService);
  
  dialog.result.subscribe((result) => {
          if (result instanceof DialogCloseResult) {
          } 
          else {
                if(!result.primary){
                       this.groupEntityService.deleteGroupEntity(obj).subscribe((response:any)=>{
                        setTimeout(() => { this.groupEntityService.getGroupEntityDetails(obj).subscribe((data:any[])=>{
                          this.dataArray=[];
                          this.viewData=null;
                          this.dataArray=data;
                          this.viewData=new MatTableDataSource(this.dataArray);
                          this.viewData.sort=this.sort;
                          this.viewData.paginator=this.paginator;
                         });},10);
                       });
  
                     
                }
          }
        
  });

}
}
