import { Component, OnInit } from '@angular/core';
import { VacationOutOfCountryService } from 'src/app/service/menu/dashboard/vacation-out-of-country.service';
import { VacationOutOfCountry } from 'src/app/model/menu/dashboard/vacation-out-of-country.model';

@Component({
  selector: 'app-vacation-out-of-country',
  templateUrl: './vacation-out-of-country.component.html',
  styleUrls: ['./vacation-out-of-country.component.css']
})

export class VacationOutOfCountryComponent implements OnInit {
  vacationOutOfCountry:VacationOutOfCountry=new VacationOutOfCountry();
vacationList:any[]=[];


  constructor(private vacationOutOfCountryService:VacationOutOfCountryService) { }

currentdate:Date;
  ngOnInit() {
        this.vacationOutOfCountryService.vacationOutOfCountry(this.vacationOutOfCountry).subscribe((data:any[])=>{
          this.currentdate=new Date();
          for(let i=0;i<data.length;i++){
              if(new Date(data[i].VACATIONSTARTDT)<=this.currentdate && new Date(data[i].ESTIMATEDENDDT)>=this.currentdate ){
                data[i].flag="0";
                this.vacationList.push(data[i]);
              }else if(new Date(data[i].VACATIONSTARTDT)<=this.currentdate &&  new Date(data[i].ESTIMATEDENDDT)<=this.currentdate ){
                data[i].flag="1";
                this.vacationList.push(data[i]);
              }
              if(new Date(data[i].VACATIONSTARTDT)>this.currentdate && new Date(data[i].ESTIMATEDENDDT)>this.currentdate ){
                data[i].flag="2";
                this.vacationList.push(data[i]);
              }
          }
          
        })
  }
}
