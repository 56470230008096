import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { CommonConstants } from 'src/app/utility/common-constants';
import { LicenseDetails } from 'src/app/model/menu/hr/hr-employee/license-details.model';

const httpOptions = {
  headers: new HttpHeaders({ 
    'Access-Control-Allow-Origin':'*',
    'Authorization':'authkey',
    'Access-Control-Allow-Methods': 'GET, PUT, POST, DELETE, HEAD',
  })
};
@Injectable({
  providedIn: 'root'
})
export class LicenseDetailsService {
  
  baseUrl:string=CommonConstants.baseUrl+'/'+CommonConstants.EmployeeLicenceDetails;
  constructor(private http:HttpClient) { 
  }

  getMaxSerialNo(obj){
    return this.http.post(this.baseUrl+'/'+'getMaxSerialNumber',obj);
  }

  getGridDetails(LicenseDetailsModel){
    return this.http.post(this.baseUrl,LicenseDetailsModel);
  }

  getCurrentLicenseDetails(LicenseDetailsModel){
    return this.http.post(this.baseUrl+'/getCurrentLicenseDetails',LicenseDetailsModel);
  }
  

  save(licenseDetailsModel:any){
    return this.http.post(this.baseUrl+'/'+CommonConstants.add,licenseDetailsModel);
    
  }

  updateEmployeeDetails(licenseDetailsModel:any){
    debugger
    console.log("hit the link");
    return this.http.post(this.baseUrl+'/updateEmployeeLicenceDetails',licenseDetailsModel);
  }
  // update(licenseDetailsModel:any){
  //     return this.http.post(this.baseUrl+'/'+CommonConstants.edit,licenseDetailsModel);
  // }
}
