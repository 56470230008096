import { Component, OnInit, Inject, Input, Output, EventEmitter, ViewChild, ViewContainerRef } from '@angular/core';
import { GroupEntityService } from 'src/app/service/menu/masters/group-entity.service';
import { GroupEntity } from 'src/app/model/menu/masters/group-entity.model';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { GroupEntityAddress } from 'src/app/model/menu/masters/group-entity-address.model';
import { DialogCloseResult, DialogService } from '@progress/kendo-angular-dialog';
import { GroupEntityAddressService } from 'src/app/service/menu/masters/group-entity-address.service';
import { Dialog } from 'src/app/utility/dialog';
import { CommonLabels } from 'src/app/utility/common-labels';
import { CommonError } from 'src/app/utility/common-error';
import { CommonService } from 'src/app/service/common/common.service';
import { NotificationService } from '@progress/kendo-angular-notification';

@Component({
  selector: 'app-group-entity-edit',
  templateUrl: './group-entity-edit.component.html',
  styleUrls: ['./group-entity-edit.component.css']
})
export class GroupEntityEditComponent implements OnInit {

  @ViewChild('appendTo', { read: ViewContainerRef }) public appendTo: ViewContainerRef;
  
  //GroupEntityEdit labels
    strGroupCompanyName=CommonLabels.common_groupCompanyName;
    strshortName=CommonLabels.common_shortName;
    strIndustryType=CommonLabels.common_industryType;
    strPaymentTerms=CommonLabels.common_paymentTerms;
    strInvoiceType=CommonLabels.common_invoiceType;
    strStatus=CommonLabels.common_status;
    strWebsite=CommonLabels.common_website;
    strTaxNumbe=CommonLabels.common_taxNumber;
  //End

  //Coomon Errors
  error_001=CommonError.error_001;
  //End
 
  public industryTypeItems: Array<GroupEntity> =[];
  public invoiceType: Array<any> =[];
  public paymentTerms: Array<any> =[];
  
  public editAddressDataItem: any;
  public status:Array<{text:String,value:String}>=
  [
    { text: "Active", value: 'A' },
    { text: "InActive", value: 'I' },
  
  ];  

 // public columns: any[] = [{field: "NATURENAME",title:"Cont. Nature"}, {field: "CONTACTPERSON",title:"Name"},{field:"ADDRESS1",title: "Address"}];

displayedColumns:string[]=["NATURENAME","CONTACTPERSON","ADDRESS1","actions"];
  
  groupEntityService:GroupEntityService;
  GroupEntityAddressService:GroupEntityAddressService;
  
  constructor(@Inject(GroupEntityService) editServiceFactory: any,
      private groupEntityAddressService:GroupEntityAddressService,
      private dialogService: DialogService,
      private commonService:CommonService,
      private notificationService:NotificationService,
      ) {
      this.groupEntityService = editServiceFactory;
   
  }
  public groupEntityAddress: any=[];
  ngOnInit() {
   
         this.commonService.getIndustryTypeList().subscribe((industryType) =>{
         this.industryTypeItems=industryType;
         });

        this.commonService.getPaymentTermsList().subscribe((paymentTerms) =>{
        this.paymentTerms=paymentTerms;
        });

        this.commonService.getInvoiceTypeList().subscribe((invoiceType)=>{
        this.invoiceType=invoiceType;
        });


  }

  public active = false;
  public groupEntityObject:any;
  public editForm: FormGroup = new FormGroup({
 
          'grpentityid': new FormControl(''),
          'grpentityname': new FormControl('',[Validators.required]),
          'shortname': new FormControl('', [Validators.required]),
          'INDUSTRYTYPEID': new FormControl('',[Validators.required]),
          'PAYMENTTERMSCODE': new FormControl('',[Validators.required]),
          'InvoiceTypecode': new FormControl('',[Validators.required]),
          'ACTIVEINACTIVEFLAG': new FormControl('',[Validators.required]),

          //'website': new FormControl('',[Validators.required]),
          //'taxnumber':new FormControl('',[Validators.required]),

          'website': new FormControl(''),
          'taxnumber':new FormControl(''),


          'STATUS':new FormControl(''),
          'COUNTRYCODE':new FormControl(''),
          'createuserid':new FormControl(),
          'createddt':new FormControl(),
          'modifieduserid':new FormControl(),
          'modifieddt':new FormControl(),
  });

  slno:any;

 

@Input() public isNew = false;
@Input() public isAddressNew :boolean= false;
@Input() dialogwidth;
@Input() public set model(groupEntity:GroupEntity) {
  if(groupEntity !== undefined)
  {
    this.groupEntityObject=groupEntity;
    this.groupEntityAddressService.getGroupEntityAddress(groupEntity).subscribe((data:GroupEntityAddress[])=>this.groupEntityAddress=data);
  }  
  this.editForm.reset(groupEntity);
  this.active = groupEntity !== undefined;
}
@Input() public state:any[];


@Output() cancel: EventEmitter<any> = new EventEmitter();
@Output() save: EventEmitter<GroupEntity> = new EventEmitter();


public addAddressHandler(clientObject) {
  var groupEntityAddress=new GroupEntityAddress();
  groupEntityAddress.grpentityid=clientObject.value.grpentityid;

  this.groupEntityAddressService.getMaxGroupEntityAddressSlno(groupEntityAddress.grpentityid).subscribe(data=>{this.slno=(data[0].SLNO+1)
   });

  this.editAddressDataItem = groupEntityAddress;
  this.isAddressNew = true;
   
}

public editAddressHandler(dataItem) {
  this.state=[]
  this.slno=dataItem.SLNO
  this.commonService.getStateByCountryCode(dataItem).subscribe((data:any[])=>{
    this.state=data;
  });
  this.editAddressDataItem = dataItem;
  this.isAddressNew = false;
 
}

public cancelAddressHandler() {
  this.editAddressDataItem = undefined;
}

public saveAddressHandler(grpEntityAddress: any) {
  setTimeout(() => { this.groupEntityAddressService.getGroupEntityAddress(this.groupEntityObject).subscribe((data:GroupEntityAddress[])=>{
    this.groupEntityAddress=data;

    this.notificationService.show({
      content: "Transaction completed successfully.",
      animation: { type: 'slide', duration: 800 },
      position: { horizontal: 'center', vertical: 'bottom' },
      type: {style: 'success', icon: true },
      //closable: true,
      appendTo: this.appendTo,
      hideAfter: 4000,
      height: 40,
      width: 400,
      cssClass: 'success-notification',
  });
  });},100); 

  this.editAddressDataItem = undefined;
}

public removeAddressHandler(dataItem) {
    var dialog = Dialog.deleteDialog(this.dialogService);
    dialog.result.subscribe((result) => {
          if (result instanceof DialogCloseResult) {
          } 
          else {
                if(!result.primary){
                       this.groupEntityAddressService.deleteGroupEntityAddress(dataItem).subscribe((response:any)=>{
                        setTimeout(() => { this.groupEntityAddressService.getGroupEntityAddress(dataItem).subscribe((data:GroupEntityAddress[])=>{
                          this.groupEntityAddress=[]
                          this.groupEntityAddress=data;

                          this.notificationService.show({
                            content: "Record deleted successfully.",
                            animation: { type: 'slide', duration: 800 },
                            position: { horizontal: 'center', vertical: 'bottom' },
                            type: {style: 'success', icon: true },
                            //closable: true,
                            appendTo: this.appendTo,
                            hideAfter: 4000,
                            height: 40,
                            width: 400,
                            cssClass: 'success-notification',
                        });



                          });},10);
                       });
                     
                       
                }
          }
        
  });
}



public onSave(e): void {
  e.preventDefault();
  debugger;
  
  this.save.emit(this.editForm.value);
  if(this.isNew){
                  this.groupEntityService.createGroupEntity(this.editForm.value);
   }
  else{
                  this.groupEntityService.updateGroupEntity(this.editForm.value);
   }
   this.active = true;
}


public onCancel(e): void {
  e.preventDefault();
  this.closeForm();
}

private closeForm(): void {
  this.active = false;
  this.cancel.emit();
}

}
