import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CommonConstants } from 'src/app/utility/common-constants';

@Injectable({
  providedIn: 'root'
})
export class SkillNatureTypeService {

  strUrl:string=CommonConstants.baseUrl+'/'+CommonConstants.skillSet;
  constructor(private http:HttpClient) { }

  loadSkillNatureTypeList(obj:any){
    return this.http.post(this.strUrl+'/skillNatureType',obj);
  }
  loadSkillNatureDropdown(obj:any){
    return this.http.post(this.strUrl+'/skillNatureType/skillNature',obj);
  }
  getMaxNatureTypeIdByNatureId(obj:any){
    return this.http.post(this.strUrl+'/skillNatureType/maxid',obj);
  }
  createSkillNatureType(obj:any){
    return this.http.post(this.strUrl+'/skillNatureType/'+CommonConstants.add,obj);
  }
  updateSkillNatureType(obj:any){
    return this.http.post(this.strUrl+'/skillNatureType/'+CommonConstants.edit,obj);
  }
  deleteSkillNatureType(obj:any){
    return this.http.post(this.strUrl+'/skillNatureType/'+CommonConstants.delete,obj);
  }
}
