import { Component, OnInit } from "@angular/core";
import { Candidate } from "src/app/model/menu/dashboard/candidate.model";
import { CandidateService } from "src/app/service/menu/dashboard/candidate.service";

@Component({
  selector: 'app-candidate',
  templateUrl: './candidate.component.html',
  styleUrls: ['./candidate.component.css']
})
export class CandidateComponent implements OnInit {

  candidateModel:Candidate=new Candidate();
  title:string='';
  //concatLabel:string='';

  barChartLabels:string[]=["Stamped","Not Stamped"];
  barChartData:number[]=[];

  stamped:any[]=[];
  notStamped:any[]=[];

  clickedOn:string='';

  constructor(private candidateService:CandidateService) { 
              this.candidateService.stamped(this.candidateModel).subscribe((data:any[])=>{
              this.barChartData.push(data[0].stamped)
              this.barChartData.push(data[0].notstamped)
            });
  }

  ngOnInit() {
  }

  public barChartColors: Array < any > = [{
    backgroundColor: ['#7FFA9B', '#F4989C'],
    borderColor: ['white', 'white']
 }];

  public barChartType:string = 'bar';
  public barChartOptions:any = {
    layout: {
      padding: {
          left: 5,
          right: 5,
          top: 20,
          bottom: 0
      }
  },
                                    
    legend: {
      display: false,
      position: 'right',
      labels: {
        fontSize: 10,
        boxWidth:10,
      }
     },
     scales: {
      xAxes: [{
          ticks: {
              fontSize: 11,
              fontStyle: "bold"
              
          }
        }],
        yAxes: [{
          ticks: {
             steps : 20,
             stepValue : 20,
             min: 0
           }
       }]
    },
    scaleShowVerticalLines: false,
    responsive: true,
    scaleShowValues: true,
    scaleValuePaddingX: 10,
    scaleValuePaddingY: 10,
    animation: {
              onComplete: function () {
                  var chartInstance = this.chart,
                  ctx = chartInstance.ctx;
                  ctx.textAlign = 'center';
                  ctx.textBaseline = 'bottom';
                  this.data.datasets.forEach(function (dataset, i) {
                      var meta = chartInstance.controller.getDatasetMeta(i);
                      meta.data.forEach(function (bar, index) {
                          var data = dataset.data[index];
                          ctx.fillText(data, bar._model.x, bar._model.y - 5);
                      });
                  });
              }
          }
}




candidate:Candidate;
employeeList:any[]=[];
public chartClicked(e: any): void {
  if (e.active.length > 0) {
  const chart = e.active[0]._chart;
  const activePoints = chart.getElementAtEvent(e.event);
    if ( activePoints.length > 0) {
      // get the internal index of slice in pie chart
      const clickedElementIndex = activePoints[0]._index;
      const label = chart.data.labels[clickedElementIndex];
      // get value by index
      const value = chart.data.datasets[0].data[clickedElementIndex];
      if(label==this.barChartLabels[0]){
              this.title=`List of Employees - ${this.barChartLabels[0]} - ${value}`;
              this.candidateModel.stamped='Y';
      }else{
            this.title=`List of Employees ${this.barChartLabels[1]} - ${value}`;
            this.candidateModel.stamped='N';
      }
      this.employeeList=[];
      this.candidateService.loadEmployee(this.candidateModel).subscribe((data:any[])=>{
        this.employeeList=data;  
        console.log(data) ;
      });
      this.candidate=new Candidate();
   }
  }
}



}
