import { Component, OnInit } from '@angular/core';
//import { Holidays } from 'src/app/model/menu/masters/holidays.model';

import { FormGroup, FormControl } from '@angular/forms';
import { HolidayService } from 'src/app/service/menu/dashboard/holiday.service';
import { HolidaysService } from 'src/app/service/menu/masters/holidays.service';
import { Holiday } from 'src/app/model/menu/dashboard/holiday.model';

@Component({
  selector: 'app-holiday-dashboard',
  templateUrl: './holiday-dashboard.component.html',
  styleUrls: ['./holiday-dashboard.component.css']
})
export class HolidayDashboardComponent implements OnInit {

  constructor( private holidayService:HolidayService,
    private masterHolidayService:HolidaysService) { }

  currentyear:any=(new Date()).getFullYear();
  
  displayedColumns:string[]=["DATEOFHOLIDAY","HOLIDAYDESC"]
  holiday:Holiday=new Holiday()
  holidayDetails:any[]=[];
  yearArray:Array<number>=[];

  yearCombo_ngModel=(new Date()).getFullYear();

  public editForm: FormGroup = new FormGroup({
      'FINANCIALYEAR':new FormControl(),
  });

  ngOnInit() {
        
        this.loaddata((new Date()).getFullYear());
   
        this.yearArray=this.masterHolidayService.loadYearComboBox();
        this.editForm.controls['FINANCIALYEAR'].setValue(""+(new Date()).getFullYear());
    }
  //displayedColumns:string[]=['action'];

  yearSelectionChange(year){
    this.holidayDetails=[];
    this.loaddata(year);
  }

  loaddata(year){
    var monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
    "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

    this.holiday.FINANCIALYEAR=year;
    //this.holiday.subgrpentityid=Number(sessionStorage.getItem("subgrpentityId"));
    
    this.holidayService.loadGridAccordingYear(this.holiday).subscribe((data:any[])=>{
      this.holidayDetails=data;
      for(let i=0;i<=this.holidayDetails.length-1;i++){
        var date=new Date(data[i].DATEOFHOLIDAY)
        var dt=monthNames[(date.getMonth())]+"-"+("0" + date.getDate()).slice(-2)+"-"+date.getFullYear();
        this.holidayDetails[i].DATEOFHOLIDAY=dt;
        
        }
      
        //console.log(this.holidayDetails);
     });
  }

  
  
}
